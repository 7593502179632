<m-modal
    [canClose]="allowClose()"
    title="{{ 'teamDialog.selectTeam' | translate }}"
>
    <ng-container body>
        @if (selectedPlannedEvent) {
            <div class="workers">
                @for (worker of plannedWorkers; track worker.id) {
                    <app-worker
                        [worker]="worker"
                        (workerSelected)="onWorkerSelected($event)"
                    />
                }
            </div>

            @if (showSplitTeam()) {
                <div class="flex justify-content-center align-items-center gap-8 mt-16">
                    {{ "teamDialog.createNewTeamFromExcludedWorkers" | translate }}
                    <label class="input--toggle">
                        <input
                            id="isCompleted"
                            [(ngModel)]="splitTeam"
                            type="checkbox"
                        />
                        <span></span>
                    </label>
                </div>
            }

            <div class="flex justify-content-center mt-16">
                @if (!(otherWorkers.length > 0)) {
                    <button
                        class="btn--yellow"
                        (click)="showOtherWorkers()"
                    >
                        {{ "teamDialog.showAll" | translate }}
                    </button>
                } @else {
                    <button
                        class="btn--yellow"
                        (click)="hideOtherWorkers()"
                    >
                        {{ "teamDialog.hideAll" | translate }}
                    </button>
                }
            </div>
            @if (otherWorkers.length > 0) {
                <div class="workers mt-16">
                    @for (worker of otherWorkers; track worker.id) {
                        <app-worker
                            [worker]="worker"
                            (workerSelected)="onWorkerSelected($event)"
                        />
                    }
                </div>
            }
        }
    </ng-container>
    <ng-container
        #footer
        footer
    >
        <div class="flex align-items-center justify-content-between gap-8">
            @if (usedInSetup()) {
                <button
                    class="btn"
                    [disabled]="disablePrevious()"
                    (click)="onPreviousClicked()"
                >
                    {{ "teamDialog.previous" | translate }}
                </button>
            }

            <button
                class="btn--yellow ml-auto"
                (click)="navigateToVehicles()"
            >
                {{ (usedInSetup() ? "teamDialog.next" : "teamDialog.save") | translate }}
            </button>
        </div>
    </ng-container>
</m-modal>
