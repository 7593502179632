import { inject, Injectable } from "@angular/core";
import {
    DataSetSubType,
    DataSetType,
    IHistoricalData,
    ILiveData,
    IPinnedDataConfigurationPerAnalysisType,
    LiveDataRangeOption,
    TimeAggregationType,
} from "@ramudden/models/pinned-data";
import { SearchParameters } from "@ramudden/models/search";
import { DownloadedFile, DownloadFileService } from "@ramudden/services";
import { Observable } from "rxjs";
import { ApiServiceBase } from "../api";

@Injectable({
    providedIn: "root",
})
export class PinnedDataWebApi extends ApiServiceBase {
    private readonly downloadFileService = inject(DownloadFileService);

    protected override getApiVersion(): string {
        return "web";
    }

    override getRoute(): string {
        return "PinnedData";
    }

    getConfiguration$(id: number): Observable<IPinnedDataConfigurationPerAnalysisType> {
        const url = `${super.getUrl()}/${id}/Configuration`;
        return this.http.get<IPinnedDataConfigurationPerAnalysisType>(url);
    }

    getHistoricalData$(id: number, searchParameters: PinnedHistoryDataSearchParameters): Observable<IHistoricalData> {
        const parameters = new SearchParameters();
        parameters.queryParams = searchParameters;

        return this.getOther$<IHistoricalData>(`/${id}/HistoricalData`, parameters);
    }

    getLiveData$(
        id: number,
        searchParameters: PinnedLiveDataSearchParameters,
        useCache: boolean,
    ): Observable<ILiveData> {
        const parameters = new SearchParameters();
        parameters.queryParams = searchParameters;

        return this.getOther$<ILiveData>(`/${id}/LiveData`, parameters, null, useCache);
    }

    public getPinnedHistoricalDataExportToExcel$(
        id: number,
        searchParameters: PinnedHistoryDataSearchParameters,
    ): Promise<DownloadedFile> {
        const url = `${super.getUrl()}/${id}/HistoricalData/ExportToExcel`;

        const parameters = new SearchParameters();
        parameters.queryParams = searchParameters;

        return this.downloadFileService.downloadBlob(
            url,
            null,
            null,
            ApiServiceBase.searchParametersToHttpParams(parameters),
        );
    }
}

abstract class PinnedDataSearchParametersBase {
    selectedDataSets: SelectedDataSet[];
}

export class SelectedDataSet {
    dataSetType: DataSetType;
    dataSetSubType: DataSetSubType;
}

export class PinnedHistoryDataSearchParameters extends PinnedDataSearchParametersBase {
    lastDaysCount?: number;
    lastHoursCount?: number;
    timeAggregationType: TimeAggregationType;
}

export class PinnedLiveDataSearchParameters extends PinnedDataSearchParametersBase {
    range: LiveDataRangeOption;
}
