export enum GalleriaPreviewer {
    Photo,
    Pdf,
    Video,
}

export interface IGalleriaImage {
    source: string;
    previewImageSrc: string;
    title: string;
    alt: string;
    previewer: GalleriaPreviewer;
}
