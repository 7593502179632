import { Component, inject, Input, OnDestroy, OnInit, output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ILocation } from "@ramudden/models/location";
import { SvgComponent, ToastService } from "@ramudden/ui";
import { QrScannerComponent } from "../../../../../components/qr-scanner/qr-scanner.component";
import { AssignmentService } from "../../../../../services/assignment.service";
import { LoaderService } from "../../../../../services/loader.service";
import { LocationService } from "../../../../../services/location.service";
import { MapsDialogComponent } from "./../../../../assignment-container/assignment-parking-bans/edit-parking-ban/dialogs/maps/maps-dialog.component";

@Component({
    selector: "cpb-step-2",
    standalone: true,
    templateUrl: "cpb-step-2.component.html",
    styleUrls: ["cpb-step-2.component.scss"],
    imports: [ReactiveFormsModule, TranslateModule, SvgComponent, QrScannerComponent, MapsDialogComponent],
})
export class CpbStep2Component implements OnInit, OnDestroy {
    @ViewChild(MapsDialogComponent) mapDialog: MapsDialogComponent;

    @Input() signScanForm: FormGroup;
    destroy = output<FormGroup>();

    private readonly assignmentService = inject(AssignmentService);
    private readonly formBuilder = inject(FormBuilder);
    private readonly loaderService = inject(LoaderService);
    private readonly toastService = inject(ToastService);
    protected readonly locationService = inject(LocationService);

    ngOnInit(): void {
        this.createFromSignScan();
    }

    ngOnDestroy() {
        this.destroy.emit(this.signScanForm);
    }

    get signs() {
        return this.signScanForm.get("signs") as FormArray;
    }

    createFromSignScan() {
        if (this.signScanForm) return;

        this.signScanForm = this.formBuilder.group({
            signs: this.formBuilder.array([]),
        });
    }

    async onQrCodeScanned(qrCode: string) {
        this.loaderService.show();
        const coordinates = await this.locationService
            .getCurrentCoordinates()
            .catch(() => {
                this.toastService.showWarning("location.usedDefault");
                return this.assignmentService.selectedPlannedAssignment.assignment.location.coordinate;
            })
            .finally(() => this.loaderService.hide());
        this.loaderService.show();
        const currentAddress = await this.locationService
            .getAddressFromCoordinates(coordinates)
            .finally(() => this.loaderService.hide());

        const location = {
            coordinate: coordinates,
            address: currentAddress.address,
        } as ILocation;

        const signForm = this.formBuilder.group({
            qrCodeNumber: new FormControl(qrCode),
            location: new FormControl(location),
        });

        this.signs.push(signForm);
    }

    deleteSignSnapshot(index: number) {
        this.signs.removeAt(index);
    }

    getAddress(location: ILocation) {
        return this.locationService.convertToAddressString(location.address);
    }

    openDialog(index: number) {
        const location = this.signs.at(index).get("location").value as ILocation;

        this.mapDialog.coordinates = location.coordinate;
        if (this.mapDialog?.marker) {
            this.mapDialog.marker.position = {
                lat: location.coordinate.latitude,
                lng: location.coordinate.longitude,
            };
            this.mapDialog.googleMap.map.setCenter(this.mapDialog.marker.position);
        }
        this.mapDialog.address = this.getAddress(location);
        this.mapDialog.location = location;
        this.mapDialog.open();

        const subscription = this.mapDialog.saveModal.subscribe((newLocation: ILocation) => {
            this.signs.at(index).get("location").patchValue(newLocation);
            subscription.unsubscribe();
        });
    }
}
