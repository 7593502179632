<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<h2 class="mb-24">{{ "parkingBanPage.general" | translate }}</h2>

<form
    class="flex flex-column gap-16"
    [formGroup]="parkingBanForm"
    (change)="onParkingBanFormChange()"
>
    <div class="card">
        <div class="card__body">
            <div class="form__fields">
                <!--region Name-->
                <div class="form__field grid">
                    <label
                        class="grid__item-4"
                        for="name"
                    >
                        {{ "parkingBanPage.name" | translate }}
                    </label>

                    <div class="grid__item-8">
                        <input
                            class="input--text"
                            id="name"
                            formControlName="name"
                            placeholder=""
                            readonly
                            type="text"
                        />
                    </div>
                </div>
                <!--endregion-->

                <!--region Description-->
                <div class="form__field grid">
                    <label
                        class="grid__item-4"
                        for="description"
                    >
                        {{ "parkingBanPage.description" | translate }}
                    </label>

                    <div class="grid__item-8">
                        <input
                            class="input--text"
                            id="description"
                            formControlName="description"
                            placeholder=""
                            type="text"
                        />
                    </div>
                </div>
                <!--endregion-->

                <!--region Start-->
                <div class="form__field grid">
                    <label
                        class="grid__item-4"
                        for="start"
                    >
                        {{ "parkingBanPage.start" | translate }}
                    </label>

                    <div
                        class="grid__item-8"
                        (click)="startDateInput.showPicker()"
                    >
                        <input
                            class="input--text"
                            id="start"
                            #startDateInput
                            formControlName="start"
                            max="{{ parkingBanForm.get('end')?.value }}"
                            placeholder=""
                            type="date"
                        />
                    </div>
                </div>
                <!--endregion-->

                <!--region End-->
                <div class="form__field grid">
                    <label
                        class="grid__item-4"
                        for="end"
                    >
                        {{ "parkingBanPage.end" | translate }}
                    </label>

                    <div
                        class="grid__item-8"
                        (click)="endDateInput.showPicker()"
                    >
                        <input
                            class="input--text"
                            id="end"
                            #endDateInput
                            formControlName="end"
                            min="{{ parkingBanForm.get('start').value }}"
                            placeholder=""
                            type="date"
                        />
                    </div>
                </div>
                <!--endregion-->

                <!--region Location-->
                <div class="form__field grid">
                    <label
                        class="grid__item-4"
                        for="location"
                    >
                        {{ "parkingBanPage.location" | translate }}
                    </label>
                    <div class="grid__item-8 flex gap-8">
                        <input
                            class="input--text"
                            id="location"
                            (click)="openMapsDialog()"
                            readonly
                            type="text"
                            value="{{ parkingBanAddress }}"
                        />
                        @if (locationService.isRetrievingLocation) {
                            <m-loader-icon />
                        }
                    </div>
                </div>

                <!--endregion-->

                <!--region Attachments-->
                <ng-container formArrayName="attachments">
                    <div>
                        <button
                            class="btn--yellow"
                            (click)="mPhotoInput.open()"
                            type="button"
                        >
                            {{ "parkingBanPage.addPhoto" | translate }}
                        </button>

                        @if (parkingBanAttachments.controls.length) {
                            <div class="attachments mt-16">
                                @for (attachmentForm of parkingBanAttachments.controls; track $index) {
                                    <ng-container [formGroup]="attachmentForm">
                                        <div class="attachment">
                                            <button
                                                class="attachment__delete btn--icon"
                                                (click)="deleteAttachmentSnapshot($index)"
                                                type="button"
                                            >
                                                <m-svg iconName="delete" />
                                            </button>

                                            <img
                                                class="attachment__image"
                                                [alt]="'attachment_' + $index"
                                                [src]="attachmentForm.get('attachmentImage').value"
                                            />

                                            <div class="attachment__body">
                                                <div class="form__field">
                                                    <label for="attachmentDescription">
                                                        {{ "parkingBanPage.description" | translate }}
                                                    </label>

                                                    <input
                                                        class="input--text"
                                                        id="attachmentDescription"
                                                        formControlName="attachmentDescription"
                                                        placeholder=""
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                }
                            </div>
                        }
                    </div>
                </ng-container>
                <!--endregion-->
            </div>
        </div>
    </div>
</form>

<m-photo-input
    #mPhotoInput
    (fileLoadEnd)="onFileLoadEnd($event)"
/>

<app-maps-dialog [coordinates]="" />
