<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div
    class="card--task"
    [style.--status-color]="getStatusColor()"
    (click)="assignmentClicked.emit(plannedAssignment())"
>
    <div class="card__header">
        <h3>{{ name() }}</h3>

        <div class="align-self-start flex align-items-center gap-16">
            <span class="badge--task">
                {{ assignment().currentStatus.statusId | translateEnum: "assignmentStatus" }}
            </span>

            <a
                class="btn--google-maps"
                [href]="getGoogleMapsLink()"
                (click)="$event.stopPropagation()"
                target="_blank"
            >
                <img
                    alt="Google Maps"
                    height="24"
                    ngSrc="/assets/google_maps.svg"
                    width="24"
                />
            </a>
        </div>
    </div>

    <div class="card__body">
        <div
            class="data-list"
            style="--data-list-columns: 2"
        >
            @if (plannedAssignment()?.plannedEvent?.eventDate) {
                <dl>
                    <dt>{{ "assignmentsPage.date" | translate }}</dt>
                    <dd>{{ plannedAssignment()?.plannedEvent?.eventDate | momentFormat: "D MMM y" }}</dd>
                </dl>
            }
            @if (description()) {
                <dl>
                    <dt>{{ "assignmentsPage.description" | translate }}</dt>
                    <dd>{{ description() }}</dd>
                </dl>
            }
            @if (assignment().taskTypeId) {
                <dl>
                    <dt>{{ "assignmentsPage.type" | translate }}</dt>
                    <dd>{{ assignment().taskTypeId | translateEnum: "taskType" ?? "/" }}</dd>
                </dl>
            }
            @if (assignment().signMaterialId) {
                <dl>
                    <dt>{{ "assignmentsPage.material" | translate }}</dt>
                    <dd>{{ assignment().signMaterialId | translateEnum: "signMaterial" ?? "/" }}</dd>
                </dl>
            }
            @if (organization()) {
                <dl>
                    <dt>{{ "assignmentsPage.organisation" | translate }}</dt>
                    <dd>{{ organization() }}</dd>
                </dl>
            }
            @if (address()) {
                <dl>
                    <dt>{{ "assignmentsPage.address" | translate }}</dt>
                    <dd>{{ address() }}</dd>
                </dl>
            }
            <dl>
                <dt>{{ "assignmentsPage.isMachineWork" | translate }}</dt>
                <dd>
                    @if (isMachineWork()) {
                        <m-svg
                            iconName="circle-check"
                            style="--icon-width: 2rem; --icon-height: 2rem; --icon-fill: var(--color-success)"
                        />
                    } @else {
                        <m-svg
                            iconName="circle-xmark"
                            style="--icon-width: 2rem; --icon-height: 2rem; --icon-fill: var(--color-error)"
                        />
                    }
                </dd>
            </dl>
        </div>

        <table class="table mt-16">
            <tbody>
                @for (attachment of attachments; track attachment.id) {
                    <tr (click)="openPreview(attachment, $event)">
                        <td>
                            <div class="flex align-items-center gap-8">
                                <m-svg [iconName]="getIconName(attachment)" />
                                {{ attachment.name }}
                            </div>
                        </td>
                        <td style="width: 0">
                            <a
                                [href]="attachment.url"
                                (click)="$event.stopPropagation()"
                            >
                                <m-svg iconName="download" />
                            </a>
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    </div>
</div>
