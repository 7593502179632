import {
    ComponentRef,
    createComponent,
    EnvironmentInjector,
    inject,
    Injectable,
    Injector,
    ViewContainerRef,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ModalComponent, ModalConfig, ModalConfigAction } from "../modal";

@Injectable({ providedIn: "root" })
export class ModalService {
    private envInjector = inject(EnvironmentInjector);
    private injector = inject(Injector);
    private translateService = inject(TranslateService);

    /**
     * Shows a modal and returns when the modal is closed. This immediately closes the modal when the handler is called.
     * @param config
     */
    public showModal(config: ModalConfig): void {
        this.createModal(config);
    }

    /**
     * Shows a modal and returns a promise that resolves when the modal is closed.
     * @param config
     */
    public async showModalAsync(config: ModalConfig): Promise<void> {
        await this.createModalAsync(config);
    }

    //region Utilities
    public loginNotification(
        body: string,
        title = "general.info",
        onOk: (doNotShowAgain: boolean) => void = null,
    ): Promise<void> {
        return new Promise((resolve, _reject) => {
            const config: ModalConfig = {
                title: title,
                body: body,
                type: "info",
                actions: [
                    {
                        label: "Ok",
                        handler: () => {
                            onOk(modal.instance.doNotShowAgain);
                            resolve();
                        },
                        class: ["btn--yellow"],
                    },
                ],
                doNotShowAgain: true,
            };

            const modal = this.createModal(config);
        });
    }

    public alert(body: string, title = "general.alert"): void {
        this.showModal({
            title,
            body,
            type: "alert",
            actions: [{ label: "Ok", class: ["btn--yellow"] }],
        });
    }

    public error(body: string, title = "general.error"): void {
        this.showModal({
            title,
            body,
            type: "error",
            actions: [{ label: "Ok", class: ["btn--yellow"] }],
        });
    }

    public confirm(
        body: string,
        onOk?: () => void,
        onCancel?: () => void,
        type: "info" | "alert" = "info",
        canClose = true,
    ): void {
        this.showModal({
            canClose: canClose,
            body: body,
            title: "general.info",
            type: type,
            outsideClickAction: { label: "", handler: onCancel },
            actions: [
                {
                    label: this.translateService.instant("general.cancel"),
                    handler: onCancel,
                    class: ["btn--lightgrey"],
                },
                { label: "Ok", handler: onOk, class: ["btn--yellow"] },
            ],
        });
    }

    public async confirmAsync(body: string, type: "info" | "alert" = "info"): Promise<boolean> {
        let result: boolean;
        return this.showModalAsync({
            body: body,
            title: "general.info",
            type: type,
            outsideClickAction: { label: "", handler: () => (result = false) },
            actions: [
                {
                    label: this.translateService.instant("general.cancel"),
                    handler: () => {
                        result = false;
                    },
                    class: ["btn--lightgrey"],
                },
                {
                    label: "Ok",
                    handler: () => {
                        result = true;
                    },
                    class: ["btn--yellow"],
                },
            ],
        }).then(() => {
            return result;
        });
    }

    public delete(body: string, onDelete: () => void, onCancel?: () => void): void {
        this.showModal({
            body: body,
            title: "form.delete",
            type: "delete",
            outsideClickAction: { label: "", handler: onCancel },
            actions: [
                {
                    label: this.translateService.instant("general.cancel"),
                    handler: onCancel,
                    class: ["btn--lightgrey"],
                },
                { label: "Delete", handler: onDelete, class: ["btn--yellow"] },
            ],
        });
    }
    //endregion

    //region Helpers
    private rootViewContainerRef!: ViewContainerRef;

    /**
     * Sets the root view container reference for the modal service.
     * @param viewContainerRef
     */
    public setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
        this.rootViewContainerRef = viewContainerRef;
    }

    private createModal(config: ModalConfig): ComponentRef<ModalComponent> {
        const componentRef = createComponent(ModalComponent, {
            environmentInjector: this.envInjector,
            elementInjector: this.injector,
        });

        componentRef.instance.config = config;

        componentRef.instance.close.subscribe((action: ModalConfigAction) => {
            this.destroyModal(componentRef);

            if (action?.handler) {
                action.handler();
            }
        });

        this.rootViewContainerRef.insert(componentRef.hostView);
        componentRef.instance.openModal();

        return componentRef;
    }

    private createModalAsync(config: ModalConfig): Promise<void> {
        return new Promise((resolve, _reject) => {
            const componentRef = createComponent(ModalComponent, {
                environmentInjector: this.envInjector,
                elementInjector: this.injector,
            });

            componentRef.instance.config = config;

            componentRef.instance.close.subscribe((action: ModalConfigAction) => {
                this.destroyModal(componentRef);

                if (!action.handler) {
                    resolve();
                    return;
                }

                Promise.all([action.handler()]).then(() => resolve());
            });

            this.rootViewContainerRef.insert(componentRef.hostView);
            componentRef.instance.openModal();
        });
    }

    private destroyModal(componentRef: ComponentRef<ModalComponent>) {
        componentRef.destroy();
    }

    //endregion
}
