import { CommonModule } from "@angular/common";
import {
    AfterViewInit,
    Component,
    ContentChild,
    ElementRef,
    forwardRef,
    HostListener,
    Input,
    input,
    OnDestroy,
    output,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { createPopper, Instance } from "@popperjs/core";
import { SvgComponent } from "@ramudden/ui";
import { Observable, of } from "rxjs";
import { LoaderIconComponent } from "../../components/loader-icon/loader-icon.component";
import { ClickOutsideDirective } from "../../directive/click-outside.directive";
import { DropdownOption } from "./dropdown.model";

@Component({
    selector: "m-dropdown",
    standalone: true,
    imports: [CommonModule, TranslateModule, ClickOutsideDirective, SvgComponent, LoaderIconComponent],
    templateUrl: "./dropdown.component.html",
    styleUrl: "./dropdown.component.scss",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true,
        },
    ],
})
export class DropdownComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
    @Input() isDisabled = false;
    customNoOptionsMessage = input<string>();
    id = input.required<string>();
    isLoading = input<Observable<boolean>>(of(false));
    isMultiSelect = input<boolean>(false);
    options = input.required<DropdownOption<any>[]>();
    placeHolder = input.required<string>();

    selectionChanged = output<any | any[]>();

    @ContentChild(TemplateRef) customTemplate: TemplateRef<any>;
    @ViewChild("dropdownButton") dropdownButton: ElementRef;
    @ViewChild("dropdownContent") dropdownContent: ElementRef;

    isDropdownOpen = false;
    label = "";
    labels: string[] = [];
    value: any | any[] = undefined;

    private popperInstance: Instance;

    @HostListener("window:resize", ["$event"])
    onResize(_event: Event) {
        this.dropdownContent.nativeElement.style.width = this.dropdownButton.nativeElement.offsetWidth + "px";
    }

    ngAfterViewInit() {
        this.createPopperInstance();
    }

    ngOnDestroy() {
        this.destroyPopperInstance();
    }

    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
        if (this.isDropdownOpen) {
            this.createPopperInstance();
        } else {
            this.destroyPopperInstance();
        }
    }

    createPopperInstance() {
        if (this.dropdownButton && this.dropdownContent) {
            this.popperInstance = createPopper(this.dropdownButton.nativeElement, this.dropdownContent.nativeElement, {
                strategy: "fixed",
                placement: "bottom-start",
            });
        }

        // Set width of content
        this.dropdownContent.nativeElement.style.width = this.dropdownButton.nativeElement.offsetWidth + "px";
    }

    destroyPopperInstance() {
        if (this.popperInstance) {
            this.popperInstance.destroy();
            this.popperInstance = null;
        }
    }

    get fieldValue(): string {
        if (this.label === undefined || (typeof this.label === "string" && this.label.length === 0)) {
            return this.placeHolder();
        } else {
            return this.label;
        }
    }

    onSelectionChange(event: Event) {
        const selectedOption = JSON.parse((event.target as HTMLSelectElement).value);
        const selectedValue = selectedOption.value;
        const selectedLabel = selectedOption.label;

        if (this.isMultiSelect()) {
            if (!this.value) this.value = [];

            if (this.value.includes(selectedValue)) {
                this.value = this.value.filter((x) => x !== selectedValue);
            } else {
                this.value.push(selectedValue);
            }

            if (this.labels.includes(selectedLabel)) {
                this.labels = this.labels.filter((x) => x !== selectedLabel);
            } else {
                this.labels.push(selectedLabel);
            }

            this.label = this.labels.join(", ");
        } else {
            this.value = selectedValue;
            this.label = selectedLabel;
            this.isDropdownOpen = false;
            this.destroyPopperInstance();
        }

        this.onChange(this.value);
        this.selectionChanged.emit(this.value);
        this.onTouched();
    }

    onblur() {
        this.onTouched();
    }

    onChange = (_value: any) => {};

    onTouched = () => {};

    writeValue(value: any): void {
        if (!this.options()) return;
        let selectedOption = this.options().find((x) => x.label === value);
        if (!selectedOption) selectedOption = this.options().find((x) => x.value === value);
        this.value = selectedOption;
        this.label = selectedOption?.label;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
