import { inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AssignmentApi } from "@ramudden/data-access/resource/assignment.api";
import { PlannedAssignmentApi } from "@ramudden/data-access/resource/planned-assignment.api";
import { PlannedEventApi } from "@ramudden/data-access/resource/planned-event-api";
import { AssignmentStatus, AssignmentUpdater, IAssignment } from "@ramudden/models/assignment";
import { AssignmentSchedule, IPlannedAssignment, IPlannedEvent } from "@ramudden/models/planned-event";
import { FilterDescriptor, FilterOperator, SearchParameters, ServiceRequestOptions } from "@ramudden/models/search";
import { TaskStatus } from "@ramudden/models/task";
import { LocalStorageService } from "@ramudden/services";
import { ModalConfig, ModalService } from "@ramudden/ui";
import moment from "moment/moment";
import { firstValueFrom } from "rxjs";
import { LoaderService } from "../services/loader.service";
import { AppService } from "./app.service";
import { TaskService } from "./task.service";

@Injectable({
    providedIn: "root",
})
export class AssignmentService {
    private readonly appService = inject(AppService);
    private readonly assignmentApi = inject(AssignmentApi);
    private readonly loaderService = inject(LoaderService);
    private readonly localStorageService = inject(LocalStorageService);
    private readonly modalService = inject(ModalService);
    private readonly plannedAssignmentApi = inject(PlannedAssignmentApi);
    private readonly plannedEventApi = inject(PlannedEventApi);
    private readonly taskService = inject(TaskService);
    private readonly translateService = inject(TranslateService);

    private _selectedPlannedAssignment: IPlannedAssignment;
    get selectedPlannedAssignment(): IPlannedAssignment {
        if (this.localStorageService.getItem("selectedPlannedEvent")) {
            this._selectedPlannedAssignment = JSON.parse(this.localStorageService.getItem("selectedPlannedEvent"));
        }
        return this._selectedPlannedAssignment;
    }

    set selectedPlannedAssignment(value: IPlannedAssignment) {
        this._selectedPlannedAssignment = value;
        this.localStorageService.setItem("selectedPlannedEvent", JSON.stringify(value));
    }

    //region Public
    public async getSelectedPlannedAssignment() {
        this.selectedPlannedAssignment = await this.fetchPlannedAssignment();
        return this.selectedPlannedAssignment;
    }

    public async getAssignmentSchedule(): Promise<AssignmentSchedule> {
        const plannedEvents = await this.fetchPlannedEvents();
        this.todayAssignments = await this.getTodayAssignments(plannedEvents);
        this.tomorrowAssignments = await this.getTomorrowAssignments(plannedEvents);
        return new AssignmentSchedule(this.todayAssignments, this.tomorrowAssignments);
    }

    private todayAssignments: IPlannedAssignment[];
    private tomorrowAssignments: IPlannedAssignment[];

    private async getTodayAssignments(plannedEvents: IPlannedEvent[]) {
        const previousTodayAssignments = this.todayAssignments;

        const now = moment();
        const yesterday = moment().add(-1, "days");
        const filteredTodayAssignments = plannedEvents
            .filter(
                (x) =>
                    (moment(x.eventDate).mFormatDate() === now.mFormatDate() &&
                        (!x.isNightShift || (x.isNightShift && now.hour() >= 12))) ||
                    (moment(x.eventDate).mFormatDate() === yesterday.mFormatDate() &&
                        x.isNightShift &&
                        now.hour() < 12),
            )
            .map((x) => {
                const plannedAssignments = x.plannedAssignments;
                plannedAssignments.forEach((pa) => {
                    pa.plannedEvent = x;
                    delete pa.plannedEvent.plannedAssignments;
                });
                return plannedAssignments;
            })
            .flat();
        if (
            previousTodayAssignments !== undefined &&
            filteredTodayAssignments?.length !== previousTodayAssignments.length
        ) {
            const config: ModalConfig = {
                title: this.translateService.instant("general.info"),
                body: this.translateService.instant("assignmentDialog.planningChanged", {
                    previousAmount: previousTodayAssignments?.length,
                    currentAmount: filteredTodayAssignments.length,
                }),
                type: "info",
                actions: [
                    {
                        label: "OK",
                        handler: () => {},
                        class: ["btn--yellow"],
                    },
                ],
            };

            this.modalService.showModal(config);
        }
        return filteredTodayAssignments;
    }

    public async getTomorrowAssignments(plannedEvents: IPlannedEvent[]): Promise<IPlannedAssignment[]> {
        const now = moment();
        let tomorrowAssignments: IPlannedAssignment[] = [];

        if (
            plannedEvents.filter(
                (x) => moment(x.eventDate).mFormatDate() === now.mFormatDate() && x.isNightShift && now.hour() < 12,
            ).length > 0
        ) {
            tomorrowAssignments = plannedEvents
                .filter(
                    (x) => moment(x.eventDate).mFormatDate() === now.mFormatDate() && x.isNightShift && now.hour() < 12,
                )
                .map((x) => {
                    const plannedAssignments = x.plannedAssignments;
                    plannedAssignments.forEach((pa) => {
                        pa.plannedEvent = x;
                        delete pa.plannedEvent.plannedAssignments;
                    });
                    return plannedAssignments;
                })
                .flat();
        } else if (
            plannedEvents.filter((x) => moment(x.eventDate).mFormatDate() === moment().add(1, "days").mFormatDate())
                .length > 0
        ) {
            tomorrowAssignments = plannedEvents
                .filter((x) => moment(x.eventDate).mFormatDate() === moment().add(1, "days").mFormatDate())
                .map((x) => {
                    const plannedAssignments = x.plannedAssignments;
                    plannedAssignments.forEach((pa) => {
                        pa.plannedEvent = x;
                        delete pa.plannedEvent.plannedAssignments;
                    });
                    return plannedAssignments;
                })
                .flat();
        } else {
            const futurePlannedEvents = await this.getFuturePlannedEvents(moment().add(1, "days").toDate());

            if (futurePlannedEvents.length > 0) {
                const firstDate = moment(futurePlannedEvents[0].eventDate);
                tomorrowAssignments = futurePlannedEvents
                    .filter((x) => moment(x.eventDate).mFormatDate() === firstDate.mFormatDate())
                    .map((x) => {
                        const plannedAssignments = x.plannedAssignments;
                        plannedAssignments.forEach((pa) => {
                            pa.plannedEvent = x;
                            delete pa.plannedEvent.plannedAssignments;
                        });
                        return plannedAssignments;
                    })
                    .flat();
            }
        }
        return tomorrowAssignments;
    }

    public async setAssignmentFinished() {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("Assignment", "ParentAssignment");
        serviceRequestOptions.includes.add("Assignment", "Project");
        serviceRequestOptions.includes.add("Assignment", "Tasks");
        serviceRequestOptions.includes.add("Assignment", "Location");
        serviceRequestOptions.includes.add("Task", "CurrentStatus");
        serviceRequestOptions.includes.add("Task", "Location");

        const assignment = await this.getAssignment(this.selectedPlannedAssignment.assignmentId, serviceRequestOptions);
        const tasks = assignment.tasks.filter((x) => !x.isObsolete);

        const notCompletedTasks = tasks.filter(
            (t) =>
                t.currentStatus.taskStatusId === TaskStatus.InProgress ||
                (t.currentStatus.taskStatusId === TaskStatus.Scheduled &&
                    t.currentStatus?.availableFrom &&
                    t.currentStatus.availableFrom <= new Date()),
        );

        const scheduledOrOnHoldTasks = tasks.filter(
            (t) =>
                (t.currentStatus.taskStatusId === TaskStatus.Scheduled &&
                    t.currentStatus?.availableFrom &&
                    t.currentStatus.availableFrom > new Date()) ||
                t.currentStatus.taskStatusId === TaskStatus.OnHold,
        );

        let answer: boolean;
        if (notCompletedTasks.length > 0 || scheduledOrOnHoldTasks.length > 0) {
            if (scheduledOrOnHoldTasks.length > 0) {
                this.modalService.alert(
                    this.translateService.instant("assignmentDialog.scheduledAndOnHoldTasksError", {
                        amount: scheduledOrOnHoldTasks.length,
                    }),
                );

                answer = false;
            } else {
                answer = await this.modalService.confirmAsync(
                    this.translateService.instant("assignmentDialog.openTasks", { amount: notCompletedTasks.length }),
                );
            }
        } else {
            answer = await this.modalService.confirmAsync(this.translateService.instant("assignmentDialog.confirm"));
        }

        if (answer) {
            for (const task of notCompletedTasks) {
                task.assignment = assignment;
                task.currentStatus.availableFrom = null;
                task.start = task.start ?? new Date();
                task.end = new Date();
                task.name =
                    task.currentStatus.taskStatusId === TaskStatus.Scheduled
                        ? moment().format("YYYYMMDD_HHmmss")
                        : task.name;
                task.currentStatus.taskStatusId = TaskStatus.Finished;
                task.location = task.location ?? assignment.location;
                await this.taskService.updateTask(task);
            }
            await this.updateAssignment(assignment, AssignmentStatus.Finished);
        }

        return answer;
    }

    public async setAssignmentReopened() {
        const answer = await this.modalService.confirmAsync(this.translateService.instant("assignmentDialog.confirm"));

        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("Assignment", "ParentAssignment");
        serviceRequestOptions.includes.add("Assignment", "Project");
        serviceRequestOptions.includes.add("Assignment", "Tasks");
        serviceRequestOptions.includes.add("Assignment", "Location");

        const assignment = await this.getAssignment(this.selectedPlannedAssignment.assignmentId, serviceRequestOptions);

        if (answer) {
            await this.updateAssignment(assignment, AssignmentStatus.Reopened);
        }
        return answer;
    }

    public updateAssignment(assignment: IAssignment, status?: AssignmentStatus) {
        const assignmentUpdater = new AssignmentUpdater().init(assignment, status);
        return firstValueFrom(this.assignmentApi.update$(assignmentUpdater));
    }

    public endWorkday() {
        this.plannedEventApi.endWorkday(this.appService.selectedWorker.id);
    }

    //endregion

    //region API Calls
    private getServiceRequests() {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("PlannedEvent", "PlannedWorkers");
        serviceRequestOptions.includes.add("PlannedEvent", "PlannedAssignments");
        serviceRequestOptions.includes.add("PlannedEvent", "PlannedVehicles");
        serviceRequestOptions.includes.add("PlannedEvent", "Planner");
        serviceRequestOptions.includes.add("PlannedAssignment", "Assignment");
        serviceRequestOptions.includes.add("PlannedAssignment", "CurrentTeamStatus");
        serviceRequestOptions.includes.add("PlannedWorker", "Worker");
        serviceRequestOptions.includes.add("PlannedVehicle", "Device");
        serviceRequestOptions.includes.add("Assignment", "CurrentStatus");
        serviceRequestOptions.includes.add("Assignment", "Priority");
        serviceRequestOptions.includes.add("Assignment", "Location");
        serviceRequestOptions.includes.add("Assignment", "Project");
        serviceRequestOptions.includes.add("Assignment", "ParentAssignment");
        serviceRequestOptions.includes.add("Assignment", "ExecutiveResponsible");
        serviceRequestOptions.includes.add("Project", "Organization");
        return serviceRequestOptions;
    }

    private async fetchPlannedEvents(): Promise<IPlannedEvent[]> {
        const workerId = this.appService.selectedWorker.id;
        const isAfterMidday = moment().hour() >= 12;

        const searchParametersDayShift = new SearchParameters();
        searchParametersDayShift.filter = [
            new FilterDescriptor("EventDate", moment().format("YYYY/MM/DD"), FilterOperator.greaterThanOrEqualTo),
            new FilterDescriptor(
                "EventDate",
                moment().add(1, "days").format("YYYY/MM/DD"),
                FilterOperator.lessThanOrEqualTo,
            ),
            new FilterDescriptor("IsNightShift", false, FilterOperator.equals),
        ];

        const searchParametersNightShift = new SearchParameters();
        searchParametersNightShift.filter = [
            new FilterDescriptor(
                "EventDate",
                (isAfterMidday ? moment() : moment().subtract(1, "days")).format("YYYY/MM/DD"),
                FilterOperator.greaterThanOrEqualTo,
            ),
            new FilterDescriptor(
                "EventDate",
                moment().add(2, "days").format("YYYY/MM/DD"),
                FilterOperator.lessThanOrEqualTo,
            ),
            new FilterDescriptor("IsNightShift", true, FilterOperator.equals),
        ];

        const serviceRequestOptions = this.getServiceRequests();

        searchParametersDayShift.filter.push(new FilterDescriptor("PlannedWorkers", workerId, FilterOperator.contains));
        searchParametersNightShift.filter.push(
            new FilterDescriptor("PlannedWorkers", workerId, FilterOperator.contains),
        );

        this.loaderService.show();
        const dayShiftData = (
            await firstValueFrom(this.plannedEventApi.search$(searchParametersDayShift, serviceRequestOptions)).finally(
                () => this.loaderService.hide(),
            )
        ).data;
        this.loaderService.show();
        const nightShiftData = (
            await firstValueFrom(
                this.plannedEventApi.search$(searchParametersNightShift, serviceRequestOptions),
            ).finally(() => this.loaderService.hide())
        ).data;

        const data = [...dayShiftData, ...nightShiftData];
        return data.sort((a, b) => {
            if (a.eventDate === b.eventDate) {
                return a.order - b.order;
            }
            return new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime();
        });
    }

    private async getFuturePlannedEvents(startingDay: Date): Promise<IPlannedEvent[]> {
        const workerId = this.appService.selectedWorker.id;
        const searchParameters = new SearchParameters();
        searchParameters.filter = [
            new FilterDescriptor(
                "EventDate",
                moment(startingDay).format("YYYY/MM/DD"),
                FilterOperator.greaterThanOrEqualTo,
            ),
            new FilterDescriptor("PlannedWorkers", workerId, FilterOperator.contains),
        ];

        const serviceRequestOptions = this.getServiceRequests();
        this.loaderService.show();
        const data = (
            await firstValueFrom(this.plannedEventApi.search$(searchParameters, serviceRequestOptions)).finally(() =>
                this.loaderService.hide(),
            )
        ).data;
        return data.sort((a, b) => {
            if (a.eventDate === b.eventDate) {
                return a.order - b.order;
            }
            return new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime();
        });
    }

    public async fetchPlannedAssignment(plannedAssignmentId?: number): Promise<IPlannedAssignment> {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("Assignment", "CurrentStatus");
        serviceRequestOptions.includes.add("Assignment", "ExecutiveResponsible");
        serviceRequestOptions.includes.add("Assignment", "Location");
        serviceRequestOptions.includes.add("Assignment", "ParentAssignment");
        serviceRequestOptions.includes.add("Assignment", "Priority");
        serviceRequestOptions.includes.add("Assignment", "Project");
        serviceRequestOptions.includes.add("PlannedAssignment", "Assignment");
        serviceRequestOptions.includes.add("PlannedAssignment", "CurrentTeamStatus");
        serviceRequestOptions.includes.add("PlannedAssignment", "CustomTeam");
        serviceRequestOptions.includes.add("PlannedAssignment", "CustomVehicles");
        serviceRequestOptions.includes.add("PlannedAssignment", "PlannedEvent");
        serviceRequestOptions.includes.add("PlannedAssignmentCustomTeamMember", "Worker");
        serviceRequestOptions.includes.add("PlannedAssignmentCustomVehicle", "Device");
        serviceRequestOptions.includes.add("Project", "Organization");

        const assignmentId = plannedAssignmentId ?? this.selectedPlannedAssignment.id;

        this.loaderService.show();
        const plannedAssignment = await firstValueFrom(
            this.plannedAssignmentApi.get$(assignmentId, null, serviceRequestOptions),
        ).finally(() => this.loaderService.hide());

        const now = moment();
        const yesterday = moment().add(-1, "days");

        plannedAssignment.isPlannedToday =
            (moment(plannedAssignment.plannedEvent.eventDate).mFormatDate() === now.mFormatDate() &&
                (!plannedAssignment.plannedEvent.isNightShift ||
                    (plannedAssignment.plannedEvent.isNightShift && now.hour() >= 12))) ||
            (moment(plannedAssignment.plannedEvent.eventDate).mFormatDate() === yesterday.mFormatDate() &&
                plannedAssignment.plannedEvent.isNightShift &&
                now.hour() < 12);
        return plannedAssignment;
    }

    private async getAssignment(assignmentId: number, serviceRequestOptions: ServiceRequestOptions) {
        this.loaderService.show();
        return await firstValueFrom(this.assignmentApi.get$(assignmentId, null, serviceRequestOptions)).finally(() =>
            this.loaderService.hide(),
        );
    }

    //endregion
}
