import { inject, Injectable } from "@angular/core";
import { AttachmentApi } from "@ramudden/data-access/resource/attachment.api";
import { TaskApi } from "@ramudden/data-access/resource/task.api";
import { AttachmentCreator, AttachmentUpdater, IAttachment } from "@ramudden/models/attachment";
import { ILocation } from "@ramudden/models/location";
import { ServiceRequestOptions } from "@ramudden/models/search";
import { ITask, TaskUpdater } from "@ramudden/models/task";
import { ImageConverterService } from "@ramudden/services";
import { firstValueFrom } from "rxjs";
import { LoaderService } from "../services/loader.service";

@Injectable({ providedIn: "root" })
export class TaskService {
    private readonly attachmentApi = inject(AttachmentApi);
    private readonly taskApi = inject(TaskApi);
    private readonly imageConverter = inject(ImageConverterService);
    private readonly loaderService = inject(LoaderService);

    public async fetchTask(taskId: number): Promise<ITask> {
        const options = new ServiceRequestOptions();
        options.includes.add("assignment", "parentAssignment");
        options.includes.add("assignment", "project");
        options.includes.add("attachment", "location");
        options.includes.add("attachment", "type");
        options.includes.add("task", "assignment");
        options.includes.add("task", "attachments");
        options.includes.add("task", "copiedTask");
        options.includes.add("task", "currentStatus");
        options.includes.add("task", "location");
        options.includes.add("taskStatusHistory", "workerTasks");
        options.includes.add("workerTask", "worker");

        this.loaderService.show();
        return await firstValueFrom(this.taskApi.get$(taskId, null, options)).finally(() => this.loaderService.hide());
    }

    public async uploadTaskAttachment(
        name: string,
        taskId: number,
        image: string,
        location: ILocation,
    ): Promise<IAttachment> {
        const webpDataUrl = await this.imageConverter.convertToWebP(image);

        const file = this.dataURLtoFile(webpDataUrl, name);

        const creator = new AttachmentCreator();
        creator.name = name;
        creator.typeId = 3;
        // creator.locationCreator = locationCreator;
        creator.taskId = taskId;
        creator.location = location;

        return await firstValueFrom(this.attachmentApi.upload$(creator, file));
    }

    public async deleteTaskAttachment(attachmentId: number): Promise<void> {
        await firstValueFrom(this.attachmentApi.delete$(attachmentId));
    }

    public async updateTaskAttachment(attachment: IAttachment, taskId: number): Promise<IAttachment> {
        const updater = new AttachmentUpdater(attachment);
        updater.taskId = taskId;
        return await firstValueFrom(this.attachmentApi.update$(updater));
    }

    public async updateTask(task: ITask): Promise<ITask> {
        const taskUpdater = new TaskUpdater().init(task);
        return await firstValueFrom(this.taskApi.update$(taskUpdater));
    }

    private dataURLtoFile(dataUrl: string, fileName: string) {
        const arr = dataUrl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
    }
}
