<content-stepper>
    <ng-container body>
        <m-stepper
            [isStepValid]="isStepValid"
            [steps]="[
                'parkingBanPage.general' | translate,
                'parkingBanPage.scannedSigns' | translate,
                'parkingBanPage.exceptions' | translate,
            ]"
            [withoutControls]="false"
            (finished)="onFinish()"
            (stepChanged)="onStepChanged($event)"
        >
            <ng-template stepIndex="0">
                <cpb-step-1
                    [parkingBanForm]="parkingBanForm"
                    (destroy)="onStep1Destroy($event)"
                    (stepValid)="isStepValid[0] = $event"
                />
            </ng-template>
            <ng-template stepIndex="1">
                <cpb-step-2
                    [signScanForm]="signScanForm"
                    (destroy)="signScanForm = $event"
                />
            </ng-template>
            <ng-template stepIndex="2">
                <cpb-step-3 [parkingBan]="parkingBan" />
            </ng-template>
        </m-stepper>
    </ng-container>
</content-stepper>
