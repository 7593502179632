<m-modal
    [canClose]="false"
    title="{{ 'userDialog.title' | translate }}"
>
    <ng-container body>
        @if (teamForm) {
            <form [formGroup]="teamForm">
                <div class="form__fields">
                    <div class="form__field">
                        <m-dropdown
                            id="worker"
                            [isLoading]="isLoading"
                            [options]="workers"
                            [placeHolder]="'teamsPage.form.worker'"
                            (selectionChanged)="workerChanged($event)"
                            formControlName="worker"
                        />
                    </div>

                    <div class="form__field">
                        <label
                            class="label"
                            for="team"
                        >
                            {{ "userDialog.team" | translate }}
                        </label>

                        <m-dropdown
                            id="team"
                            #teamDropdown
                            [isLoading]="isLoading"
                            [options]="teams"
                            [placeHolder]="'teamsPage.form.team'"
                            formControlName="team"
                        />
                    </div>

                    <div class="form__field">
                        <label
                            class="label"
                            for="language"
                        >
                            {{ "userDialog.language" | translate }}
                        </label>

                        <m-dropdown
                            id="language"
                            [options]="languages"
                            [placeHolder]="'teamsPage.form.language'"
                            (selectionChanged)="languageChanged($event)"
                            formControlName="language"
                        />
                    </div>
                </div>
            </form>
        }
    </ng-container>

    <ng-container
        #footer
        footer
    >
        @if (teamForm) {
            <div class="flex align-items-center justify-content-between gap-8">
                <button
                    class="btn"
                    (click)="logout()"
                    type="button"
                >
                    {{ "userDialog.logout" | translate }}
                </button>

                <button
                    class="btn--yellow ml-auto"
                    [disabled]="!teamForm.valid"
                    (click)="submit()"
                    type="button"
                >
                    {{ "userDialog.next" | translate }}
                </button>
            </div>
        }
    </ng-container>
</m-modal>
