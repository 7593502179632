import { Injectable } from "@angular/core";
import { DailyReportsStatus } from "@ramudden/models/daily-expense-worklistitem";
import {
    IPlannedAssignment,
    IPlannedAssignmentCustomTeam,
    IPlannedAssignmentCustomVehicles,
    IPlannedAssignmentTeamHistory,
    PlannedAssignmentCreator,
    PlannedAssignmentUpdater,
} from "@ramudden/models/planned-event";
import { Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class PlannedAssignmentApi extends ApiService<
    IPlannedAssignment,
    PlannedAssignmentCreator,
    PlannedAssignmentUpdater
> {
    override getRoute(): string {
        return "PlannedAssignments";
    }

    setDailyReportsStatus$(id: number, status: DailyReportsStatus): Observable<IPlannedAssignment> {
        const url = `${super.getUrl()}/${id}/DailyReportsStatus`;
        const command = { status: status };
        return this.http.post<IPlannedAssignment>(url, command);
    }

    marktTeamArrival$(id: number): Observable<IPlannedAssignmentTeamHistory> {
        const url = `${super.getUrl()}/${id}/TeamArrival`;
        return this.http.put<IPlannedAssignmentTeamHistory>(url, {});
    }

    markTeamDeparture$(id: number): Observable<IPlannedAssignmentTeamHistory> {
        const url = `${super.getUrl()}/${id}/TeamDeparture`;
        return this.http.put<IPlannedAssignmentTeamHistory>(url, {});
    }

    updateCustomTeam(id: number, customTeam: IPlannedAssignmentCustomTeam): Observable<IPlannedAssignment> {
        const url = `${super.getUrl()}/${id}/CustomTeam`;
        return this.http.put<IPlannedAssignment>(url, customTeam);
    }

    updateCustomVehicle(id: number, customVehicles: IPlannedAssignmentCustomVehicles): Observable<IPlannedAssignment> {
        const url = `${super.getUrl()}/${id}/CustomVehicleConfiguration`;
        return this.http.put<IPlannedAssignment>(url, customVehicles);
    }
}
