import { Injectable } from "@angular/core";
import { AnalysisType } from "@ramudden/models/measuring-point";
import { ExistsResult, ExistsValue } from "@ramudden/models/search";
import { IOrganization, OrganizationCreator } from "@ramudden/models/user";
import { Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class OrganizationApi extends ApiService<IOrganization, OrganizationCreator, IOrganization> {
    override getRoute(): string {
        return "Organizations";
    }

    exists$(existsValue: ExistsValue): Observable<ExistsResult> {
        const url = `${super.getUrl()}/Exists`;
        return this.http.get<ExistsResult>(url, {
            params: { code: existsValue.value, parentOrganizationId: existsValue.parentOrganizationId.toString() },
        });
    }

    uploadLogo$(organization: IOrganization, file: File): Observable<IOrganization> {
        const url = `${super.getUrl()}/${organization.id}/UploadLogo`;
        const formData = new FormData();

        if (file) {
            formData.append("icon", file);
        }

        return this.http.post<IOrganization>(url, formData);
    }

    getChildren$(organizationId: number): Observable<IOrganization[]> {
        const url = `${super.getUrl()}/${organizationId}/children`;
        return this.http.get<IOrganization[]>(url);
    }

    getAllowedAnalysisTypes$(organizationId: number): Observable<AnalysisType[]> {
        const url = `${super.getUrl()}/${organizationId}/AllowedAnalysisTypes`;
        return this.http.get<AnalysisType[]>(url);
    }

    getInUseAnalysisTypes$(organizationId: number): Observable<AnalysisType[]> {
        const url = `${super.getUrl()}/${organizationId}/InUseAnalysisTypes`;
        return this.http.get<AnalysisType[]>(url);
    }

    addAnalysisTypes$(organizationId: number, analysisTypes: AnalysisType[]): Observable<void> {
        const url = `${super.getUrl()}/${organizationId}/AddAnalysisTypes`;
        return this.http.post<void>(url, analysisTypes);
    }
}
