import { Injectable } from "@angular/core";
import { LocationKmlCreator, LocationKmlResult } from "@ramudden/models/kml";
import {
    IAddress,
    IAddressWithTimeZone,
    ICoordinate,
    ILocation,
    LocationCreator,
    LocationUpdater,
} from "@ramudden/models/location";
import { SearchParameters } from "@ramudden/models/search";
import { Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class LocationApi extends ApiService<ILocation, LocationCreator, LocationUpdater> {
    override getRoute(): string {
        return "Locations";
    }

    getAddressFromCoordinates$(coordinate: ICoordinate): Observable<IAddressWithTimeZone> {
        const url = `${super.getUrl()}/GetAddressFromCoordinates`;

        const params = {
            params: {
                latitude: coordinate.latitude.toString(),
                longitude: coordinate.longitude.toString(),
            },
        };

        return this.http.get<IAddressWithTimeZone>(url, params);
    }

    getCoordinatesFromAddress$(address: IAddress): Observable<ICoordinate> {
        const url = `${super.getUrl()}/GetCoordinatesFromAddress`;
        return this.http.post<ICoordinate>(url, address);
    }

    uploadKeyholeMarkupFile$(creator: LocationKmlCreator, file: File): Observable<LocationKmlResult[]> {
        const url = `${super.getUrl()}/UploadKeyholeMarkupFile`;
        const formData = new FormData();
        formData.append("config", JSON.stringify(creator));
        formData.append("data", file);

        return this.http.post<LocationKmlResult[]>(url, formData);
    }
}

export class LocationSearchContent {
    static measuringPoints = "MeasuringPoints";
    static organization = "Organization";
    static devices = "Devices";

    static combine(contents: string[]) {
        return contents.join(",");
    }
}

export class LocationSearchParameters extends SearchParameters {
    content: string;
}
