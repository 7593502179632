import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, ViewChild } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { SubscriptionManager } from "@ramudden/core/utils";
import { LocalStorageService } from "@ramudden/services";
import { ModalService, SvgComponent } from "@ramudden/ui";
import { environment } from "../../../environments/environment";
import { AppConfig, AppService } from "../../services/app.service";
import { AssignmentService } from "../../services/assignment.service";
import { TitleService } from "../../services/title.service";
import { RefreshPopupComponent } from "./refresh-popup/refresh-popup.component";

@Component({
    selector: "m-page-header",
    standalone: true,
    imports: [SvgComponent, RouterLink, TranslateModule, RefreshPopupComponent],
    templateUrl: "./header.component.html",
    styleUrl: "./header.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
    private readonly assignmentService = inject(AssignmentService);
    private readonly router = inject(Router);
    protected readonly appService = inject(AppService);
    protected readonly titleService = inject(TitleService);
    private readonly modalService = inject(ModalService);
    private readonly translateService = inject(TranslateService);
    private readonly localStorageService = inject(LocalStorageService);
    private readonly subscriptionManager = new SubscriptionManager();
    private readonly cdr = inject(ChangeDetectorRef);

    guiVersion: string;
    hasLatestVersion = true;
    isConfigured: boolean;

    @ViewChild("popupDialog") popupDialog: RefreshPopupComponent;

    ngOnInit() {
        this.isConfigured = this.appService?.config?.isConfigured;
        this.guiVersion = environment.version;

        this.hasLatestVersion = this.localStorageService.getItem("hasLatestVersion") === "true";
        this.subscriptionManager.add(
            "localStorageChanges",
            this.localStorageService.storageSubject$.subscribe((changes) => {
                if (changes.key === "hasLatestVersion") {
                    this.hasLatestVersion = changes.value === "true";
                }
                if (changes.key === "configKey") {
                    const oldValue = this.isConfigured;
                    const jsonValue = JSON.parse(changes.value) as AppConfig;
                    this.isConfigured = jsonValue?.isConfigured || false;
                    if (oldValue !== this.isConfigured) {
                        this.cdr.detectChanges();
                    }
                }
            }),
        );
    }

    public async refreshClicked(button: HTMLElement) {
        if (this.hasLatestVersion) {
            await this.router.navigate(["/assignments"]);
            location.reload();
        } else {
            this.popupDialog.show(button);
        }
    }

    public userIconClick() {
        return this.router.navigate(["/setup"]);
    }

    public endWorkday() {
        const onOk = () => {
            this.assignmentService.endWorkday();
            this.appService.clearState();
            this.router.navigate(["/setup"]);
        };

        this.modalService.confirm(this.translateService.instant("endWorkdayDialog.confirm"), onOk);
    }
}
