<m-modal
    #modal
    [canClose]="false"
    [title]="'parkingBanPage.updateLocation' | translate"
>
    <ng-container body>
        <form class="maps-dialog-search">
            <input
                class="input--text"
                id="location"
                disabled
                readonly
                type="text"
                value="{{ address }}"
            />

            <app-map
                #appMap
                [coordinates]="coordinates"
                (coordinatesChanged)="onCoordinatesChanged($event)"
                (mapLoaded)="onMapLoaded()"
                style="--map-height: 100%"
            />
        </form>
    </ng-container>

    <ng-container
        #footer
        footer
    >
        <div class="flex justify-content-between gap-16">
            <button
                class="btn"
                [disabled]="appMap.locationLoading"
                (click)="appMap.getCurrentCoordinates()"
            >
                <m-svg iconName="location-crosshair" />
                {{ "parkingBanPage.useCurrentLocation" | translate }}
            </button>

            <button
                class="btn--yellow"
                [disabled]="appMap.locationLoading"
                (click)="onSave(); modal.closeModal()"
            >
                {{ "parkingBanPage.save" | translate }}
            </button>
        </div>
    </ng-container>
</m-modal>
