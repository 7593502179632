<h2 class="mb-24">{{ "parkingBanPage.scannedSigns" | translate }}</h2>

<form
    class="flex flex-column gap-16"
    [formGroup]="signScanForm"
>
    <div class="card">
        <div class="card__body">
            <button
                class="btn--yellow"
                (click)="qrScanner.open($event)"
            >
                {{ "parkingBanPage.scanSign" | translate }}
            </button>

            @if (signs.controls.length) {
                <div class="attachments mt-16">
                    @for (signForm of signs.controls; track $index) {
                        <ng-container [formGroup]="signForm">
                            <div class="attachment">
                                <button
                                    class="attachment__delete btn--icon"
                                    (click)="deleteSignSnapshot($index)"
                                >
                                    <m-svg iconName="delete" />
                                </button>

                                <img
                                    class="attachment__image"
                                    alt="{{ 'parkingBanPage.parkingBan' | translate }}"
                                    src="/assets/parking-ban.png"
                                />

                                <div class="attachment__body form__fields">
                                    <div class="form__field">
                                        <label for="qrCodeNumber">{{ "parkingBanPage.number" | translate }}</label>
                                        <input
                                            class="input--text"
                                            id="qrCodeNumber"
                                            formControlName="qrCodeNumber"
                                            placeholder=""
                                            type="text"
                                        />
                                    </div>

                                    <div class="form__field">
                                        <label for="signScanLocation">{{ "parkingBanPage.address" | translate }}</label>
                                        <input
                                            class="input--text"
                                            id="signScanLocation"
                                            [value]="getAddress(signForm.get('location').value)"
                                            (click)="openDialog($index)"
                                            readonly
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    }
                </div>
            }
        </div>
    </div>
</form>

<app-qr-scanner
    #qrScanner
    (qrCodeScanned)="onQrCodeScanned($event)"
/>

<app-maps-dialog [coordinates]="" />
