@if (isVisible) {
    <div
        class="popup-content"
        #popup
    >
        {{ "newVersion" | translate }}
        <br />
        <button
            class="btn--lightgrey"
            (click)="reload()"
        >
            {{ "updateNow" | translate }}
        </button>
    </div>
}
