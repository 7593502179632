import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { AttachmentApi } from "@ramudden/data-access/resource/attachment.api";
import { LocationApi } from "@ramudden/data-access/resource/location.api";
import { AttachmentCreator } from "@ramudden/models/attachment";
import { IAddress, ILocation, LocationUpdater } from "@ramudden/models/location";
import { ISignScan } from "@ramudden/models/sign-scan";
import { ImageConverterService } from "@ramudden/services";
import {
    GalleriaService,
    IGalleriaImage,
    PhotoInputComponent,
    PhotoInputFileLoadEnd,
    SvgComponent,
    ToastService,
} from "@ramudden/ui";
import moment from "moment";
import { firstValueFrom } from "rxjs";
import { DateFormatDirective } from "../../../../../directive/date-format.directive";
import { DefaultComponent } from "../../../../../layout/content/default/default.component";
import { MapsDialogComponent } from "../../../../../pages/assignment-container/assignment-parking-bans/edit-parking-ban/dialogs/maps/maps-dialog.component";
import { LocationService } from "../../../../../services/location.service";
import { SignScanService } from "../../../../../services/sign-scan.service";
import { TitleService } from "../../../../../services/title.service";

@Component({
    selector: "app-sign-scan-detail",
    standalone: true,
    templateUrl: "sign-scan-detail.component.html",
    imports: [
        DefaultComponent,
        TranslateModule,
        MapsDialogComponent,
        ReactiveFormsModule,
        SvgComponent,
        DateFormatDirective,
        PhotoInputComponent,
    ],
})
export class SignScanDetailComponent implements OnInit {
    private readonly attachmentApi = inject(AttachmentApi);
    private readonly formBuilder = inject(FormBuilder);
    private readonly imageConverter = inject(ImageConverterService);
    private readonly locationApi = inject(LocationApi);
    private readonly locationService = inject(LocationService);
    private readonly route = inject(ActivatedRoute);
    private readonly signScanService = inject(SignScanService);
    private readonly titleService = inject(TitleService);
    private readonly toastService = inject(ToastService);
    private readonly translateService = inject(TranslateService);
    private readonly galleriaService = inject(GalleriaService);

    signScan: ISignScan;
    signScanForm: FormGroup;

    async ngOnInit() {
        await this.fetchSignScan();
        this.createForm();

        this.titleService.title = this.translateService.instant("signScanDetailPage.title");
        const assignmentId = this.route.snapshot.params.plannedAssignmentId;
        const parkingBanId = this.route.snapshot.params.parkingBanId;
        this.titleService.goBack.set(["/assignment", assignmentId, "parking-ban", parkingBanId]);
    }

    //region Getters
    get attachments(): FormArray<FormGroup> {
        return this.signScanForm.get("attachmentsForm") as FormArray<FormGroup>;
    }

    get isCheckedOut(): boolean {
        return this.signScan?.checkOut && this.signScan?.checkOut?.getTime() <= new Date().getTime();
    }

    get isLost(): boolean {
        return this.signScan?.lost && this.signScan?.lost?.getTime() <= new Date().getTime();
    }
    //endregion

    //region Form
    createForm() {
        const generalForm = this.formBuilder.group({
            signCode: new FormControl(this.signScan.signCode),
            checkIn: new FormControl(this.signScan.checkIn),
            checkOut: new FormControl(this.signScan.checkOut),
        });

        generalForm.get("signCode").disable();
        generalForm.get("checkIn").disable();
        generalForm.get("checkOut").disable();

        const attachmentsForm = this.formBuilder.array([]);

        this.signScanForm = this.formBuilder.group({
            isCheckedOut: new FormControl(this.isCheckedOut),
            isLost: new FormControl(this.isLost),
            generalForm,
            attachmentsForm,
        });

        this.updateToggles();

        if (this.signScan.photos.length > 0) {
            this.signScan.photos.forEach((attachment) => {
                this.attachments.push(
                    this.formBuilder.group({
                        id: new FormControl(attachment.id),
                        name: new FormControl(attachment.name),
                        url: new FormControl(attachment.url),
                        description: new FormControl(attachment.description),
                    }),
                );
            });
        }
    }
    //endregion

    //region Attachments
    async onAttachmentDelete(attachmentId: number) {
        await firstValueFrom(this.attachmentApi.delete$(attachmentId));
        await this.fetchSignScan();

        this.attachments.removeAt(
            this.attachments.controls.findIndex((control) => control.get("id").value === attachmentId),
        );

        if (attachmentId) {
            await firstValueFrom(this.attachmentApi.delete$(attachmentId));
        }
    }
    //endregion

    //region Address
    address: string;

    getAddress(address: IAddress) {
        if (this.signScan.location?.address) {
            return this.locationService.convertToAddressString(address);
        }
        return "";
    }

    @ViewChild(MapsDialogComponent) mapsDialog!: MapsDialogComponent;

    openMapsDialog(event: Event) {
        this.mapsDialog.open(event);
    }

    async onSaveModal(location: ILocation) {
        if (!this.signScan.location?.code) {
            this.signScan.location.code = moment().format("YYYYMMDD_HHmmss");
        }

        const locationUpdater = new LocationUpdater({
            ...this.signScan.location,
            coordinate: location.coordinate,
            address: location.address,
        });
        await firstValueFrom(this.locationApi.update$(locationUpdater));
        await this.fetchSignScan();
    }

    //endregion

    //region Toggles
    async markAsCheckedOut() {
        await this.signScanService.markAsCheckedOut(this.signScan, this.route.snapshot.params.parkingBanId);
        await this.fetchSignScan();
    }

    async markAsLost() {
        await this.signScanService.markAsLost(this.signScan, this.route.snapshot.params.parkingBanId);
        await this.fetchSignScan();
    }

    updateToggles() {
        if (this.isCheckedOut) {
            this.signScanForm.get("isCheckedOut").disable();
        } else {
            this.signScanForm.get("isCheckedOut").enable();
        }

        if (this.isLost) {
            this.signScanForm.get("isLost").disable();
        } else {
            this.signScanForm.get("isLost").enable();
        }
    }
    //endregion

    //region Api calls
    async fetchSignScan() {
        const signScanId = this.route.snapshot.params.signScanId;

        this.signScan = await this.signScanService.fetchSignScan(signScanId);
        this.address = this.getAddress(this.signScan.location?.address);

        if (this.signScanForm) {
            this.signScanForm.get("generalForm.checkOut").setValue(this.signScan.checkOut);
            this.signScanForm.get("generalForm.checkIn").setValue(this.signScan.checkIn);
            this.signScanForm.get("isCheckedOut").setValue(this.isCheckedOut);
            this.signScanForm.get("isLost").setValue(this.isLost);
            this.updateToggles();
        }
    }

    async uploadSignScanPhoto(dataUrl: string, description: string) {
        const imageName = `${this.signScan.signCode}_${new Date().getTime()}.webp`.replace(/ /g, "_");
        const webpDataUrl = await this.imageConverter.convertToWebP(dataUrl);
        const image = this.dataURLtoFile(webpDataUrl, imageName);

        const creator = new AttachmentCreator();
        creator.description = description;
        creator.signScanId = this.signScan.id;
        creator.name = imageName;
        creator.typeId = 3;
        creator.location = this.signScan.location;

        return await firstValueFrom(this.attachmentApi.upload$(creator, image));
    }

    //endregion

    //region Helpers
    private dataURLtoFile(dataUrl: string, fileName: string) {
        const arr = dataUrl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
    }

    openGalleria(index: number) {
        const images = this.signScan.photos.map(
            (photo) =>
                ({
                    alt: photo.description,
                    previewImageSrc: photo.url,
                    source: photo.url,
                    title: photo.name,
                }) as IGalleriaImage,
        );

        this.galleriaService.open(images, index);
    }

    async onFileLoadEnd(event: PhotoInputFileLoadEnd) {
        const attachment = await this.uploadSignScanPhoto(event.base64image as string, "");
        await this.fetchSignScan();

        const formGroup = this.formBuilder.group({
            id: new FormControl(attachment.id),
            name: new FormControl(attachment.name),
            url: new FormControl(attachment.url),
            description: new FormControl(attachment.description),
        });

        this.attachments.push(formGroup);
    }
}
