@if (visible) {
    <div class="m-galleria">
        <div class="m-galleria-actions p-16">
            <m-svg
                (click)="download()"
                iconName="download"
                style="
                    --icon-fill: white;
                    --icon-width: 2rem;
                    --icon-height: 2.4rem;
                    margin-right: 16px;
                    cursor: pointer;
                "
            />

            <m-svg
                (click)="close()"
                iconName="close"
                style="--icon-fill: white; --icon-width: 2rem; --icon-height: 2.4rem; cursor: pointer"
            />
        </div>

        <div class="m-galleria-body-wrapper flex align-items-center">
            <div class="p-16">
                @if (hasPrevious) {
                    <m-svg
                        (click)="previous()"
                        iconName="gallery-previous"
                        style="--icon-fill: white; --icon-width: 2rem; --icon-height: 2rem; cursor: pointer"
                    />
                }
            </div>
            @if (currentImage) {
                <div class="m-galleria-body">
                    <h3 class="m-galleria-title p-16 text-center">{{ currentImage.title }}</h3>

                    <div class="m-galleria-content">
                        @switch (currentImage.previewer) {
                            @case (0) {
                                <img
                                    [alt]="currentImage.alt"
                                    [src]="currentImage.source"
                                />
                            }
                            @case (1) {
                                <pdf-viewer
                                    [show-all]="true"
                                    [src]="currentImage.source"
                                ></pdf-viewer>
                            }
                            @case (3) {
                                <video
                                    controls
                                    preload="metadata"
                                >
                                    <source
                                        [src]="currentImage.source"
                                        type="video/mp4"
                                    />
                                </video>
                            }
                        }
                    </div>
                </div>
            }

            <div class="p-16">
                @if (hasNext) {
                    <m-svg
                        (click)="next()"
                        iconName="gallery-next"
                        style="--icon-fill: white; --icon-width: 2rem; --icon-height: 2rem; cursor: pointer"
                    />
                }
            </div>
        </div>

        @if (navigator) {
            <div class="m-galleria-navigator p-16">
                @for (image of navigatorImages; track $index) {
                    <div
                        class="m-galleria-navigator-item flex flex-column"
                        (click)="navigateToImage(image)"
                    >
                        @if (image) {
                            <img
                                [alt]="image.alt"
                                [src]="image.previewImageSrc"
                            />
                        }
                        <span class="m-galleria-navigator-title mt-8">{{ image.title }}</span>
                    </div>
                }
            </div>
        }
    </div>
}
