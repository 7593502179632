import { CommonModule } from "@angular/common";
import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { PlannedEventApi } from "@ramudden/data-access/resource/planned-event-api";
import {
    IPlannedAssignment,
    IPlannedEvent,
    IPlannedVehicle,
    IPlannedWorker,
    PlannedEventCreator,
    PlannedEventUpdater,
} from "@ramudden/models/planned-event";
import { ServiceRequestOptions } from "@ramudden/models/search";
import { firstValueFrom } from "rxjs";
import { AppService } from "../../services/app.service";
import { AuthenticationService } from "../../services/generic/authentication.service";
import { LoaderService } from "../../services/loader.service";
import { TeamDialogComponent, TeamDialogOutput } from "./dialogs/team/team-dialog.component";
import { UserDialogComponent, UserDialogType } from "./dialogs/user/user-dialog.component";
import { ISelectedVehicle, VehicleDialogComponent } from "./dialogs/vehicle/vehicle-dialog.component";

@Component({
    selector: "app-setup",
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, UserDialogComponent, TeamDialogComponent, VehicleDialogComponent],
    templateUrl: "./setup.component.html",
})
export class SetupComponent implements OnInit {
    @ViewChild("userDialog", { static: true }) userDialog: UserDialogComponent;
    @ViewChild("teamDialog", { static: true }) teamDialog: TeamDialogComponent;
    @ViewChild("vehicleDialog", { static: true }) vehicleDialog: VehicleDialogComponent;

    private readonly appService = inject(AppService);
    private readonly plannedEventApi = inject(PlannedEventApi);
    private readonly router = inject(Router);
    private readonly authenticationService = inject(AuthenticationService);
    private readonly loaderService = inject(LoaderService);

    userDialogOutput: UserDialogType;

    splitTeam: boolean;
    disablePreviousTeamDialog = false;

    ngOnInit() {
        this.appService.restoreState();
        this.disablePreviousTeamDialog = false;

        if (this.appService.config?.isConfigured) {
            this.disablePreviousTeamDialog = true;
            this.teamDialog.openModal();
            return;
        }
        this.userDialog.openModal();
        return;
    }

    onUserDialogNext(userDialogOutput: UserDialogType) {
        this.userDialogOutput = userDialogOutput;

        this.appService.config = {
            language: userDialogOutput.language,
            isConfigured: false,
            plannedEventId: undefined,
        };
        this.appService.selectedWorker = {
            firstName: userDialogOutput.worker.firstName,
            id: userDialogOutput.worker.id,
            isActive: true,
            isTeamLeader: true,
            lastName: userDialogOutput.worker.lastName,
            organizationId: userDialogOutput.worker.organizationId,
            canDoControl: userDialogOutput.worker.canDoControl,
        };
        this.appService.plannedEvent = userDialogOutput.plannedEvent;

        this.teamDialog.openModal();
    }

    onTeamDialogPreviousClicked() {
        this.userDialog.openModal(this.userDialogOutput);
    }

    onTeamDialogClosed(teamDialogOutput: TeamDialogOutput) {
        this.appService.selectedWorkers = teamDialogOutput.selectedWorkers;
        this.appService.excludedWorkers = teamDialogOutput.excludedWorkers;
        this.splitTeam = teamDialogOutput.splitTeam;

        this.vehicleDialog.openModal();
    }

    onVehiclePrevious() {
        this.teamDialog.openModal();
    }

    onVehicleNext(selectedVehicles: ISelectedVehicle[]) {
        this.appService.selectedVehicles = selectedVehicles;
        this.saveConfiguration();
    }

    //region Save configuration

    async saveConfiguration() {
        this.loaderService.show();
        if (this.appService.selectedWorker === undefined) {
            this.appService.clearState();
            this.router.navigate(["/setup"]);
            return;
        }

        const plannedEvent = this.appService.plannedEvent;
        const selectedWorkers = this.appService.selectedWorkers;

        const plannedEventUpdater = {
            id: plannedEvent.id,
            eventDate: plannedEvent.eventDate,
            order: plannedEvent.order,
            isNightShift: plannedEvent.isNightShift,
            isWeekendShift: plannedEvent.isWeekendShift,
            plannedWorkers: [],
            plannedVehicles: [],
        } as IPlannedEvent;

        for (let i = 0; i < selectedWorkers.length; i++) {
            const existing = plannedEvent.plannedWorkers.find(
                (x) => x.plannedEventId === plannedEvent.id && x.workerId === selectedWorkers[i].id,
            );

            const plannedWorkCreator = {
                id: existing?.id,
                workerId: selectedWorkers[i].id,
                plannedEventId: plannedEvent.id,
                displayOrder: i,
                isCreatedByMobileApp: existing?.isCreatedByMobileApp ?? true,
                date: plannedEvent.eventDate,
            } as IPlannedWorker;

            plannedEventUpdater.plannedWorkers.push(plannedWorkCreator);
        }

        this.appService.selectedVehicles.forEach((vehicle) => {
            const plannedVehicleCreator = {
                deviceId: vehicle.deviceId,
                plannedEventId: plannedEvent.id,
            } as IPlannedVehicle;

            plannedEventUpdater.plannedVehicles.push(plannedVehicleCreator);
        });

        const plannedEventUpdaterClass = new PlannedEventUpdater(plannedEventUpdater);

        try {
            const promise1 = firstValueFrom(this.plannedEventApi.update$(plannedEventUpdaterClass));

            let promise2: Promise<IPlannedEvent | void>;
            if (this.splitTeam) {
                const plannedWorkers: IPlannedWorker[] = [];
                this.appService.excludedWorkers.forEach((worker, index) => {
                    const plannedWorker = {
                        displayOrder: index,
                        isCreatedByMobileApp: true,
                        plannedEventId: null,
                        workerId: worker.id,
                    } as IPlannedWorker;

                    plannedWorkers.push(plannedWorker);
                });

                const plannedAssignments: IPlannedAssignment[] = [];
                this.appService.plannedEvent.plannedAssignments.forEach((assignment, index) => {
                    const plannedAssignment = {
                        id: null,
                        note: "",
                        safetyQuestionsChecked: false,
                        displayOrder: index,
                        checkInAtWorkChecked: null,
                        checkOutAtWorkChecked: null,
                        plannedEventId: null,
                        assignmentId: assignment.assignmentId,
                    } as IPlannedAssignment;

                    plannedAssignments.push(plannedAssignment);
                });

                const plannedEvent = {
                    eventDate: this.appService.plannedEvent.eventDate,
                    order: this.appService.plannedEvent.order + 1,
                    isNightShift: this.appService.plannedEvent.isNightShift,
                    isWeekendShift: this.appService.plannedEvent.isWeekendShift,
                    plannedWorkers: plannedWorkers,
                    plannedAssignments: plannedAssignments,
                } as IPlannedEvent;

                const plannedEventCreator = new PlannedEventCreator(plannedEvent);
                promise2 = firstValueFrom(this.plannedEventApi.create$(plannedEventCreator));
            } else {
                promise2 = Promise.resolve();
            }

            await Promise.all([promise1, promise2]).then((_results) => {
                this.appService.config = {
                    isConfigured: true,
                    language: this.appService.config.language,
                    plannedEventId: plannedEvent.id,
                };
            });

            const serviceRequestOptions = new ServiceRequestOptions();
            serviceRequestOptions.includes.add("PlannedEvent", "PlannedWorkers");
            serviceRequestOptions.includes.add("PlannedEvent", "PlannedAssignments");
            serviceRequestOptions.includes.add("PlannedEvent", "PlannedVehicles");
            serviceRequestOptions.includes.add("PlannedWorker", "Worker");
            serviceRequestOptions.includes.add("Project", "organization");

            this.appService.plannedEvent = await firstValueFrom(
                this.plannedEventApi.get$(plannedEvent.id, null, serviceRequestOptions),
            );
        } catch (error) {
            alert("An error occurred while saving the configuration");
            this.authenticationService.signoutSilently();
            console.error(error);
        } finally {
            this.loaderService.hide();
        }

        this.appService.saveState();
        this.router.navigate(["/assignments"]);
    }

    //endregion
}
