<dialog
    class="modal"
    #modal
>
    <div class="modal__wrapper">
        <div
            class="modal__content"
            #modalContent
        >
            <header class="modal__header">
                @if (config) {
                    {{ config.title | translate }}
                } @else {
                    <h3>
                        {{ title() | translate }}
                    </h3>

                    @if (canClose()) {
                        <button
                            class="btn--icon modal__close"
                            (click)="closeModal()"
                        >
                            <m-svg iconName="close" />
                        </button>
                    }
                }
            </header>

            <div class="modal__body">
                @if (config) {
                    <div [innerHTML]="config.body | translate"></div>
                } @else {
                    <ng-content select="[body]"></ng-content>
                }
            </div>

            @if (config) {
                <footer class="modal__footer">
                    <div class="flex align-items-center justify-content-end gap-8">
                        @for (action of config.actions; track $index) {
                            <button
                                [class]="action.class ? action.class : 'btn--lightgrey'"
                                (click)="closeModal(action)"
                            >
                                {{ action.label | translate }}
                            </button>
                        }

                        <label
                            class="label-checkbox m-input-checkbox mr-8"
                            *ngIf="config.doNotShowAgain"
                            for="dialog-doNotShowAgain"
                        >
                            <input
                                id="dialog-doNotShowAgain"
                                (change)="onChangeDoNotShow($event)"
                                type="checkbox"
                            />
                            {{ "form.doNotShowAgain" | translate }}
                        </label>
                    </div>
                </footer>
            } @else {
                @if (hasFooter) {
                    <footer class="modal__footer">
                        <ng-content select="[footer]"></ng-content>
                    </footer>
                }
            }
        </div>
    </div>
</dialog>
