import { Injectable } from "@angular/core";
import { StringUtils, SubscriptionManager } from "@ramudden/core/utils";
import { CacheOptions } from "@ramudden/data-access/resource/api";
import { OrganizationApi } from "@ramudden/data-access/resource/organization.api";
import { ProjectApi } from "@ramudden/data-access/resource/project.api";
import { LocationWebApi } from "@ramudden/data-access/resource/web";
import { IAssignment } from "@ramudden/models/assignment";
import { IGroup, IGroupMeasuringPoint } from "@ramudden/models/group";
import { IProject } from "@ramudden/models/project";
import { IListResult, ISearchResult, ServiceRequestOptions } from "@ramudden/models/search";
import { IOrganization } from "@ramudden/models/user";
import {
    IGroupSummary,
    ILocationSummary,
    ILocationWithAssignmentsSummary,
    ILocationWithDevicesSummary,
} from "@ramudden/models/web";
import * as lodash from "lodash";
import { Observable, Subject } from "rxjs";
import { EventService } from "./event.service";

@Injectable({
    providedIn: "root",
})
export class MapDataService {
    private readonly subscriptionManager = new SubscriptionManager();

    private measuringPointLocations: ILocationSummary[];
    private readonly measuringPointLocationsSubject = new Subject<ILocationSummary[]>();
    private loadMeasuringPointLocationsPromise: Promise<ILocationSummary[]>;

    private deviceLocations: ILocationWithDevicesSummary[];
    private readonly deviceLocationsSubject = new Subject<ILocationWithDevicesSummary[]>();
    private loadDeviceLocationsPromise: Promise<ILocationWithDevicesSummary[]>;

    private groupSummaries: IGroupSummary[];
    private readonly groupSummariesSubject = new Subject<IGroupSummary[]>();
    private readonly groupEditSubject = new Subject<IGroup>();
    private loadGroupSummariesPromise: Promise<IGroupSummary[]>;

    organizations: IOrganization[];
    private readonly organizationsSubject = new Subject<IOrganization[]>();
    private readonly organizationSubject = new Subject<IOrganization>();
    private loadOrganizationsPromise: Promise<IOrganization[]>;

    assignments: IAssignment[];
    private assignmentLocations: ILocationWithAssignmentsSummary[];
    private readonly assignmentLocationsSubject = new Subject<ILocationWithAssignmentsSummary[]>();
    private loadAsignmentLocationsPromise: Promise<ILocationWithAssignmentsSummary[]>;

    projects: IProject[];
    private loadProjectsPromise: Promise<IProject[]>;

    private _editGroup: IGroup;

    public get canCreateGroup(): boolean {
        return !!this.groupSummaries && !!this.measuringPointLocations;
    }

    constructor(
        private readonly locationWebApi: LocationWebApi,
        private readonly organizationApi: OrganizationApi,
        private readonly projectApi: ProjectApi,
        private readonly eventService: EventService,
    ) {}

    clear() {
        this.clearMeasuringPointLocations();
        this.clearOrganizations();
        this.clearGroupSummaries();
        this.clearAssignmentLocations();
    }

    get isLoading(): boolean {
        return (
            !!this.loadMeasuringPointLocationsPromise ||
            !!this.loadDeviceLocationsPromise ||
            !!this.loadGroupSummariesPromise ||
            !!this.loadOrganizationsPromise ||
            !!this.loadProjectsPromise ||
            !!this.loadAsignmentLocationsPromise
        );
    }

    get isLoadingOnlyMeasuringPoints(): boolean {
        return (
            this.loadDeviceLocationsPromise !== null &&
            this.loadGroupSummariesPromise === null &&
            this.loadOrganizationsPromise === null &&
            this.loadAsignmentLocationsPromise === null
        );
    }

    async reload() {
        if (this.organizations) {
            this.clearOrganizations();
            await this.loadOrganizations();
        }

        if (this.measuringPointLocations) {
            this.clearMeasuringPointLocations();
            await this.loadMeasuringPointLocations();
        }

        if (this.groupSummaries) {
            this.clearGroupSummaries();
            await this.loadGroupSummaries();
        }

        if (this.deviceLocations) {
            this.clearDeviceLocations();
            await this.loadDeviceLocations();
        }

        if (this.assignmentLocations) {
            this.clearAssignmentLocations();
            await this.loadAssignmentLocations();
        }

        if (this.projects) {
            this.clearProjects();
            await this.loadProjects();
        }
    }

    // Keys are used for keeping track of subscriptions
    // So that multiples of the same component can subscribe to the same observables
    createKey(): string {
        return StringUtils.createKey();
    }

    unsubscribe(key: string) {
        const subscriptions = this.subscriptionManager.subscriptions;
        for (const subscriptionKey in subscriptions) {
            if (!subscriptions.hasOwnProperty(subscriptionKey)) continue;
            if (!subscriptionKey.startsWith(key)) continue;

            this.subscriptionManager.remove(subscriptionKey);
        }
    }

    //#region Measuring Points
    private onMeasuringPointLocationsUpdate(): Observable<ILocationSummary[]> {
        return this.measuringPointLocationsSubject.asObservable();
    }

    subscribeToMeasuringPointLocations(
        key: string,
        callback?: (res: ILocationSummary[]) => void,
        errorCallback?: (res: Response) => void,
        forceUpdate = true,
    ) {
        const onSuccess = () => {
            if (callback) {
                callback(this.getMeasuringPointLocations());
            }
        };

        if (!errorCallback) errorCallback = () => {};
        const onError = (response: Response) => {
            if (errorCallback) errorCallback(response);
        };

        const subscription = this.onMeasuringPointLocationsUpdate().subscribe(onSuccess, onError);

        if (!forceUpdate && callback && this.measuringPointLocations) {
            callback(this.getMeasuringPointLocations());
        }

        if (forceUpdate || !this.measuringPointLocations) {
            this.loadMeasuringPointLocations();
        }

        this.subscriptionManager.add(`${key}locations`, subscription);
    }

    cancelLoadMeasuringPointLocations() {
        this.loadMeasuringPointLocationsPromise = null;
    }

    async loadMeasuringPointLocations() {
        if (!this.loadMeasuringPointLocationsPromise) {
            this.loadMeasuringPointLocationsPromise = new Promise<ILocationSummary[]>((resolve, reject) => {
                const onSuccess = (searchResult: IListResult<ILocationSummary>) => {
                    this.measuringPointLocations = searchResult.data;
                    resolve(this.measuringPointLocations);
                };

                const onError = (response: Response) => {
                    if (response.status !== 304) {
                        this.clearMeasuringPointLocations();
                        reject(response);
                    } else {
                        resolve(this.measuringPointLocations);
                    }
                };

                const cacheOptions = new CacheOptions();
                cacheOptions.pushCacheResult = false;
                cacheOptions.pushCacheResultOnNotModified = !this.measuringPointLocations;

                this.locationWebApi.getMeasuringPoints$(cacheOptions).subscribe(onSuccess, onError);
            });

            const onPromiseEnd = () => {
                this.cancelLoadMeasuringPointLocations();
            };

            const onPromiseSuccess = () => {
                this.updateMeasuringPointLocations();
                onPromiseEnd();
            };

            this.loadMeasuringPointLocationsPromise.then(onPromiseSuccess, onPromiseEnd);
        }

        return this.loadMeasuringPointLocationsPromise;
    }

    private updateMeasuringPointLocations() {
        this.eventService.processNext(this.measuringPointLocationsSubject, this.getMeasuringPointLocations, this);
    }

    removeMeasuringPointLocation(location: ILocationSummary) {
        if (!this.measuringPointLocations) return;

        this.measuringPointLocations = this.measuringPointLocations.filter((x) => x !== location);
        this.updateMeasuringPointLocations();
    }

    removeMeasuringPointLocationById(locationId: number) {
        if (!this.measuringPointLocations) return;

        this.measuringPointLocations = this.measuringPointLocations.filter((x) => x.id !== locationId);
        this.updateMeasuringPointLocations();
    }

    async getMeasuringPointLocation$(
        callbackfn: (value: ILocationSummary, index: number, array: ILocationSummary[]) => boolean,
    ): Promise<ILocationSummary> {
        if (!this.measuringPointLocations) {
            await this.loadMeasuringPointLocations();
        }

        return this.measuringPointLocations.find(callbackfn);
    }

    clearMeasuringPointLocations() {
        this.measuringPointLocations = null;
        this.updateMeasuringPointLocations();
    }

    private getMeasuringPointLocations(): ILocationSummary[] {
        const filteredLocations = this.measuringPointLocations || [];

        return filteredLocations.orderBy((x) => x.code);
    }

    //#endregion Measuring Points

    //#region Device

    private onDeviceLocationsUpdate(): Observable<ILocationWithDevicesSummary[]> {
        return this.deviceLocationsSubject.asObservable();
    }

    subscribeToDeviceLocations(
        key: string,
        callback?: (res: ILocationWithDevicesSummary[]) => void,
        errorCallback?: (res: Response) => void,
        forceUpdate = true,
    ) {
        const onSuccess = () => {
            if (callback) callback(this.getDeviceLocations());
        };

        if (!errorCallback) errorCallback = () => {};
        const onError = (response: Response) => {
            if (errorCallback) errorCallback(response);
        };

        const subscription = this.onDeviceLocationsUpdate().subscribe(onSuccess, onError);

        if (callback && this.deviceLocations) {
            callback(this.getDeviceLocations());
        }

        if (forceUpdate || !this.deviceLocations) {
            this.loadDeviceLocations();
        }

        this.subscriptionManager.add(`${key}devices`, subscription);
    }

    cancelLoadDeviceLocations() {
        this.loadDeviceLocationsPromise = null;
    }

    async loadDeviceLocations() {
        if (!this.loadDeviceLocationsPromise) {
            this.loadDeviceLocationsPromise = new Promise<ILocationWithDevicesSummary[]>((resolve, reject) => {
                const onSuccess = (searchResult: IListResult<ILocationWithDevicesSummary>): void => {
                    this.deviceLocations = searchResult.data;
                    resolve(this.deviceLocations);
                };

                const onError = (response: Response) => {
                    if (response.status !== 304) {
                        this.clearDeviceLocations();
                        reject(response);
                    } else {
                        reject(response);
                    }
                };

                const cacheOptions = new CacheOptions();
                cacheOptions.pushCacheResult = false;
                cacheOptions.pushCacheResultOnNotModified = !this.deviceLocations;

                this.locationWebApi.getDevices$(cacheOptions).subscribe(onSuccess, onError);
            });

            const onPromiseEnd = () => {
                this.cancelLoadDeviceLocations();
            };

            const onPromiseSuccess = () => {
                this.updateDeviceLocations();
                onPromiseEnd();
            };

            this.loadDeviceLocationsPromise.then(onPromiseSuccess, onPromiseEnd);
        }

        return this.loadDeviceLocationsPromise;
    }

    private updateDeviceLocations() {
        this.eventService.processNext(this.deviceLocationsSubject, this.getDeviceLocations, this);
    }

    removeDeviceLocation(location: ILocationSummary) {
        if (!this.deviceLocations) return;

        this.deviceLocations = this.deviceLocations.filter((x) => x !== location);
        this.updateDeviceLocations();
    }

    async getDeviceLocation(
        callbackfn: (
            value: ILocationWithDevicesSummary,
            index: number,
            array: ILocationWithDevicesSummary[],
        ) => boolean,
    ): Promise<ILocationWithDevicesSummary> {
        if (!this.deviceLocations) {
            await this.loadDeviceLocations();
        }

        return this.deviceLocations.find(callbackfn);
    }

    clearDeviceLocations() {
        this.deviceLocations = null;
        this.updateDeviceLocations();
    }

    private getDeviceLocations(): ILocationWithDevicesSummary[] {
        const filteredLocations = this.deviceLocations || [];

        return filteredLocations.orderBy((x) => x.code);
    }

    //#endregion Device

    //#region Group

    private onGroupSummariesUpdate(): Observable<IGroupSummary[]> {
        return this.groupSummariesSubject.asObservable();
    }

    subscribeToGroupSummaries(
        key: string,
        callback?: (res: IGroupSummary[]) => void,
        errorCallback?: (res: Response) => void,
        forceUpdate = true,
    ) {
        const onSuccess = () => {
            if (callback) callback(this.getGroupSummaries());
        };

        if (!errorCallback) errorCallback = () => {};
        const onError = (response: Response) => {
            if (errorCallback) errorCallback(response);
        };

        const subscription = this.onGroupSummariesUpdate().subscribe(onSuccess, onError);

        if (callback && this.groupSummaries) {
            callback(this.getGroupSummaries());
        }

        if (forceUpdate || !this.groupSummaries) {
            this.loadGroupSummaries();
        }

        this.subscriptionManager.add(`${key}groups`, subscription);
    }

    cancelLoadGroupSummaries() {
        this.loadGroupSummariesPromise = null;
    }

    async loadGroupSummaries() {
        if (!this.loadGroupSummariesPromise) {
            this.loadGroupSummariesPromise = new Promise<IGroupSummary[]>((resolve, reject) => {
                const onSuccess = (searchResult: IListResult<IGroupSummary>) => {
                    this.groupSummaries = searchResult.data;
                    resolve(this.groupSummaries);
                };

                const onError = (response: Response) => {
                    if (response.status !== 304) {
                        this.clearGroupSummaries();
                        reject(response);
                    } else {
                        resolve(this.groupSummaries);
                    }
                };

                const cacheOptions = new CacheOptions();
                cacheOptions.pushCacheResult = false;
                cacheOptions.pushCacheResultOnNotModified = !this.groupSummaries;

                this.locationWebApi.getGroups$(cacheOptions).subscribe(onSuccess, onError);
            });

            const onPromiseEnd = () => {
                this.cancelLoadGroupSummaries();
            };

            const onPromiseSuccess = () => {
                this.updateGroupSummaries();
                onPromiseEnd();
            };

            this.loadGroupSummariesPromise.then(onPromiseSuccess, onPromiseEnd);
        }

        return this.loadGroupSummariesPromise;
    }

    private updateGroupSummaries() {
        this.eventService.processNext(this.groupSummariesSubject, this.getGroupSummaries, this);
    }

    removeGroupSummary(groupSummary: IGroupSummary) {
        if (!this.groupSummaries) return;

        this.groupSummaries = this.groupSummaries.filter((x) => x !== groupSummary);
        this.updateGroupSummaries();
    }

    async getGroupSummary(
        callbackfn: (value: IGroupSummary, index: number, array: IGroupSummary[]) => boolean,
    ): Promise<IGroupSummary> {
        if (!this.groupSummaries) {
            await this.loadGroupSummaries();
        }

        return this.groupSummaries.find(callbackfn);
    }

    clearGroupSummaries() {
        this.groupSummaries = null;
        this.updateGroupSummaries();
    }

    private getGroupSummaries(): IGroupSummary[] {
        const filteredGroupSummaries = this.groupSummaries || [];

        return filteredGroupSummaries;
    }

    //#endregion Group

    //#region Organizations

    private getOrganizations(): IOrganization[] {
        const organizations = this.organizations || [];

        return organizations.orderBy((x) => x.name);
    }

    private onOrganizationUpdate(): Observable<IOrganization> {
        return this.organizationSubject.asObservable();
    }

    subscribeToOrganizationUpdate(key: string, onUpdate?: (updatedOrganization: IOrganization) => void) {
        const subscription = this.onOrganizationUpdate().subscribe(onUpdate);
        this.subscriptionManager.add(`${key}organizationUpdate`, subscription);
    }

    private onOrganizationsUpdate(): Observable<IOrganization[]> {
        return this.organizationsSubject.asObservable();
    }

    subscribeToOrganizations(
        key: string,
        callback?: (res: IOrganization[]) => void,
        errorCallback?: (res: Response) => void,
        forceUpdate = false,
    ) {
        const onSuccess = () => {
            if (callback) callback(this.getOrganizations());
        };

        if (!errorCallback) errorCallback = () => {};
        const onError = (response: Response) => {
            if (errorCallback) errorCallback(response);
        };

        const subscription = this.onOrganizationsUpdate().subscribe(onSuccess, onError);

        if (callback && this.organizations) {
            callback(this.getOrganizations());
        }

        if (forceUpdate || !this.organizations) {
            this.loadOrganizations();
        }

        this.subscriptionManager.add(`${key}organizations`, subscription);
    }

    updateOrganizations() {
        this.eventService.processNext(this.organizationsSubject, this.getOrganizations, this);
    }

    loadOrganizations(): Promise<IOrganization[]> {
        if (!this.loadOrganizationsPromise) {
            this.loadOrganizationsPromise = new Promise<IOrganization[]>((resolve, reject) => {
                const onSuccess = (searchResult: ISearchResult<IOrganization>) => {
                    this.fillOrganizations(searchResult.data);

                    resolve(this.organizations);
                };

                const onError = (response: any) => {
                    if (response.status !== 304) {
                        this.clearOrganizations();
                        reject(response);
                    } else {
                        resolve(this.organizations);
                    }
                };

                const serviceRequestOptions = new ServiceRequestOptions();
                serviceRequestOptions.includes.add("organization", "datePeriodSet");
                serviceRequestOptions.includes.add("user", "roles");

                const cacheOptions = new CacheOptions();
                cacheOptions.pushCacheResult = false;
                cacheOptions.pushCacheResultOnNotModified = !this.organizations;

                this.organizationApi.search$(null, serviceRequestOptions, cacheOptions).subscribe(onSuccess, onError);
            });

            const onPromiseEnd = () => {
                this.loadOrganizationsPromise = null;
            };

            this.loadOrganizationsPromise.then(onPromiseEnd, onPromiseEnd);
        }

        return this.loadOrganizationsPromise;
    }

    private fillOrganizations(organizations: IOrganization[]) {
        this.organizations = organizations;

        for (const organization of this.organizations) {
            this.enrichOrganization(organization);
        }

        this.updateOrganizations();
    }

    async addOrUpdateOrganization(organization: IOrganization) {
        const existingOrganization = this.organizations.filter((x) => x.id === organization.id).takeSingleOrDefault();
        if (existingOrganization) {
            Object.assign(existingOrganization, organization);
            this.enrichOrganization(existingOrganization);
            this.updateOrganization(existingOrganization);
            return;
        }

        this.organizations = [organization].concat(this.organizations);
        this.enrichOrganization(organization);
        this.updateOrganizations();
    }

    updateOrganization(organization: IOrganization) {
        this.eventService.processNext(this.organizationSubject, organization);
    }

    removeOrganization(organization: IOrganization) {
        organization.isObsolete = true;

        this.organizations = this.organizations.filter((x) => x !== organization);
        this.updateOrganizations();
    }

    async getOrganization$(callbackfn: (value: IOrganization) => boolean): Promise<IOrganization> {
        if (!this.organizations) {
            await this.loadOrganizations();
        }

        if (this.organizations) {
            for (const organization of this.organizations) {
                if (callbackfn(organization)) return organization;
            }
        }

        return null;
    }

    clearOrganizations() {
        this.organizations = null;
        this.loadOrganizationsPromise = null;
        this.updateOrganizations();
    }

    private enrichOrganization(organization: IOrganization) {
        if (!organization) return;

        if (organization.users) {
            for (const user of organization.users) {
                if (!user.userOrganizations) {
                    console.warn(
                        `Cannot enrich user ${user.firstName} ${user.lastName} for organization ${organization.name}`,
                    );
                    continue;
                }

                const existing = user.userOrganizations.find(
                    (x) => x && x.organization && x.organization.id === organization.id,
                );
                if (existing) {
                    console.log("Replacing Organization", existing.organization, organization);
                    existing.organization = organization;
                }
            }
        }
    }

    //#endregion Organizations

    //#region Group

    get editGroup(): IGroup {
        return this._editGroup;
    }

    set editGroup(group: IGroup) {
        if (this.editGroup === group) return;
        this._editGroup = lodash.cloneDeep(group);
        this.triggerEditGroupUpdate();
    }

    updateGroupMeasuringPointsInEditGroup(groupMeasuringPoints: IGroupMeasuringPoint[]) {
        const measuringPointsThatShouldBeInGroup = groupMeasuringPoints
            .filter((x) => x.includeForwardDirection || x.includeReverseDirection || x.includeSum)
            .map((x) => x.measuringPoint.id);
        const editGroup = this.editGroup;

        if (editGroup) {
            if (!editGroup.measuringPoints) {
                editGroup.measuringPoints = [];
            }

            for (const groupMeasuringPoint of groupMeasuringPoints) {
                const measuringPointInEditGroup = editGroup.measuringPoints.find(
                    (x) => x.measuringPoint.id === groupMeasuringPoint.measuringPoint.id,
                );

                if (
                    measuringPointsThatShouldBeInGroup.contains(groupMeasuringPoint.measuringPoint.id) &&
                    !measuringPointInEditGroup
                ) {
                    editGroup.measuringPoints = editGroup.measuringPoints.concat([groupMeasuringPoint]);
                } else if (
                    measuringPointsThatShouldBeInGroup.contains(groupMeasuringPoint.measuringPoint.id) &&
                    measuringPointInEditGroup
                ) {
                    // already exists in a group so we're going just to update selected values
                    measuringPointInEditGroup.includeForwardDirection = groupMeasuringPoint.includeForwardDirection;
                    measuringPointInEditGroup.includeReverseDirection = groupMeasuringPoint.includeReverseDirection;
                    measuringPointInEditGroup.includeSum = groupMeasuringPoint.includeSum;
                }

                if (
                    !measuringPointsThatShouldBeInGroup.contains(groupMeasuringPoint.measuringPoint.id) &&
                    measuringPointInEditGroup
                ) {
                    editGroup.measuringPoints = editGroup.measuringPoints.remove(measuringPointInEditGroup);
                }
            }
        }

        this.triggerEditGroupUpdate();
    }

    triggerEditGroupUpdate() {
        this.groupEditSubject.next(this.editGroup);
    }

    private onEditGroupUpdate(): Observable<IGroup> {
        return this.groupEditSubject.asObservable();
    }

    subscribeToEditGroupUpdate(key: string, onUpdate: (updatedGroup: IGroup) => void) {
        const subscription = this.onEditGroupUpdate().subscribe(onUpdate);
        this.subscriptionManager.add(`${key}editGroupUpdate`, subscription);
    }

    isMeasuringPointInGroup(measuringPointId: number): boolean {
        if (!this.editGroup) return false;
        return this.editGroup.measuringPoints.map((x) => x.measuringPoint.id).contains(measuringPointId);
    }

    isLocationInGroup(locationId: number): boolean {
        if (!this.editGroup) return false;

        return !!this.editGroup.measuringPoints.find((x) => x.measuringPoint.locationId === locationId);
    }

    //#endregion Group

    //#region Assignment
    subscribeToAssignmentLocations(
        key: string,
        callback?: (res: ILocationWithAssignmentsSummary[]) => void,
        errorCallback?: (res: Response) => void,
        forceUpdate = true,
    ) {
        const onSuccess = () => {
            if (callback) callback(this.getAssignmentLocations());
        };

        if (!errorCallback) errorCallback = () => {};
        const onError = (response: Response) => {
            if (errorCallback) errorCallback(response);
        };

        const subscription = this.onAssignmentLocationsUpdate().subscribe(onSuccess, onError);

        if (callback && this.assignmentLocations) {
            callback(this.getAssignmentLocations());
        }

        if (forceUpdate || !this.deviceLocations) {
            this.loadAssignmentLocations();
        }

        this.subscriptionManager.add(`${key}assignments`, subscription);
    }

    async loadAssignmentLocations() {
        if (!this.loadAsignmentLocationsPromise) {
            this.loadAsignmentLocationsPromise = new Promise<ILocationWithAssignmentsSummary[]>((resolve, reject) => {
                const onSuccess = (searchResult: IListResult<ILocationWithAssignmentsSummary>) => {
                    this.assignmentLocations = searchResult.data;
                    resolve(this.assignmentLocations);
                };

                const onError = (response: Response) => {
                    if (response.status !== 304) {
                        this.clearAssignmentLocations();
                        reject(response);
                    } else {
                        reject(response);
                    }
                };

                const cacheOptions = new CacheOptions();
                cacheOptions.pushCacheResult = false;
                cacheOptions.pushCacheResultOnNotModified = !this.assignmentLocations;

                this.locationWebApi.getAssignments$(cacheOptions).subscribe(onSuccess, onError);
            });

            const onPromiseEnd = () => {
                this.cancelLoadAssignmentLocations();
            };

            const onPromiseSuccess = () => {
                this.updateAssignmentLocations();
                onPromiseEnd();
            };

            this.loadAsignmentLocationsPromise.then(onPromiseSuccess, onPromiseEnd);
        }

        return this.loadAsignmentLocationsPromise;
    }

    clearAssignmentLocations() {
        this.assignmentLocations = null;
        this.updateAssignmentLocations();
    }

    cancelLoadAssignmentLocations() {
        this.loadAsignmentLocationsPromise = null;
    }

    private updateAssignmentLocations() {
        this.eventService.processNext(this.assignmentLocationsSubject, this.getAssignmentLocations, this);
    }

    private getAssignmentLocations(): ILocationWithAssignmentsSummary[] {
        return this.assignmentLocations || [];
    }

    private onAssignmentLocationsUpdate(): Observable<ILocationWithAssignmentsSummary[]> {
        return this.assignmentLocationsSubject.asObservable();
    }
    //#endregion Assignment

    //#region Projects

    clearProjects() {
        this.projects = null;
    }

    loadProjects(): Promise<IProject[]> {
        if (!this.loadProjectsPromise) {
            this.loadProjectsPromise = new Promise<IProject[]>((resolve, reject) => {
                const onSuccess = (searchResult: ISearchResult<IProject>) => {
                    this.projects = searchResult.data;
                    resolve(this.projects);
                };

                const onError = (response: any) => {
                    if (response.status !== 304) {
                        this.clearProjects();
                        reject(response);
                    } else {
                        resolve(this.projects);
                    }
                };

                const serviceRequestOptions = new ServiceRequestOptions();

                const cacheOptions = new CacheOptions();
                cacheOptions.pushCacheResult = false;
                cacheOptions.pushCacheResultOnNotModified = !this.projects;

                this.projectApi.search$(null, serviceRequestOptions, cacheOptions).subscribe(onSuccess, onError);
            });

            const onPromiseEnd = () => {
                this.loadProjectsPromise = null;
            };

            this.loadProjectsPromise.then(onPromiseEnd, onPromiseEnd);
        }

        return this.loadProjectsPromise;
    }

    async getProject$(callbackfn: (value: IProject) => boolean): Promise<IProject> {
        if (!this.projects) {
            await this.loadProjects();
        }

        if (this.projects) {
            for (const project of this.projects) {
                if (callbackfn(project)) return project;
            }
        }

        return null;
    }
    // #endregion Projects
}
