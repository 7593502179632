import { Component } from "@angular/core";
import { FileUtils } from "@ramudden/core/utils";
import { DocumentEventService, DownloadFileService, ImageConverterService } from "@ramudden/services";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { SvgComponent } from "../svg/svg.component";
import { GalleriaPreviewer, IGalleriaImage } from "./galleria.modal";

@Component({
    imports: [SvgComponent, NgxExtendedPdfViewerModule, PdfViewerModule],
    selector: "m-galleria",
    standalone: true,
    styleUrls: ["./galleria.component.scss"],
    templateUrl: "./galleria.component.html",
})
export class GalleriaComponent {
    private _visible = false;
    get visible(): boolean {
        return this._visible;
    }

    private _images: IGalleriaImage[];
    get images(): IGalleriaImage[] {
        return this._images;
    }

    private _index = 0;
    get index(): number {
        return this._index;
    }

    set index(index: number) {
        this._index = index;
    }

    get currentImage(): IGalleriaImage {
        return this._images[this.index];
    }

    private _navigatorImages: IGalleriaImage[];
    get navigatorImages(): IGalleriaImage[] {
        return this._navigatorImages;
    }

    loop = true;
    navigator = true;
    navigatorVisibleImages = 5;

    constructor(
        private readonly documentEventService: DocumentEventService,
        private readonly downloadFileService: DownloadFileService,
        private readonly imageConverterService: ImageConverterService,
    ) {}

    show(images: IGalleriaImage[], indexStart = 0) {
        if (!images || !images.length) {
            this.close();
            return;
        }

        this._images = images;
        this.index = indexStart;

        for (const image of this.images) {
            image.previewer = this.GetPreviewer(image);

            if (!image.previewImageSrc || image.source === image.previewImageSrc) {
                image.previewImageSrc =
                    image.previewer === GalleriaPreviewer.Pdf
                        ? "/assets/img/pdf-preview.png"
                        : image.previewer === GalleriaPreviewer.Video
                          ? "/assets/img/video-preview.png"
                          : image.previewImageSrc;
            }
        }

        this.refreshNavigatorImages();
        this.bindKeys();

        setTimeout(() => {
            this._visible = true;
        }, 100);
    }

    GetPreviewer(image: IGalleriaImage): GalleriaPreviewer {
        return FileUtils.isPdfUrl(image.source)
            ? GalleriaPreviewer.Pdf
            : FileUtils.isVideoUrl(image.source)
              ? GalleriaPreviewer.Video
              : GalleriaPreviewer.Photo;
    }

    private bindKeys() {
        this.documentEventService.addOnKeyPress("Escape", () => {
            this.close();
        });

        this.documentEventService.addOnKeyPress(
            "ArrowLeft",
            () => {
                this.previous();
            },
            true,
        );

        this.documentEventService.addOnKeyPress(
            "ArrowRight",
            () => {
                this.next();
            },
            true,
        );
    }

    private unbindKeys() {
        this.documentEventService.removeOnKeyPress("Escape");
        this.documentEventService.removeOnKeyPress("ArrowLeft");
        this.documentEventService.removeOnKeyPress("ArrowRight");
    }

    get hasPrevious(): boolean {
        return this.images && this.images.length > 1 && (this.loop || this.index - 1 > 0);
    }

    get hasNext(): boolean {
        return this.images && this.images.length > 1 && (this.loop || this.index + 1 < this.images.length);
    }

    previous() {
        if (--this.index < 0) this.index = this.images.length - 1;
        this.refreshNavigatorImages();
    }

    next() {
        if (++this.index >= this.images.length) this.index = 0;
        this.refreshNavigatorImages();
    }

    navigateToImage(image: IGalleriaImage) {
        this.index = this.images.indexOf(image);
        this.refreshNavigatorImages();
    }

    private refreshNavigatorImages() {
        if (!this.images) return;

        const indexMargin = (this.navigatorVisibleImages - 1) / 2;
        let beginImageIndex = this.index - indexMargin;
        let endImageIndex = this.index + indexMargin;

        // If less images than we wanna show
        // Or not looping and going before 0
        // Set start index to 0
        if (this.images.length < this.navigatorVisibleImages || (beginImageIndex < 0 && !this.loop)) {
            beginImageIndex = 0;
            endImageIndex = Math.min(this.images.length - 1, this.navigatorVisibleImages);
        }

        const images = new Array<IGalleriaImage>();

        if (beginImageIndex === endImageIndex) {
            images.push(this.currentImage);
        } else {
            for (let index = beginImageIndex; index <= endImageIndex; index++) {
                let actualIndex = index;
                if (actualIndex < 0) actualIndex = this.images.length + index;
                if (actualIndex > this.images.length - 1) actualIndex = index - this.images.length;

                images.push(this.images[actualIndex]);
            }
        }

        this._navigatorImages = images;
    }

    download() {
        this.downloadFileService.downloadBlob(this.currentImage.source).then(async (downloadedFile) => {
            // If current image is webp, convert to jpg
            if (downloadedFile.file.type === "image/webp") {
                await this.imageConverterService.convertAndDownloadWebpToJpeg(
                    await FileUtils.toBase64(downloadedFile.file),
                    downloadedFile.file.name,
                );
            } else {
                downloadedFile.save();
            }
        });
    }

    close() {
        this._visible = false;
        this.unbindKeys();
    }
}
