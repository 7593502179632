import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class ImageConverterService {
    private convertImage(imageData: string, format: "image/webp" | "image/jpeg"): Promise<string> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imageData;
            image.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                const ctx = canvas.getContext("2d");
                if (ctx) {
                    ctx.drawImage(image, 0, 0);
                    const dataUrl = canvas.toDataURL(format);
                    // remove canvas
                    canvas.remove();
                    resolve(dataUrl);
                } else {
                    reject(new Error("Failed to get canvas context"));
                }
            };
            image.onerror = (error) => {
                reject(error);
            };
        });
    }

    convertToWebP(imageData: string): Promise<string> {
        return this.convertImage(imageData, "image/webp");
    }

    convertToJpeg(imageData: string): Promise<string> {
        return this.convertImage(imageData, "image/jpeg");
    }

    async convertAndDownloadWebpToJpeg(imageData: string, fileName: string) {
        const dataUrl = await this.convertToJpeg(imageData);
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = fileName.replace(".webp", ".jpg");
        link.click();
        // remove link
        link.remove();
    }
}
