import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { SvgComponent } from "@ramudden/ui";

@Component({
    selector: "m-page-navigation",
    standalone: true,
    imports: [RouterLink, RouterOutlet, RouterLinkActive, SvgComponent, TranslateModule],
    templateUrl: "./navigation.component.html",
    styleUrl: "./navigation.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
    plannedAssignmentId = input.required<number>();
}
