import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { SubscriptionManager } from "@ramudden/core/utils";
import { JournalApi } from "@ramudden/data-access/resource/journal.api";
import { IAttachment } from "@ramudden/models/attachment";
import { IPlannedAssignment } from "@ramudden/models/planned-event";
import { FilterOperator, SearchParameters, ServiceRequestOptions } from "@ramudden/models/search";
import { SvgComponent } from "@ramudden/ui";
import { firstValueFrom } from "rxjs";
import { DefaultComponent } from "../../layout/content/default/default.component";
import { AppService } from "../../services/app.service";
import { AssignmentService } from "../../services/assignment.service";
import { TitleService } from "../../services/title.service";
import { PdfViewerDialogComponent } from "../assignment-container/assignment-details/dialogs/pdf-viewer/pdf-viewer-dialog.component";
import { AssignmentCardComponent } from "./assignment-card/assignment-card.component";

@Component({
    selector: "app-assignments",
    standalone: true,
    templateUrl: "./assignments.component.html",
    styleUrl: "./assignments.component.scss",
    imports: [DefaultComponent, AssignmentCardComponent, SvgComponent, TranslateModule, PdfViewerDialogComponent],
})
export class AssignmentsComponent implements OnInit, OnDestroy {
    private readonly appService = inject(AppService);
    private readonly router = inject(Router);
    private readonly assignmentService = inject(AssignmentService);
    private readonly titleService = inject(TitleService);
    private readonly subscriptionManager = new SubscriptionManager();

    todayAssignments: IPlannedAssignment[] = [];
    tomorrowAssignments: IPlannedAssignment[] = [];
    groupedAttachments: { [key: number]: IAttachment[] } = {};

    async ngOnInit() {
        this.titleService.title = "Markings as a Service";

        if (!this.appService.config.isConfigured) {
            this.router.navigate(["/setup"]);
        }

        const assignmentSchedule = await this.assignmentService.getAssignmentSchedule();
        this.todayAssignments = assignmentSchedule.todayAssignments;
        this.tomorrowAssignments = assignmentSchedule.tomorrowAssignments;
        this.getJournals();
    }

    ngOnDestroy() {
        this.subscriptionManager.clear();
    }

    protected onAssignmentClicked(plannedAssignment: IPlannedAssignment) {
        this.assignmentService.selectedPlannedAssignment = plannedAssignment;
        this.router.navigate(["assignment", plannedAssignment.id, "details"]);
    }

    private readonly journalApi = inject(JournalApi);

    async getJournals() {
        const assignmentIds = [...this.todayAssignments, ...this.tomorrowAssignments]
            .map((a) => a.assignment.id)
            .distinct()
            .join("|");
        const projectIds = [...this.todayAssignments, ...this.tomorrowAssignments]
            .map((a) => a.assignment.project?.id || a.assignment.parentAssignment?.project?.id)
            .distinct()
            .join("|");

        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("journal", "attachments");

        const searchParametersAssignments = new SearchParameters();
        searchParametersAssignments.filter = [
            { field: "assignmentId", value: assignmentIds, operator: "in" },
            { field: "IsAdminOnly", value: "false", operator: FilterOperator.equals },
        ];

        const journals = await firstValueFrom(
            this.journalApi.getAll$(searchParametersAssignments, null, serviceRequestOptions),
        );

        const searchParametersProjects = new SearchParameters();
        searchParametersProjects.filter = [
            { field: "projectId", value: projectIds, operator: "in" },
            { field: "IsAdminOnly", value: "false", operator: FilterOperator.equals },
        ];

        journals.push(
            ...(await firstValueFrom(this.journalApi.getAll$(searchParametersProjects, null, serviceRequestOptions))),
        );

        this.groupedAttachments = {};
        this.todayAssignments.forEach((a) => {
            this.groupedAttachments[a.assignment.id] = journals
                .filter(
                    (j) =>
                        (j?.assignment?.id && j.assignment.id === a.assignment.id) ||
                        (j?.project?.id && j.project.id === a.assignment.project?.id) ||
                        (j?.project?.id && j.project.id === a.assignment.parentAssignment?.project?.id),
                )
                .map((j) => j.attachments)
                .flat();
        });

        this.tomorrowAssignments.forEach((a) => {
            this.groupedAttachments[a.assignment.id] = journals
                .filter(
                    (j) =>
                        (j?.assignment?.id && j.assignment.id === a.assignment.id) ||
                        (j?.project?.id && j.project.id === a.assignment.project?.id) ||
                        (j?.project?.id && j.project.id === a.assignment.parentAssignment?.project?.id),
                )
                .map((j) => j.attachments)
                .flat();
        });
    }
}
