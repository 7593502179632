import { CommonModule } from "@angular/common";
import { Component, inject, OnInit, output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { PlannedEventApi } from "@ramudden/data-access/resource/planned-event-api";
import { WorkerApi } from "@ramudden/data-access/resource/worker.api";
import { IPlannedEvent } from "@ramudden/models/planned-event";
import {
    FilterDescriptor,
    FilterOperator,
    SearchParameters,
    ServiceRequestOptions,
    SortDescriptor,
    SortDirection,
} from "@ramudden/models/search";
import { IWorker } from "@ramudden/models/worker";
import { ModalComponent } from "@ramudden/ui";
import moment from "moment/moment";
import { firstValueFrom, Observable } from "rxjs";
import { DropdownComponent } from "../../../../components/dropdown/dropdown.component";
import { DropdownOption } from "../../../../components/dropdown/dropdown.model";
import { AuthenticationService } from "../../../../services/generic/authentication.service";
import { DomainDataService } from "../../../../services/generic/domain-data.service";
import { LoaderService } from "../../../../services/loader.service";

export interface UserDialogType {
    worker: IWorker;
    plannedEvent: IPlannedEvent;
    language: string;
}

@Component({
    selector: "app-user-dialog",
    standalone: true,
    imports: [CommonModule, DropdownComponent, ReactiveFormsModule, ModalComponent, TranslateModule],
    templateUrl: "./user-dialog.component.html",
})
export class UserDialogComponent implements OnInit {
    @ViewChild(ModalComponent, { static: true }) dialog: ModalComponent;
    @ViewChild("teamDropdown") teamDropdown: DropdownComponent;

    nextClicked = output<UserDialogType>();

    //region Variables
    isLoading: Observable<boolean>;
    languages: DropdownOption<string>[] = [];
    teamForm: FormGroup;
    teams: DropdownOption<IPlannedEvent>[] = [];
    workers: DropdownOption<IWorker>[] = [];
    //endregion

    //region Services
    private readonly authenticationService = inject(AuthenticationService);
    private readonly domainDataService = inject(DomainDataService);
    private readonly formBuilder = inject(FormBuilder);
    private readonly loaderService = inject(LoaderService);
    private readonly plannedEventApi = inject(PlannedEventApi);
    private readonly translateService = inject(TranslateService);
    private readonly workerApi = inject(WorkerApi);
    //endregion

    ngOnInit() {
        this.isLoading = this.loaderService.loading$;
    }

    async openModal(input?: UserDialogType) {
        this.translateService.onLangChange.subscribe(() => {
            this.setLanguages();
        });

        this.setLanguages();
        await this.setWorkers();
        this.createForm(input);
        this.dialog.openModal();
    }

    //region Form
    private createForm(input?: UserDialogType) {
        const worker = input?.worker ? `${input.worker.firstName} ${input.worker.lastName}` : "";

        this.teamForm = this.formBuilder.group({
            worker: [worker, Validators.required],
            team: [input?.plannedEvent ?? "", Validators.required],
            language: [input?.language ?? this.translateService.currentLang, Validators.required],
        });
    }

    protected submit() {
        if (this.teamForm.valid) {
            const selectedPlannedEvent = this.teamForm.value.team as IPlannedEvent;
            selectedPlannedEvent.plannedWorkers.forEach((plannedWorker, index) => {
                plannedWorker.isTeamLeader = index === 0;
            });
            this.dialog.closeModal();
            this.nextClicked.emit({
                worker: this.teamForm.value.worker,
                plannedEvent: selectedPlannedEvent,
                language: this.teamForm.value.language,
            });
        }
    }

    protected logout() {
        this.authenticationService.signoutSilently();
    }
    //endregion

    //region Setting data
    private setLanguages() {
        this.languages = ["en", "nl", "fr"].map((language) => ({
            value: language,
            label: this.domainDataService.translateEnum("language", language),
        }));
    }

    private async setWorkers() {
        const workers = await this.fetchWorkers();

        this.workers = workers.map((worker) => {
            return {
                label: `${worker.firstName} ${worker.lastName}`,
                value: worker,
            };
        });
    }

    private async setTeams(workerId: number) {
        this.teams = [];
        const plannedEvents = await this.fetchPlannedEvents(workerId);
        plannedEvents.forEach((plannedEvent) => {
            this.teams.push({
                label: plannedEvent.plannedWorkers
                    .map((plannedWorker) => `${plannedWorker.worker.firstName} ${plannedWorker.worker.lastName}`)
                    .join(", "),
                value: plannedEvent,
            });
        });
    }

    //endregion

    //region Selection changed handlers
    protected async workerChanged(worker: IWorker): Promise<void> {
        if (this.teamDropdown.value) {
            this.teamDropdown.value = null;
            this.teamDropdown.label = "";
            this.teamForm.controls.team.setValue(null);
        }

        await this.setTeams(worker.id);

        if (this.teams.length === 1) {
            this.teamDropdown.value = this.teams[0].value;
            this.teamDropdown.label = this.teams[0].label;
            this.teamForm.controls.team.setValue(this.teams[0].value);
        }
    }

    protected languageChanged(language: string): void {
        this.translateService.use(language);
    }

    //endregion

    //region API Calls
    private async fetchPlannedEvents(workerId: number): Promise<IPlannedEvent[]> {
        const noon = moment()
            .day(moment().day())
            .month(moment().month())
            .year(moment().year())
            .hour(12)
            .minute(0)
            .second(0);

        const searchParameters = new SearchParameters();
        searchParameters.filter = [];

        if (moment() > noon) {
            searchParameters.filter.push(
                new FilterDescriptor("EventDate", moment().format("YYYY/MM/DD"), FilterOperator.equals),
            );
        } else {
            searchParameters.filter.push(
                new FilterDescriptor(
                    "EventDate",
                    moment().subtract(1, "days").format("YYYY/MM/DD"),
                    FilterOperator.greaterThanOrEqualTo,
                ),
            );
            searchParameters.filter.push(
                new FilterDescriptor("EventDate", moment().format("YYYY/MM/DD"), FilterOperator.lessThanOrEqualTo),
            );
        }

        searchParameters.filter.push(new FilterDescriptor("PlannedWorkers", workerId, FilterOperator.contains));

        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("PlannedEvent", "PlannedWorkers");
        serviceRequestOptions.includes.add("PlannedEvent", "PlannedAssignments");
        serviceRequestOptions.includes.add("PlannedEvent", "PlannedVehicles");
        serviceRequestOptions.includes.add("PlannedWorker", "Worker");
        serviceRequestOptions.includes.add("Project", "organization");

        this.loaderService.show();
        let plannedEvents = await firstValueFrom(
            this.plannedEventApi.getAll$(searchParameters, false, serviceRequestOptions),
        ).finally(() => this.loaderService.hide());

        if (moment() <= noon) {
            plannedEvents = plannedEvents.filter(
                (x) =>
                    (x.isNightShift &&
                        moment(x.eventDate).mFormatDate() === moment().subtract(1, "days").mFormatDate()) ||
                    (!x.isNightShift && moment(x.eventDate).mFormatDate() === moment().mFormatDate()),
            );
        }

        return plannedEvents;
    }

    private async fetchWorkers() {
        const today = moment().format("YYYY/MM/DD");

        const searchParameters = new SearchParameters();
        searchParameters.sort = [new SortDescriptor(SortDirection.ascending, "FirstName")];
        searchParameters.filter = [];
        searchParameters.filter.push(new FilterDescriptor("inServiceAtPeriod", `${today}|${today}`));

        this.loaderService.show();
        return await firstValueFrom(this.workerApi.getAll$(searchParameters)).finally(() => this.loaderService.hide());
    }
    //endregion
}
