<m-modal
    #modal
    title="Neem foto"
>
    <ng-container body>
        @if (modal.modalOpen) {
            <video
                id="qrVideo"
                #qrVideo
                style="max-width: 100%"
            ></video>
        }
    </ng-container>
</m-modal>
