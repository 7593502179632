<h2 class="mb-24">{{ "parkingBanPage.exceptions" | translate }}</h2>

@if (exceptionsForm) {
    <form
        class="flex flex-column gap-16"
        [formGroup]="exceptionsForm"
    >
        <div class="card">
            <div class="card__body">
                <button
                    class="btn--yellow"
                    (click)="mPhotoInput.open()"
                >
                    {{ "parkingBanPage.scanLicensePlate" | translate }}
                </button>

                @if (exceptions.controls.length) {
                    <div class="attachments mt-16">
                        @for (exceptionForm of exceptions.controls; track $index) {
                            <ng-container [formGroup]="exceptionForm">
                                <div class="attachment">
                                    <button
                                        class="attachment__delete btn--icon"
                                        (click)="onExceptionDelete(exceptionForm.get('exceptionId').value, $index)"
                                    >
                                        <m-svg iconName="delete" />
                                    </button>

                                    <img
                                        class="attachment__image"
                                        [alt]="exceptionForm.get('exceptionLicensePlate').value"
                                        [src]="exceptionForm.get('exceptionImage').value"
                                        (click)="openGalleria($index)"
                                    />

                                    <div class="attachment__body form__fields">
                                        <div class="form__field">
                                            <label for="exceptionLicensePlate">
                                                {{ "parkingBanPage.licensePlate" | translate }}
                                            </label>
                                            <input
                                                class="input--text"
                                                id="exceptionLicensePlate"
                                                (delayedInput)="
                                                    onLicensePlateChange(exceptionForm.get('exceptionId').value, $event)
                                                "
                                                appDelayedInput
                                                formControlName="exceptionLicensePlate"
                                                placeholder=""
                                                type="text"
                                            />
                                        </div>

                                        <div class="form__field">
                                            <label for="signScanLocation">
                                                {{ "parkingBanPage.address" | translate }}
                                            </label>
                                            <input
                                                class="input--text"
                                                id="signScanLocation"
                                                [value]="getAddress(exceptionForm.get('location').value)"
                                                (click)="openDialog($index)"
                                                readonly
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        }
                    </div>
                }
            </div>
        </div>
    </form>
}

<m-photo-input
    #mPhotoInput
    (fileLoadEnd)="onFileLoadEnd($event)"
/>

<app-maps-dialog [coordinates]="" />
