import { inject, Injectable } from "@angular/core";
import { SignScanApi } from "@ramudden/data-access/resource/sign-scan.api";
import { ServiceRequestOptions } from "@ramudden/models/search";
import { ISignScan, SignScanUpdater } from "@ramudden/models/sign-scan";
import { firstValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class SignScanService {
    private readonly signScanApi = inject(SignScanApi);

    isCheckedOut(signScan: ISignScan): boolean {
        if (signScan?.checkOut === undefined) return false;
        return signScan.checkOut.getTime() <= new Date().getTime();
    }

    isLost(signScan: ISignScan): boolean {
        if (signScan?.lost === undefined) return false;
        return signScan.lost.getTime() <= new Date().getTime();
    }

    async fetchSignScan(signScanId: number): Promise<ISignScan> {
        const options = new ServiceRequestOptions();
        options.includes.add("SignScan", "Location");
        options.includes.add("SignScan", "Photos");

        return await firstValueFrom(this.signScanApi.get$(signScanId, null, options));
    }

    async markAsCheckedOut(signScan: ISignScan, parkingBanId: number) {
        if (this.isCheckedOut(signScan) && signScan.checkOut) return;

        const signScanUpdater = new SignScanUpdater(signScan);
        signScanUpdater.parkingBanId = parkingBanId;
        signScanUpdater.checkOut = new Date();

        if (signScanUpdater.checkOut) {
            signScanUpdater.lost = null;
        }

        await firstValueFrom(this.signScanApi.update$(signScanUpdater));
    }

    async markAsLost(signScan: ISignScan, parkingBanId: number) {
        if (this.isLost && signScan.lost) {
            return;
        }

        const signScanUpdater = new SignScanUpdater(signScan);
        signScanUpdater.parkingBanId = parkingBanId;
        signScanUpdater.lost = new Date();

        if (signScanUpdater.lost) {
            signScanUpdater.checkOut = null;
        }

        await firstValueFrom(this.signScanApi.update$(signScanUpdater));
    }
}
