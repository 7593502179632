import { CommonModule } from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    inject,
    input,
    output,
    ViewChild,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { DocumentEventService } from "@ramudden/services";
import { ModalConfig, ModalConfigAction } from "../modal";
import { SvgComponent } from "../svg";

@Component({
    selector: "m-modal",
    standalone: true,
    imports: [CommonModule, SvgComponent, TranslateModule],
    templateUrl: "./modal.component.html",
    styleUrl: "./modal.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
    @ViewChild("modal", { static: true }) modalElement!: ElementRef<HTMLDialogElement>;
    @ViewChild("modalContent", { static: true }) modalContentElement!: ElementRef;

    config?: ModalConfig;
    doNotShowAgain = false;
    hasFooter = false;

    close = output<ModalConfigAction>();

    @ContentChild("footer") set setFooter(footer: HTMLElement) {
        this.hasFooter = !!footer;
    }

    title = input.required<string>();
    canClose = input<boolean>(true);

    private readonly documentService = inject(DocumentEventService);

    get modalOpen() {
        return this.modalElement.nativeElement.open;
    }

    openModal(event?: Event) {
        if (event) event.stopPropagation();
        this.modalElement.nativeElement.show();

        this.documentService.addOnKeyPress("Escape", this.keyPressHandler);

        if (this.canClose()) {
            window.addEventListener("click", this.mouseClickHandler, true);
        }
    }

    closeModal(action?: ModalConfigAction) {
        this.close.emit(action);

        if (this.canClose()) {
            window.removeEventListener("click", this.mouseClickHandler, true);
        }

        this.documentService.removeOnKeyPress("Escape");

        this.modalElement.nativeElement.close();
    }

    keyPressHandler = () => {
        if (this.canClose()) {
            this.closeModal(this.config?.outsideClickAction);
        }
    };

    mouseClickHandler = (event: Event) => {
        const userClickedOutside = this.isOutsideOfElement(event, this.modalContentElement.nativeElement);
        if (userClickedOutside && this.config?.canClose === true) {
            this.closeModal(this.config?.outsideClickAction);
        }
    };

    isOutsideOfElement(event: Event, element: any) {
        return !element.contains(event.target);
    }

    onChangeDoNotShow(event: Event) {
        this.doNotShowAgain = (event.target as HTMLInputElement).checked;
    }
}
