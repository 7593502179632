import { Injectable } from "@angular/core";
import { ITaskRule } from "@ramudden/models/task-rule";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class TaskRuleApi extends ApiService<ITaskRule, ITaskRule, ITaskRule> {
    override getRoute(): string {
        return "TaskRules";
    }
}
