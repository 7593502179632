import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { IAssignment } from "@ramudden/models/assignment";
import { IParkingBan } from "@ramudden/models/parking-ban";
import { ToastService } from "@ramudden/ui";
import { StepIndexDirective } from "../../../../components/stepper/step-index.directive";
import { StepperComponent } from "../../../../components/stepper/stepper.component";
import { LayoutStepperComponent } from "../../../../layout/content/stepper/stepper.component";
import { AssignmentService } from "../../../../services/assignment.service";
import { LoaderService } from "../../../../services/loader.service";
import { ParkingBanService } from "../../../../services/parking-ban.service";
import { TitleService } from "../../../../services/title.service";
import { CpbStep1Component } from "./cpb-step-1/cpb-step-1.component";
import { CpbStep2Component } from "./cpb-step-2/cpb-step-2.component";
import { CpbStep3Component } from "./cpb-step-3/cpb-step-3.component";

@Component({
    selector: "app-create-parking-ban",
    standalone: true,
    imports: [
        CommonModule,
        StepperComponent,
        StepIndexDirective,
        ReactiveFormsModule,
        LayoutStepperComponent,
        TranslateModule,
        CpbStep1Component,
        CpbStep2Component,
        CpbStep3Component,
    ],
    templateUrl: "./create-parking-ban.component.html",
})
export class CreateParkingBanComponent implements OnInit {
    private readonly loaderService = inject(LoaderService);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly titleService = inject(TitleService);
    private readonly toastService = inject(ToastService);
    private readonly translateService = inject(TranslateService);
    protected readonly assignmentService = inject(AssignmentService);
    protected readonly parkingBanService = inject(ParkingBanService);

    assignment: IAssignment;
    isStepValid = [false, true, true];

    parkingBan: IParkingBan;

    parkingBanForm: FormGroup;
    signScanForm: FormGroup;

    //region Implements
    async ngOnInit() {
        const plannedAssignmentId = this.route.snapshot.params["plannedAssignmentId"];
        if (!plannedAssignmentId) {
            this.router.navigate(["/assignments"]);
            return;
        }

        this.titleService.title = this.translateService.instant("parkingBanPage.createParkingBan");
        this.titleService.goBack.set(["/assignment", plannedAssignmentId, "parking-bans"]);
    }

    //endregion

    //region Stepper
    async onStepChanged(index: number) {
        if (index === 2) {
            if (!this.parkingBan) {
                this.loaderService.show();
                this.parkingBan = await this.createParkingBan().finally(() => this.loaderService.hide());
            }
        }
    }

    async onFinish() {
        if (!this.parkingBan) {
            this.parkingBan = await this.createParkingBan();
        }
        await this.uploadParkingBanAttachments(this.parkingBan);
        await this.createSignScans(this.parkingBan);

        this.router.navigate(["/assignment", this.route.snapshot.params["plannedAssignmentId"], "parking-bans"]);
        this.toastService.showSuccess(this.translateService.instant("parkingBanPage.parkingBanCreated"));
    }

    //endregion

    async onStep1Destroy(parkingBanForm: FormGroup) {
        this.parkingBanForm = parkingBanForm;
        if (this.parkingBan) {
            await this.updateParkingBan();
        }
    }

    //region API Calls
    async createParkingBan() {
        const assignment = this.assignmentService.selectedPlannedAssignment.assignment;

        const parkingBan = {
            id: null,
            name: this.parkingBanForm.get("name").value,
            description: this.parkingBanForm.get("description").value,
            start: this.parkingBanForm.get("start").value,
            end: this.parkingBanForm.get("end").value,
            location: this.parkingBanForm.get("location").value,
            assignmentId: assignment.id,
            assignment: assignment,
            isCompleted: false,
        } as IParkingBan;

        return await this.parkingBanService.createParkingBan(parkingBan);
    }

    async updateParkingBan() {
        const parkingBan = {} as IParkingBan;
        Object.assign(parkingBan, this.parkingBan, this.parkingBanForm.getRawValue());
        parkingBan.location = this.parkingBan.location;

        this.parkingBan = await this.parkingBanService.updateParkingBan(parkingBan);
    }

    async uploadParkingBanAttachments(parkingBan: IParkingBan) {
        const attachments = this.parkingBanForm.get("attachments").value as any[];

        for (const attachment of attachments) {
            await this.parkingBanService.createParkingBanAttachment(
                parkingBan,
                attachment["attachmentImage"],
                attachment["attachmentDescription"],
            );
        }
    }

    async createSignScans(parkingBan: IParkingBan) {
        const signScans = this.signScanForm.get("signs").value;

        const organizationId =
            this.assignmentService.selectedPlannedAssignment.assignment?.project?.organizationId ??
            this.assignmentService.selectedPlannedAssignment.assignment?.parentAssignment?.project?.organizationId;

        for (const signScan of signScans) {
            await this.parkingBanService.createParkingBanSignScan(
                parkingBan,
                organizationId,
                signScan["location"].coordinate,
                signScan["qrCodeNumber"],
            );
        }
    }

    //endregion
}
