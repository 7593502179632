import { Injectable } from "@angular/core";
import { ISignCategory, SignCategoryCreator } from "@ramudden/models/signcategory";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class SignCategoryApi extends ApiService<ISignCategory, SignCategoryCreator, SignCategoryCreator> {
    override getRoute(): string {
        return "SignCategories";
    }
}
