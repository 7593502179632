import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { IParkingBan } from "@ramudden/models/parking-ban";
import { ModalService, SvgComponent } from "@ramudden/ui";
import moment from "moment";
import { DefaultComponent } from "../../../layout/content/default/default.component";
import { MomentFormatPipe } from "../../../pipes/datetime.pipe";
import { AssignmentService } from "../../../services/assignment.service";
import { LoaderService } from "../../../services/loader.service";
import { LocationService } from "../../../services/location.service";
import { ParkingBanService } from "../../../services/parking-ban.service";

@Component({
    selector: "app-assignment-parking-bans",
    standalone: true,
    imports: [CommonModule, SvgComponent, DefaultComponent, TranslateModule, MomentFormatPipe],
    templateUrl: "./assignment-parking-bans.component.html",
    styleUrl: "./assignment-parking-bans.component.scss",
})
export class AssignmentParkingBansComponent implements OnInit {
    private readonly assignmentService = inject(AssignmentService);
    private readonly loaderService = inject(LoaderService);
    private readonly locationService = inject(LocationService);
    private readonly modalService = inject(ModalService);
    private readonly parkingBanService = inject(ParkingBanService);
    private readonly router = inject(Router);
    private readonly translateService = inject(TranslateService);

    parkingBans: IParkingBan[] = [];

    get siteActive() {
        const plannedAssignment = this.assignmentService.selectedPlannedAssignment;
        return (
            plannedAssignment.currentTeamStatus?.teamArrival !== undefined &&
            plannedAssignment.currentTeamStatus?.teamDeparture === undefined
        );
    }

    get editableAssignment() {
        return this.assignmentService.selectedPlannedAssignment.isPlannedToday;
    }

    numberPlates(parkingBan: IParkingBan): string {
        return parkingBan.exceptions.map((e) => e.licensePlate).join(", ");
    }

    address(parkingBan: IParkingBan): string {
        const address = parkingBan.location.address;
        if (address) {
            return this.locationService.convertToAddressString(address);
        }
        return "";
    }

    async ngOnInit() {
        this.parkingBans = await this.fetchParkingBans();
    }

    //region Actions
    async onRefresh() {
        this.parkingBans = await this.fetchParkingBans();
    }

    createParkingBan() {
        const plannedAssignmentId = this.assignmentService.selectedPlannedAssignment.id;
        this.router.navigate(["/assignment", plannedAssignmentId, "parking-ban", "create"]);
    }

    editParkingBan(parkingBan: IParkingBan) {
        const plannedAssignmentId = this.assignmentService.selectedPlannedAssignment.id;
        this.router.navigate(["/assignment", plannedAssignmentId, "parking-ban", parkingBan.id]);
    }

    async deleteParkingBan(parkingBan: IParkingBan) {
        const answer = await this.modalService.confirmAsync(
            this.translateService.instant("parkingBanPage.deleteParkingBanConfirmation"),
        );
        if (!answer) {
            return;
        }
        this.parkingBanService.deleteParkingBan(parkingBan.id).then(() => this.onRefresh());
    }

    getStatusColor(parkingBan: IParkingBan): string {
        return parkingBan.isCompleted ? "var(--color-finished)" : "var(--color-in-progress)";
    }

    //region Fetch data
    private async fetchParkingBans(): Promise<IParkingBan[]> {
        const assignmentId = this.assignmentService.selectedPlannedAssignment.assignmentId;
        if (assignmentId <= 0) return Promise.resolve([] as IParkingBan[]);

        this.loaderService.show();
        const parkingBans = await this.parkingBanService
            .getAllParkingBans(assignmentId)
            .finally(() => this.loaderService.hide());
        return parkingBans.sort((a, b) => {
            // Sort by name asc, then by start asc
            const nameComparison = a.name.localeCompare(b.name);
            if (nameComparison !== 0) {
                return nameComparison;
            }
            return moment(a.start).diff(moment(b.start));
        });
    }

    //endregion
}
