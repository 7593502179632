import { ExternalInputType } from "./external-input-configuration";
import { LightAction } from "./lightAction";
import { IMeasuringPointStatusData } from "./measuring-point-status";
import { ILightMode } from "./mode-configuration";
import { IScenarioNavigator } from "./scenario";
import { ITotemDisplayConfiguration } from "./totem-display-configuration";
import { VehicleCategory } from "./vehicle";

export interface IHistoricalData {
    dataSets: IVehicleHistoryDataSet[];
}

export interface IVehicleHistoryDataSet {
    dataSetType: DataSetType;
    dataSetSubType: DataSetSubType;
    vehicleCategory: VehicleCategory;
    history: IVehicleHistoryDataPoint[];
}

export interface IVehicleHistoryDataPoint {
    timestamp: Date;
    value?: number;
}

export interface ILiveData {
    lastValuesGroups: ILiveValuesPerType[];
    from?: Date;
    until?: Date;
    lastUpdate?: Date;
    pinnedData: IPinnedData;
    currentStatus: IMeasuringPointStatusData;
    linkedScenarios: IScenarioNavigator[];
}

export interface ILiveValuesPerType {
    dataSetType: DataSetType;
    lastValues: ILiveValue[];
}

export interface ILiveValue {
    value?: number;
    dataSetSubType: DataSetSubType;
}

export enum LiveDataRangeOption {
    Today = "today",
    Hour = "hour",
    Minutes15 = "minutes15",
    Minutes5 = "minutes5",
}

export interface IPinnedData {
    externalInput: IExternalInputPinnedData;
    light: ILightPinnedData;
    totemDisplay: ITotemDisplayConfiguration;
}

// used for configuration of chart
export enum DataSetType {
    Speed = "speed",
    Distance = "distance",
    Intensity = "intensity",
    ParkingOccupancy = "parkingOccupancy",
    Occupancy = "occupancy",
}

// used for configuration of chart
export enum DataSetSubType {
    SpeedV50 = "v50",
    SpeedV85 = "v85",
    SpeedV95 = "v95",
    DistanceA05 = "a05",
    DistanceA15 = "a15",
    DistanceA50 = "a50",
    IntensityLight = "intensityLight",
    IntensityMedium = "intensityMedium",
    IntensityHeavy = "intensityHeavy",
    IntensityBikes = "intensityBikes",
    IntensityMopeds = "intensityMopeds",
    IntensityPedestrians = "intensityPedestrians",
    ParkingOccupancyOccupiedPlaces = "parkingOccupancyOccupiedPlaces",
    ParkingOccupancyAvailablePlaces = "parkingOccupancyAvailablePlaces",
    ParkingOccupancyTotalCapacity = "parkingOccupancyTotalCapacity",
    AverageSpeed = "averageSpeed",
    OccupancyAverage = "occupancyAverage",
}

export interface IExternalInputPinnedData {
    type: ExternalInputType;
    inputs: InputData[];
}

export interface InputData {
    id: string;
    description: string;
    value?: boolean;
    trueIconUrl?: string;
    trueIconPreview?: string;
    falseIconUrl?: string;
    falseIconPreview?: string;
}

export interface ILightPinnedData {
    currentAction: LightAction;
    iconUrl?: string;
    modes: ILightMode[];
}

export interface IPinnedDataConfigurationPerAnalysisType {
    supportedDataSets: IDataSetConfiguration[];
    defaultLiveDataRangeOption?: LiveDataRangeOption;
}

export interface IDataSetConfiguration {
    dataSetType: DataSetType;
    availableSubTypes: DataSetSubType[];
    supportedTimeAggregations: TimeAggregationType[];
}

export interface ICountingZoneStatus {
    vehicles: string[];
    removedVehicles: string[];
    accessStatus?: CountingZoneAccessStatus;
}

export enum CountingZoneAccessStatus {
    Closed = "closed",
    OpenForward = "openForward",
    OpenReverse = "openReverse",
}

export enum TimeAggregationType {
    Day = "day",
    Hour = "hour",
    Minutes15 = "minutes15",
    Minutes5 = "minutes5",
    Minutes1 = "minutes1",
}
