import { DATE_PIPE_DEFAULT_OPTIONS } from "@angular/common";
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    provideHttpClient,
    withFetch,
    withInterceptorsFromDi,
} from "@angular/common/http";
import {
    APP_INITIALIZER,
    ApplicationConfig,
    ErrorHandler,
    importProvidersFrom,
    provideZoneChangeDetection,
} from "@angular/core";
import { provideRouter } from "@angular/router";
import { provideServiceWorker } from "@angular/service-worker";
import { MSAL_INSTANCE, MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { BrowserCacheLocation, IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { APP_CONFIGURATION, AppConfiguration } from "@ramudden/core/constants";
import { JsonInterceptor } from "@ramudden/interceptors";
import { ConfigurationService } from "@ramudden/services";
import { Angulartics2Module } from "angulartics2";
import { provideToastr } from "ngx-toastr";
import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { appRoutes } from "./app.routes";
import { SigncoErrorHandler } from "./error-handler";
import { AppConfiguredGuard } from "./guards/app-configured.guard";
import { AuthGuard } from "./guards/auth.guard";
import { AddWorkerInterceptor } from "./interceptor/add-worker.interceptor";
import { AuthInterceptor } from "./interceptor/auth.interceptor";
import { DefaultInterceptor } from "./interceptor/default.interceptor";
import { NoInternetInterceptor } from "./interceptor/no-internet.interceptor";
import { DomainDataService } from "./services/generic/domain-data.service";

export function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./i18n/", ".json");
}

export function msalConfigFactory(configurationService: ConfigurationService): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: configurationService.configuration.azureAuthenticationClientId,
            authority: configurationService.configuration.azureAuthenticationAuthority,
            redirectUri: window.location.origin,
            knownAuthorities: [configurationService.configuration.azureAuthenticationAuthority],
            postLogoutRedirectUri: window.location.origin,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.SessionStorage, //Seems to be problems with LocalStorage (cache misses)
        },
        system: {
            loggerOptions: {
                loggerCallback(_logLevel: LogLevel, message: string) {
                    console.log(message);
                },
                logLevel: LogLevel.Warning,
                piiLoggingEnabled: false,
            },
        },
    });
}

export const appConfig: ApplicationConfig = {
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NoInternetInterceptor,
            multi: true,
        },
        {
            provide: APP_CONFIGURATION,
            useValue: {
                svgVersion: environment.svgVersion,
            } as AppConfiguration,
        },
        provideHttpClient(withInterceptorsFromDi(), withFetch()),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(appRoutes),
        provideServiceWorker("ngsw-worker.js", {
            enabled: !location.hostname.includes("localhost"),
            registrationStrategy: "registerWhenStable:30000",
        }),
        importProvidersFrom(Angulartics2Module.forRoot()),
        importProvidersFrom(
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: httpLoaderFactory,
                    deps: [HttpClient],
                },
            }),
        ),
        provideToastr(),
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigurationService, domainDataService: DomainDataService) => async () => {
                await AppComponent.attemptStartup(configService, domainDataService);
            },
            deps: [ConfigurationService, DomainDataService],
            multi: true,
        },
        AuthGuard,
        AppConfiguredGuard,
        [
            {
                provide: ErrorHandler,
                useClass: SigncoErrorHandler,
            },
        ],
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AddWorkerInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DefaultInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JsonInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: msalConfigFactory,
            deps: [ConfigurationService],
        },
        MsalService,
        MsalBroadcastService,
        {
            provide: DATE_PIPE_DEFAULT_OPTIONS,
            useValue: { dateFormat: "d MMM y" },
        },
    ],
};
