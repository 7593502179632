import { Directive, ElementRef, HostListener, inject, input, OnDestroy, Renderer2 } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { createPopper, Instance, Placement } from "@popperjs/core";
import { Subscription } from "rxjs";

@Directive({
    selector: "[mTooltip]",
    standalone: true,
})
export class TooltipDirective implements OnDestroy {
    /**
     * The text to display inside the tooltip.
     */
    mTooltip = input.required<string>();

    /**
     * The delay in milliseconds before showing the tooltip.
     * Default: 300
     */
    tooltipDelay = input<number>(300);

    /**
     * The position of the tooltip relative to the element.
     * Default: "top"
     */
    tooltipPosition = input<Placement>("top");

    /**
     * Whether the tooltip is disabled.
     * Default: false
     */
    tooltipDisabled = input<boolean>(false);
    private tooltipDisabled$ = toObservable(this.tooltipDisabled);
    private tooltipDisabledSubscription: Subscription;

    private arrowElement: HTMLElement | null = null;
    private popperInstance: Instance | null = null;
    private timeoutId: any;
    private tooltipElement: HTMLElement | null = null;

    private readonly el = inject(ElementRef);
    private readonly renderer = inject(Renderer2);

    @HostListener("mouseenter") onMouseEnter() {
        if (this.tooltipDisabled()) return;
        this.timeoutId = setTimeout(() => {
            this.showTooltip();
            this.subscribeToTooltipDisabled();
        }, this.tooltipDelay());
    }

    private onCloseEvent() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.tooltipDisabledSubscription?.unsubscribe();
        this.hideTooltip();
    }

    @HostListener("mouseleave") onMouseLeave() {
        this.onCloseEvent();
    }

    @HostListener("click") onClick() {
        this.onCloseEvent();
    }

    // If root elements gets destroyed, this gets destroyed as well
    ngOnDestroy() {
        this.onCloseEvent();
    }

    private showTooltip() {
        if (!this.mTooltip() || this.tooltipElement) return;

        this.createTooltipAndArrowElement();
        this.initPopper();
    }

    private createTooltipAndArrowElement() {
        // Tooltip
        this.tooltipElement = this.renderer.createElement("div");
        this.renderer.addClass(this.tooltipElement, "m-tooltip");
        this.renderer.setProperty(this.tooltipElement, "innerHTML", this.mTooltip());

        // Arrow
        this.arrowElement = this.renderer.createElement("div");
        this.renderer.addClass(this.arrowElement, "m-tooltip-arrow");
        this.renderer.setAttribute(this.arrowElement, "data-popper-arrow", "");
        this.renderer.appendChild(this.tooltipElement, this.arrowElement);

        this.renderer.appendChild(document.body, this.tooltipElement);
    }

    private initPopper() {
        this.popperInstance = createPopper(this.el.nativeElement, this.tooltipElement, {
            placement: this.tooltipPosition(),
            modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: [0, 8],
                    },
                },
                {
                    name: "flip",
                    options: {
                        fallbackPlacements: ["top", "bottom", "left", "right"],
                    },
                },
                {
                    name: "preventOverflow",
                    options: {
                        boundary: "viewport",
                    },
                },
                {
                    name: "arrow",
                    options: {
                        element: this.arrowElement,
                    },
                },
            ],
        });
    }

    private hideTooltip() {
        if (this.tooltipElement) {
            this.renderer.removeChild(document.body, this.tooltipElement);
            this.tooltipElement = null;
            this.arrowElement = null;
        }
        if (this.popperInstance) {
            this.popperInstance.destroy();
            this.popperInstance = null;
        }
    }

    private subscribeToTooltipDisabled() {
        this.tooltipDisabledSubscription = this.tooltipDisabled$.subscribe((value) => {
            if (value) {
                this.hideTooltip();
            }
        });
    }
}
