import { Component, ElementRef, HostListener, inject } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { createPopper } from "@popperjs/core";
import { LocalStorageService } from "@ramudden/services";

@Component({
    selector: "app-refresh-popup",
    templateUrl: "./refresh-popup.component.html",
    styleUrls: ["./refresh-popup.component.scss"],
    standalone: true,
    imports: [TranslateModule],
})
export class RefreshPopupComponent {
    isVisible = false;
    private popperInstance: any;
    private referenceElement: HTMLElement;

    private el = inject(ElementRef);
    private readonly localStorageService = inject(LocalStorageService);
    private readonly router = inject(Router);

    show(referenceElement: HTMLElement) {
        this.referenceElement = referenceElement;
        this.isVisible = true;
        setTimeout(() => {
            this.popperInstance = createPopper(
                referenceElement,
                this.el.nativeElement.querySelector(".popup-content"),
                {
                    placement: "bottom-start",
                },
            );
        });
    }

    hide() {
        this.isVisible = false;
        this.popperInstance?.destroy();
    }

    async reload() {
        this.hide();
        this.localStorageService.setItem("hasLatestVersion", "true");
        await this.router.navigate(["/assignments"]);
        location.reload();
    }

    @HostListener("document:click", ["$event"])
    onClickOutside(event: Event) {
        if (
            this.isVisible &&
            !this.el.nativeElement.contains(event.target) &&
            !this.referenceElement.contains(event.target as Node)
        ) {
            this.hide();
        }
    }
}
