<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<content-default>
    <ng-container body>
        <h2 class="mb-24">{{ "parkingBanPage.title" | translate }}</h2>

        <div class="flex flex-column gap-16">
            @for (parkingBan of parkingBans; track parkingBan.id) {
                <div
                    class="card--task"
                    [style.--status-color]="getStatusColor(parkingBan)"
                    (click)="editParkingBan(parkingBan)"
                >
                    <div class="card__header flex justify-content-between align-items-start">
                        <h2>{{ parkingBan.name }}</h2>

                        <div class="flex align-items-center gap-8">
                            <button
                                class="btn--icon"
                                (click)="deleteParkingBan(parkingBan); $event.stopPropagation()"
                            >
                                <m-svg iconName="delete" />
                            </button>
                        </div>
                    </div>

                    <div class="card__body">
                        <div
                            class="data-list"
                            style="--data-list-columns: 2"
                        >
                            @if (parkingBan.description) {
                                <dl>
                                    <dt>{{ "parkingBanPage.description" | translate }}</dt>
                                    <dd>{{ parkingBan.description }}</dd>
                                </dl>
                            }
                            @if (address(parkingBan)) {
                                <dl>
                                    <dt>{{ "parkingBanPage.address" | translate }}</dt>
                                    <dd>{{ address(parkingBan) }}</dd>
                                </dl>
                            }
                            @if (parkingBan.start) {
                                <dl>
                                    <dt>{{ "parkingBanPage.start" | translate }}</dt>
                                    <dd>{{ parkingBan.start | momentFormat: "D MMM y" }}</dd>
                                </dl>
                            }
                            @if (parkingBan.end) {
                                <dl>
                                    <dt>{{ "parkingBanPage.end" | translate }}</dt>
                                    <dd>{{ parkingBan.end | momentFormat: "D MMM y" }}</dd>
                                </dl>
                            }
                            @if (numberPlates(parkingBan)) {
                                <dl>
                                    <dt>{{ "parkingBanPage.licensePlates" | translate }}</dt>
                                    <dd>{{ numberPlates(parkingBan) }}</dd>
                                </dl>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    </ng-container>

    <ng-container floating-action>
        @if (editableAssignment) {
            <button
                class="btn--floating-action"
                (click)="createParkingBan()"
                title="{{ 'parkingBanPage.addParkingBan' | translate }}"
            >
                <m-svg iconName="plus" />
            </button>
        }
    </ng-container>
</content-default>
