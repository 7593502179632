<div
    class="dropdown"
    [id]="id()"
    (blur)="onblur()"
>
    <button
        class="dropdown__button"
        #dropdownButton
        [disabled]="isDisabled || (isLoading() | async)"
        (click)="toggleDropdown()"
        type="button"
    >
        <span class="dropdown__button__label">{{ fieldValue | translate }}</span>

        @if (isLoading() | async) {
            <m-loader-icon />
        } @else {
            <m-svg
                class="dropdown__button__icon"
                iconName="marker-down"
            />
        }
    </button>

    <div
        class="dropdown__content"
        #dropdownContent
        [class.is-visible]="isDropdownOpen"
        (clickOutside)="isDropdownOpen = false"
    >
        <div class="dropdown__list">
            @if (options().length >= 1) {
                @for (option of options(); track $index) {
                    <label
                        class="dropdown__list-item"
                        [for]="id() + '-' + option.label + '-' + $index"
                    >
                        <input
                            class="input--option"
                            [id]="id() + '-' + option.label + '-' + $index"
                            [type]="isMultiSelect() ? 'checkbox' : 'radio'"
                            [value]="option | json"
                            (change)="onSelectionChange($event)"
                            name="item"
                        />
                        @if (customTemplate) {
                            <ng-container *ngTemplateOutlet="customTemplate; context: { $implicit: option }" />
                        } @else {
                            {{ option.label | translate }}
                        }
                    </label>
                }
            } @else {
                <label
                    class="dropdown__list-item"
                    for="noInput"
                >
                    <input
                        class="input--option"
                        id="noInput"
                        [type]="isMultiSelect() ? 'checkbox' : 'radio'"
                    />
                    @if (customNoOptionsMessage()) {
                        {{ customNoOptionsMessage() | translate }}
                    } @else {
                        {{ "dropdown.noOptionsAvailable" | translate }}
                    }
                </label>
            }
        </div>
    </div>
</div>
