import { Directive, ElementRef, forwardRef, HostListener, inject, input, OnInit, Renderer2 } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import moment from "moment";

@Directive({
    selector: "[dateFormat]",
    standalone: true,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateFormatDirective),
            multi: true,
        },
    ],
})
export class DateFormatDirective implements OnInit, ControlValueAccessor {
    private onChange: (value: any) => void = () => {};
    private onTouched: () => void = () => {};

    format = input<string>("D MMM y");

    private readonly el = inject(ElementRef);
    private readonly renderer = inject(Renderer2);

    ngOnInit() {
        this.writeValue(this.el.nativeElement.value);
    }

    writeValue(value: any): void {
        if (value && moment(value).isValid()) {
            this.renderer.setProperty(this.el.nativeElement, "value", moment(value).format(this.format()));
        } else {
            this.renderer.setProperty(this.el.nativeElement, "value", "");
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.renderer.setProperty(this.el.nativeElement, "disabled", isDisabled);
    }

    @HostListener("blur") onBlur(): void {
        this.onChange(this.el.nativeElement.value);
        this.onTouched();
    }
}
