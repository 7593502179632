import { CommonModule, NgOptimizedImage } from "@angular/common";
import { Component, computed, inject, Input, input, output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AssignmentStatus } from "@ramudden/models/assignment";
import { IAttachment } from "@ramudden/models/attachment";
import { IPlannedAssignment } from "@ramudden/models/planned-event";
import { GalleriaService, IconName, IGalleriaImage, SvgComponent, ToastService } from "@ramudden/ui";
import { PdfViewerDialogComponent } from "../../../pages/assignment-container/assignment-details/dialogs/pdf-viewer/pdf-viewer-dialog.component";
import { MomentFormatPipe } from "../../../pipes/datetime.pipe";
import { TranslateEnumPipe } from "../../../pipes/translate-enum.pipe";

@Component({
    selector: "app-assignment-card",
    standalone: true,
    imports: [CommonModule, NgOptimizedImage, SvgComponent, TranslateEnumPipe, TranslateModule, MomentFormatPipe],
    templateUrl: "./assignment-card.component.html",
    styleUrl: "./assignment-card.component.scss",
})
export class AssignmentCardComponent {
    private readonly toastService = inject(ToastService);
    private readonly galleriaService = inject(GalleriaService);

    @Input() attachments: IAttachment[] = [];

    plannedAssignment = input.required<IPlannedAssignment>();
    pdfViewer = input.required<PdfViewerDialogComponent>();
    assignmentClicked = output<IPlannedAssignment>();

    assignment = computed(() => {
        return this.plannedAssignment().assignment;
    });

    name = computed(() => {
        if (this.assignment()?.parentAssignment)
            return `${this.assignment()?.parentAssignment.name} - ${this.assignment().name}`;
        else {
            return this.assignment()?.name;
        }
    });

    organization = computed(() => {
        return (
            this.assignment().project?.organization?.name ||
            this.assignment().parentAssignment?.project?.organization?.name
        );
    });

    description = computed(() => {
        if (this.assignment().parentAssignment) {
            return `${this.assignment().description} - ${this.assignment().parentAssignment.description}`;
        }
        return this.assignment().description;
    });

    address = computed(() => {
        const address = this.assignment().location.address;
        return `${address.line1 ?? ""}, ${address.zipCode ?? ""} ${address.city ?? ""}`;
    });

    isMachineWork = computed(() => {
        return this.assignment().isMachineWork;
    });

    protected getGoogleMapsLink(): string {
        const coordinate = this.assignment().location.coordinate;

        return `https://www.google.com/maps/dir/?api=1&destination=${coordinate.latitude},${coordinate.longitude}`;
    }

    getStatusColor(): string {
        const statusId = this.assignment()?.currentStatus?.statusId;

        if (!statusId) {
            return "var(--color-default)";
        }

        switch (statusId) {
            case AssignmentStatus.Cancelled:
                return "var(--color-not-started)";
            case AssignmentStatus.Finished:
                return "var(--color-finished)";
            case AssignmentStatus.InProgress:
                return "var(--color-in-progress)";
            default:
                return "var(--color-default)";
        }
    }

    isImage(fileName: string): boolean {
        return (
            fileName.toLowerCase().endsWith(".jpg") ||
            fileName.toLowerCase().endsWith(".jpeg") ||
            fileName.toLowerCase().endsWith(".png") ||
            fileName.toLowerCase().endsWith(".webp")
        );
    }

    getIconName(attachment: IAttachment): IconName {
        if (attachment.url.toLowerCase().endsWith(".pdf")) return "file-pdf-light";
        else if (this.isImage(attachment.url)) return "file-image";
        else return "file";
    }

    openPreview(attachment: IAttachment, event: Event) {
        if (attachment.url.toLowerCase().endsWith(".pdf"))
            this.pdfViewer().openModal(attachment.name, attachment.url, event);
        else if (this.isImage(attachment.url)) this.showGalleria(attachment);
        else this.toastService.showInfo("documents.unableToView");
        event.stopPropagation();
    }

    showGalleria(attachment: IAttachment) {
        const images = this.attachments
            .filter((x) => !x.url.endsWith(".pdf"))
            .map((attachment) => {
                return {
                    source: attachment.url,
                    previewImageSrc: attachment.url,
                    title: attachment.name,
                    alt: attachment.name,
                } as IGalleriaImage;
            });
        const imageIndex = images.findIndex((i) => i.source === attachment.url);
        this.galleriaService.open(images, imageIndex);
    }
}
