import { Injectable } from "@angular/core";
import { IParkingBan, ParkingBanCreator, ParkingBanUpdater } from "@ramudden/models/parking-ban";
import { SearchParameters } from "@ramudden/models/search";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class ParkingBanApi extends ApiService<IParkingBan, ParkingBanCreator, ParkingBanUpdater> {
    override getRoute(): string {
        return "ParkingBans";
    }

    move$(parkingBanId: number, start: Date) {
        const searchParameters = new SearchParameters();
        searchParameters.queryParams = { start: start.valueOf() };
        const url = `${super.getUrl()}/${parkingBanId}/Move`;
        return this.http.post<IParkingBan>(url, {}, { params: searchParameters.queryParams });
    }
}
