import { Component, inject, Input, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ICoordinate, ILocation } from "@ramudden/models/location";
import { IParkingBan } from "@ramudden/models/parking-ban";
import {
    GalleriaService,
    IGalleriaImage,
    PhotoInputComponent,
    PhotoInputFileLoadEnd,
    SvgComponent,
    ToastService,
} from "@ramudden/ui";
import { DelayedInputDirective } from "../../../../../directive/delayed-input.directive";
import { AssignmentService } from "../../../../../services/assignment.service";
import { LoaderService } from "../../../../../services/loader.service";
import { LocationService } from "../../../../../services/location.service";
import { ParkingBanService } from "../../../../../services/parking-ban.service";
import { MapsDialogComponent } from "./../../../../assignment-container/assignment-parking-bans/edit-parking-ban/dialogs/maps/maps-dialog.component";

@Component({
    selector: "cpb-step-3",
    standalone: true,
    templateUrl: "cpb-step-3.component.html",
    styleUrls: ["cpb-step-3.component.scss"],
    imports: [
        ReactiveFormsModule,
        TranslateModule,
        SvgComponent,
        DelayedInputDirective,
        MapsDialogComponent,
        PhotoInputComponent,
    ],
})
export class CpbStep3Component implements OnInit {
    @ViewChild(MapsDialogComponent) mapDialog: MapsDialogComponent;

    @Input() parkingBan: IParkingBan;

    exceptionsForm: FormGroup;

    private readonly formBuilder = inject(FormBuilder);
    private readonly loaderService = inject(LoaderService);
    private readonly locationService = inject(LocationService);
    protected readonly assignmentService = inject(AssignmentService);
    protected readonly parkingBanService = inject(ParkingBanService);
    protected readonly toastService = inject(ToastService);
    private readonly galleriaService = inject(GalleriaService);

    async ngOnInit() {
        if (this.parkingBan) {
            this.parkingBan = await this.parkingBanService.getParkingBan(this.parkingBan.id);
        }
        this.createFormExceptions();
    }

    get exceptions() {
        return this.exceptionsForm.get("exceptions") as FormArray;
    }

    createFormExceptions() {
        const exceptionsForm = this.formBuilder.group({
            exceptions: this.formBuilder.array([]),
        });

        if (this.parkingBan?.exceptions) {
            this.parkingBan?.exceptions.forEach((exception) =>
                exception?.photos.forEach((photo) =>
                    (exceptionsForm.get("exceptions") as FormArray).push(
                        this.formBuilder.group({
                            exceptionId: new FormControl(exception.id),
                            exceptionImage: new FormControl(photo.url),
                            exceptionLicensePlate: new FormControl(exception.licensePlate),
                            location: new FormControl(exception.location),
                        }),
                    ),
                ),
            );
        }

        this.exceptionsForm = exceptionsForm;
    }

    async onExceptionDelete(exceptionId: number, index: number) {
        await this.parkingBanService.deleteParkingBanException(exceptionId);
        this.exceptions.removeAt(index);
        this.parkingBan.exceptions = this.parkingBan.exceptions.filter((exception) => exception.id !== exceptionId);
    }

    async onLicensePlateChange(id: number, event: any) {
        const exceptionLicensePlate = event.target.value;
        const currentException = this.parkingBan.exceptions.find((exception) => exception.id === id);

        currentException.licensePlate = exceptionLicensePlate;
        await this.parkingBanService.updateParkingBanException(currentException, this.parkingBan);
    }

    onFileLoadEnd(event: PhotoInputFileLoadEnd) {
        let coordinate: ICoordinate;
        if (event.metadata && event.metadata?.latitude && event.metadata?.longitude) {
            coordinate = {
                latitude: event.metadata?.latitude,
                longitude: event.metadata?.longitude,
            };
        }

        this.uploadExceptionPhoto(event.base64image, coordinate);
    }

    async uploadExceptionPhoto(dataUrl: string, coordinate?: ICoordinate) {
        const organizationId =
            this.assignmentService.selectedPlannedAssignment.assignment?.project?.organizationId ??
            this.assignmentService.selectedPlannedAssignment.assignment?.parentAssignment?.project?.organizationId;

        const location = await this.getLocation(coordinate);

        this.loaderService.show();
        const parkingBanException = await this.parkingBanService
            .createParkingBanExceptionWithAttachment(this.parkingBan, organizationId, dataUrl, location)
            .finally(() => this.loaderService.hide());
        if (!this.parkingBan.exceptions) this.parkingBan.exceptions = [];
        this.parkingBan = await this.parkingBanService.getParkingBan(this.parkingBan.id);

        const exceptionsForm = this.formBuilder.group({
            exceptionId: new FormControl(parkingBanException.id),
            exceptionImage: new FormControl(dataUrl),
            exceptionLicensePlate: new FormControl(parkingBanException.licensePlate),
            location: this.parkingBan.location,
        });

        this.exceptions.push(exceptionsForm);
    }

    async getLocation(coordinate?: ICoordinate): Promise<ILocation> {
        if (!coordinate) {
            this.loaderService.show();
            coordinate = await this.locationService
                .getCurrentCoordinates()
                .catch(() => {
                    this.toastService.showWarning("location.usedDefault");
                    return this.parkingBan.location.coordinate;
                })
                .finally(() => this.loaderService.hide());
        }
        this.loaderService.show();
        const currentAddress = await this.locationService
            .getAddressFromCoordinates(coordinate)
            .finally(() => this.loaderService.hide());

        return {
            coordinate: coordinate,
            address: currentAddress.address,
        } as ILocation;
    }

    getAddress(location: ILocation) {
        if (!location?.address) return;
        return this.locationService.convertToAddressString(location?.address);
    }

    openDialog(index: number) {
        const location = this.exceptions.at(index).get("location").value as ILocation;

        this.mapDialog.coordinates = location.coordinate;
        if (this.mapDialog?.marker) {
            this.mapDialog.marker.position = {
                lat: location.coordinate.latitude,
                lng: location.coordinate.longitude,
            };
        }
        this.mapDialog.address = this.getAddress(location);
        this.mapDialog.location = location;
        this.mapDialog.open();

        const subscription = this.mapDialog.saveModal.subscribe((newLocation: ILocation) => {
            this.exceptions.at(index).get("location").patchValue(newLocation);
            subscription.unsubscribe();
        });
    }

    openGalleria(index: number) {
        const images = [] as IGalleriaImage[];
        this.parkingBan.exceptions.forEach((exception) => {
            exception.photos.forEach((photo) => {
                images.push({
                    source: photo.url,
                    previewImageSrc: photo.url,
                    title: exception.licensePlate,
                    alt: exception.licensePlate,
                } as IGalleriaImage);
            });
        });

        this.galleriaService.open(images, index);
    }
}
