import { Duration } from "moment";
import { IAlarm } from "./alarm";
import { IAttachment } from "./attachment";
import { ICameraConfiguration } from "./camera";
import { IDekimoGuiConfiguration } from "./dekimo-gui-configuration";
import { IDekimoTrackerConfiguration } from "./dekimo-tracker-configuration";
import { IAvsConfiguration } from "./device-avs";
import { IBerlexDeviceConfiguration } from "./device-berlex";
import { IDeviceDepotNavigator } from "./device-depot";
import { IDekimoDisplayConfiguration, IDeviceDisplayConfiguration } from "./device-display-configuration";
import { IDeviceLink } from "./device-link";
import { ILisaConfiguration } from "./device-lisa";
import { INtcipConfiguration } from "./device-ntcip";
import { IRelayConfiguration } from "./device-relay";
import { IDeviceStatus } from "./device-status";
import { ILocation } from "./location";
import { IMeasuringPointNavigator } from "./measuring-point";
import { IRelease, ReleaseCreator } from "./release";
import { IServiceModelBase } from "./servicemodelbase";
import { ITmsRadarConfiguration } from "./tms-radar-configuration";
import { IVehicleConfiguration } from "./vehicle-configuration";
import { IWorkerBase } from "./worker";
import { IZigbeeConfiguration } from "./zigbee-configuration";

export enum DeviceType {
    Anpr = "anpr",
    AvsFleetTracker = "avsFleetTracker",
    AvsRetroTrafficLight = "avsRetroTrafficLight",
    AvsTrafficLight = "avsTrafficLight",
    AvsVms = "avsVms",
    Berlex = "berlex",
    BikeCounter = "bikeCounter",
    Camera = "camera",
    ControlPanel = "controlPanel",
    DekimoController = "dekimoController",
    DekimoTracker = "dekimoTracker",
    GenericLinuxController = "genericLinuxController",
    Lisa = "lisa",
    NtcipVms = "ntcipVms",
    PulseMeasuringConnected = "pulseMeasuringConnected",
    PulseMeasuringFixed = "pulseMeasuringFixed",
    PulseMeasuringNotConnected = "pulseMeasuringNotConnected",
    Relay = "relay",
    SigncoTablet = "signcoTablet",
    SmartSolarTracker = "smartSolarTracker",
    Tat140Tracker = "tat140Tracker",
    TrafficFleet = "trafficFleet",
    TssTrailerTracker = "tssTrailerTracker",
    Vehicle = "vehicle",
    WJet = "wJet",
    FlirCamera = "flirCamera",
}

export interface IDeviceNavigator extends IServiceModelBase {
    code: string;
    typeId: DeviceType;
    ownerId: number;
}

export interface IDeviceSummary extends IDeviceNavigator {
    currentLocationId: number;
    description: string;
    manufacturer: string;
    measuringPoints: IMeasuringPointNavigator[];
    alarms: IAlarm[];
    alarmsHigh: number[];
    alarmsLow: number[];
    cameraConfigurationImage: string;
    depot: IDeviceDepotNavigator;
}

export interface ITrafficLightStatus {
    state: TrafficLightState;
    head: TrafficLightHead;
}

export enum TrafficLightHead {
    Unknown = "unknown",
    K1 = "k1",
    K2 = "k2",
    K3 = "k3",
    K4 = "k4",
}

export enum TrafficLightState {
    Unknown = "unknown",
    Green = "green",
    GreenBlinking = "greenBlinking",
    Orange = "orange",
    OrangeBlinking = "orangeblinking",
    Red = "red",
    RedBlinking = "redBlinking",
    RedOrange = "redOrange",
    Off = "off",
    Dark = "dark",
    RedRed = "redRed",
}

export interface IAnprConfiguration {
    dataUrl: string;
    updateInterval: Duration;
}

export interface IDeviceFeatures {
    zigbee: boolean;
    gpio: boolean;
    otaUpdate: boolean;
    realtimeAnalyzer: boolean;
    vms: boolean;
    qLiteDisplay: boolean;
    dekimoDisplay: boolean;
    tmsRadar: boolean;
    simulateData: boolean;
}

export interface IRealtimeAnalyzerConfiguration {
    maxWindowTime: Duration;
    minTimeBetweenWheelies: Duration;
    simulateVehicleOnEveryPulse: boolean;
}

export interface IDeviceIgnoredRule {
    ruleId: number;
    until: Date;
    comment: string;
}

export interface IDeviceReleaseConfiguration {
    releaseChannelId: string;
    packageId: string;
    specificReleaseId: number;
    specificRelease: ReleaseCreator;
}

export interface IDevice extends IServiceModelBase {
    id: number;
    code: string;
    description: string;
    manufacturer: string;
    typeId: DeviceType;
    features: IDeviceFeatures;
    ownerId: number;
    currentLink: IDeviceLink;
    currentStatus: IDeviceStatus;
    lastSync: Date;
    currentLocation: ILocation;
    isOffline: boolean;
    useRealtimeUpdates: boolean;
    activeReleases: IRelease[];
    batteryDischargeTableId: number;
    imei: string;
    ipAddress: string;
    qrCode: string;

    ignoredRules: IDeviceIgnoredRule[];
    alarms: IAlarm[];
    alarmLevel: string[];

    releaseConfigurations: IDeviceReleaseConfiguration[];
    anprConfiguration: IAnprConfiguration;
    avsConfiguration: IAvsConfiguration;
    lisaConfiguration: ILisaConfiguration;
    displayConfiguration: IDeviceDisplayConfiguration;
    dekimoDisplayConfiguration: IDekimoDisplayConfiguration;
    cameraConfiguration: ICameraConfiguration;
    relayConfiguration: IRelayConfiguration;
    zigbeeConfiguration: IZigbeeConfiguration;
    tmsRadarConfiguration: ITmsRadarConfiguration;
    realtimeAnalyzerConfiguration: IRealtimeAnalyzerConfiguration;
    dekimoGuiConfiguration: IDekimoGuiConfiguration;
    hardwareConfiguration: string;
    dekimoTrackerConfiguration: IDekimoTrackerConfiguration;
    vehicleConfiguration: IVehicleConfiguration;
    ntcipConfiguration: INtcipConfiguration;
    berlexConfiguration: IBerlexDeviceConfiguration;

    workerDriver?: IWorkerBase;
    attachment?: IAttachment;
}

export class DeviceCreator {
    batteryDischargeTableId: number;
    code: string;
    dekimoGuiConfiguration: IDekimoGuiConfiguration;
    dekimoTrackerConfiguration: IDekimoTrackerConfiguration;
    description: string;
    features: IDeviceFeatures;
    hardwareConfiguration: string;
    ignoredRules: IDeviceIgnoredRule[];
    imei: string;
    ipAddress: string;
    isOffline: boolean;
    manufacturer: string;
    ownerId?: number;
    qrCode: string;
    releaseConfigurations: IDeviceReleaseConfiguration[];
    typeId: DeviceType;
    useRealtimeUpdates: boolean;
    workerDriverId?: number;

    anprConfiguration: IAnprConfiguration;
    avsConfiguration: IAvsConfiguration;
    berlexConfiguration: IBerlexDeviceConfiguration;
    cameraConfiguration: ICameraConfiguration;
    lisaConfiguration: ILisaConfiguration;
    ntcipConfiguration: INtcipConfiguration;
}

export class SpecificReleaseCreator {
    release: ReleaseCreator;
    releaseId: number;
}

export class DeviceUpdater extends DeviceCreator {
    constructor(existingDevice: IDevice) {
        super();

        this.id = existingDevice.id;
        this.code = existingDevice.code;
        this.description = existingDevice.description;
        this.manufacturer = existingDevice.manufacturer;
        this.typeId = existingDevice.typeId;
        this.features = existingDevice.features;
        this.ownerId = existingDevice.ownerId;
        this.isOffline = existingDevice.isOffline;
        this.imei = existingDevice.imei;
        this.batteryDischargeTableId = existingDevice.batteryDischargeTableId;

        this.ignoredRules = structuredClone(existingDevice.ignoredRules || []);

        this.releaseConfigurations = structuredClone(existingDevice.releaseConfigurations || []);

        this.anprConfiguration = existingDevice.anprConfiguration;
        this.avsConfiguration = existingDevice.avsConfiguration;
        this.berlexConfiguration = existingDevice.berlexConfiguration;
        this.cameraConfiguration = existingDevice.cameraConfiguration;
        this.dekimoDisplayConfiguration = existingDevice.dekimoDisplayConfiguration;
        this.dekimoGuiConfiguration = existingDevice.dekimoGuiConfiguration;
        this.displayConfiguration = existingDevice.displayConfiguration;
        this.hardwareConfiguration = existingDevice.hardwareConfiguration;
        this.lisaConfiguration = existingDevice.lisaConfiguration;
        this.ntcipConfiguration = existingDevice.ntcipConfiguration;
        this.realtimeAnalyzerConfiguration = existingDevice.realtimeAnalyzerConfiguration;
        this.relayConfiguration = existingDevice.relayConfiguration;
        this.tmsRadarConfiguration = existingDevice.tmsRadarConfiguration;
        this.useRealtimeUpdates = existingDevice.useRealtimeUpdates;
        this.vehicleConfiguration = existingDevice.vehicleConfiguration;
        this.zigbeeConfiguration = existingDevice.zigbeeConfiguration;

        this.workerDriverId = existingDevice.workerDriver?.id;
    }

    dekimoDisplayConfiguration: IDekimoDisplayConfiguration;
    displayConfiguration: IDeviceDisplayConfiguration;
    id: number;
    realtimeAnalyzerConfiguration: IRealtimeAnalyzerConfiguration;
    relayConfiguration: IRelayConfiguration;
    specificRelease: SpecificReleaseCreator;
    tmsRadarConfiguration: ITmsRadarConfiguration;
    vehicleConfiguration: IVehicleConfiguration;
    zigbeeConfiguration: IZigbeeConfiguration;
}

export interface IBatteryHistory {
    timestamp: Date;
    batteryVoltage?: number;
    chargePercentage?: number;
}

export interface IDeviceLastLocationsCompared {
    gpsLocation?: ILocation;
    gpsAddress?: string;
    timestampGps?: Date;
    linkedLocation?: ILocation;
    distanceInMeter?: number;
    cellTowerLocation?: ILocation;
    error: boolean;
}
