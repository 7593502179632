import { Component, inject, Input, OnDestroy, OnInit, output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { LocationApi } from "@ramudden/data-access/resource/location.api";
import { ILocation } from "@ramudden/models/location";
import { PhotoInputComponent, PhotoInputFileLoadEnd, SvgComponent, ToastService } from "@ramudden/ui";
import moment from "moment";
import { firstValueFrom } from "rxjs";
import { LoaderIconComponent } from "../../../../../components/loader-icon/loader-icon.component";
import { AssignmentService } from "../../../../../services/assignment.service";
import { LoaderService } from "../../../../../services/loader.service";
import { LocationService } from "../../../../../services/location.service";
import { MapsDialogComponent } from "../../edit-parking-ban/dialogs/maps/maps-dialog.component";

@Component({
    selector: "cpb-step-1",
    standalone: true,
    templateUrl: "cpb-step-1.component.html",
    imports: [
        ReactiveFormsModule,
        TranslateModule,
        SvgComponent,
        LoaderIconComponent,
        MapsDialogComponent,
        PhotoInputComponent,
    ],
})
export class CpbStep1Component implements OnInit, OnDestroy {
    @ViewChild(MapsDialogComponent) mapDialog: MapsDialogComponent;

    private readonly formBuilder = inject(FormBuilder);
    private readonly loaderService = inject(LoaderService);
    private readonly locationApi = inject(LocationApi);
    protected readonly locationService = inject(LocationService);
    private readonly toastService = inject(ToastService);
    private readonly assignmentService = inject(AssignmentService);

    @Input() parkingBanForm: FormGroup;
    destroy = output<FormGroup>();

    stepValid = output<boolean>();

    async ngOnInit() {
        this.createFormParkingBan();

        const coordinate = await this.locationService.getCurrentCoordinates().catch(() => {
            this.toastService.showWarning("location.usedDefault");
            return this.assignmentService.selectedPlannedAssignment.assignment.location.coordinate;
        });
        const addressWithTimeZone = await firstValueFrom(
            this.locationApi.getAddressFromCoordinates$(coordinate),
        ).finally(() => this.loaderService.hide());
        const location = {
            address: addressWithTimeZone.address,
            coordinate: coordinate,
        };

        this.parkingBanForm.patchValue({
            location: location,
        });
        this.onParkingBanFormChange();
    }

    ngOnDestroy() {
        this.destroy.emit(this.parkingBanForm);
    }

    //region Get

    get parkingBanAttachments() {
        return this.parkingBanForm.get("attachments") as FormArray;
    }

    get parkingBanLocation(): ILocation {
        return this.parkingBanForm.get("location").value;
    }

    get parkingBanAddress(): string {
        const address = this.parkingBanLocation?.address;
        if (address) {
            return this.locationService.convertToAddressString(address, false);
        }
        return "";
    }

    //endregion

    createFormParkingBan() {
        if (this.parkingBanForm) return;
        const name = moment().format("YYYYMMDD_HHmmss");

        this.parkingBanForm = this.formBuilder.group({
            name: new FormControl(name),
            description: new FormControl(""),
            start: new FormControl(""),
            end: new FormControl(""),
            location: new FormControl(""),
            attachments: this.formBuilder.array([]),
        });
    }

    onParkingBanFormChange() {
        this.stepValid.emit(this.parkingBanForm.valid);
    }

    //region Snapshot

    deleteAttachmentSnapshot(index: number) {
        this.parkingBanAttachments.removeAt(index);
    }

    //endregion

    //endregion

    onFileLoadEnd(event: PhotoInputFileLoadEnd) {
        const attachmentsForm = this.formBuilder.group({
            attachmentImage: new FormControl(event.base64image as string),
            attachmentDescription: new FormControl(""),
        });

        this.parkingBanAttachments.push(attachmentsForm);
    }

    openMapsDialog() {
        this.mapDialog.coordinates = this.parkingBanLocation.coordinate;
        this.mapDialog.open();

        const subscription = this.mapDialog.saveModal.subscribe((newLocation: ILocation) => {
            this.parkingBanForm.get("location").patchValue(newLocation);
            subscription.unsubscribe();
        });
    }
}
