import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { fromEvent, Subject, timer } from "rxjs";
import { debounce, distinctUntilChanged, takeUntil } from "rxjs/operators";

//https://www.leonelngande.com/creating-a-delayed-input-directive-in-angular/

@Directive({
    selector: "[appDelayedInput]",
    standalone: true,
})
export class DelayedInputDirective implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    @Input() delayTime = 500;
    @Output() delayedInput = new EventEmitter<Event>();

    constructor(private _elementRef: ElementRef<HTMLInputElement>) {}

    ngOnInit() {
        fromEvent(this._elementRef.nativeElement, "input")
            .pipe(
                debounce(() => timer(this.delayTime)),
                distinctUntilChanged(null, (event: Event) => (event.target as HTMLInputElement).value),
                takeUntil(this.destroy$),
            )
            .subscribe((e) => this.delayedInput.emit(e));
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
