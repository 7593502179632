import { inject, Injectable } from "@angular/core";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { TranslateService } from "@ngx-translate/core";
import { filter } from "rxjs/operators";
import { LocalStorageService } from "./storage.service";

@Injectable({ providedIn: "root" })
export class PromptUpdateService {
    private readonly swUpdate = inject(SwUpdate);
    private readonly translateService = inject(TranslateService);
    private readonly localStorageService = inject(LocalStorageService);

    subscribeToUpdates() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates
                .pipe(filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"))
                .subscribe(() => {
                    this.localStorageService.setItem("hasLatestVersion", "true");
                    console.info(`[${new Date().toISOString()}] A new version is available.`);

                    const onOk = () => {
                        this.localStorageService.setItem("hasLatestVersion", "true");
                        document.location.reload();
                    };
                    const onCancel = () => {
                        console.warn(`[${new Date().toISOString()}] User declined new version.`);
                        this.localStorageService.setItem("hasLatestVersion", "false");
                    };

                    const newVersion = this.translateService.instant("newVersion");
                    if (confirm(newVersion)) {
                        onOk();
                    } else {
                        onCancel();
                    }
                });
        }
    }
}
