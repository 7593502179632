import { DatePipe } from "@angular/common";
import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { LocationApi } from "@ramudden/data-access/resource/location.api";
import { IAssignment } from "@ramudden/models/assignment";
import { ICoordinate, ILocation, LocationUpdater } from "@ramudden/models/location";
import { IParkingBan } from "@ramudden/models/parking-ban";
import { IPlannedAssignment } from "@ramudden/models/planned-event";
import { ISignScan } from "@ramudden/models/sign-scan";
import {
    GalleriaService,
    IGalleriaImage,
    ModalComponent,
    PhotoInputComponent,
    PhotoInputFileLoadEnd,
    SvgComponent,
    ToastService,
} from "@ramudden/ui";
import moment from "moment";
import { firstValueFrom } from "rxjs";
import { QrScannerComponent } from "../../../../components/qr-scanner/qr-scanner.component";
import { DelayedInputDirective } from "../../../../directive/delayed-input.directive";
import { DefaultComponent } from "../../../../layout/content/default/default.component";
import { AssignmentService } from "../../../../services/assignment.service";
import { LocationService } from "../../../../services/location.service";
import { ParkingBanService } from "../../../../services/parking-ban.service";
import { SignScanService } from "../../../../services/sign-scan.service";
import { TitleService } from "../../../../services/title.service";
import { MapsDialogComponent } from "./dialogs/maps/maps-dialog.component";

@Component({
    selector: "app-edit-parking-ban",
    standalone: true,
    imports: [
        DefaultComponent,
        ReactiveFormsModule,
        SvgComponent,
        MapsDialogComponent,
        DatePipe,
        QrScannerComponent,
        TranslateModule,
        ModalComponent,
        FormsModule,
        DelayedInputDirective,
        PhotoInputComponent,
    ],
    templateUrl: "./edit-parking-ban.component.html",
    styleUrls: ["./edit-parking-ban.component.scss"],
})
export class EditParkingBanComponent implements OnInit {
    protected readonly moment = moment;

    @ViewChild(MapsDialogComponent) mapsDialog!: MapsDialogComponent;

    private readonly assignmentService = inject(AssignmentService);
    private readonly formBuilder = inject(FormBuilder);
    private readonly galleriaService = inject(GalleriaService);
    private readonly locationApi = inject(LocationApi);
    private readonly locationService = inject(LocationService);
    private readonly parkingBanService = inject(ParkingBanService);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly signScanService = inject(SignScanService);
    private readonly titleService = inject(TitleService);
    private readonly toastService = inject(ToastService);
    private readonly translateService = inject(TranslateService);

    plannedAssignment: IPlannedAssignment;
    assignment: IAssignment;
    parkingBan: IParkingBan;
    parkingBanForm: FormGroup;
    moveDate: string = null;

    //region Implements
    async ngOnInit() {
        const plannedAssignmentId = this.route.snapshot.params["plannedAssignmentId"];
        const parkingBanId = this.route.snapshot.params["parkingBanId"];

        await this.fetchPlannedAssignment(plannedAssignmentId);
        await this.fetchParkingBan(plannedAssignmentId, parkingBanId);
        this.titleService.title = this.translateService.instant("parkingBanPage.viewParkingBan");
        this.titleService.goBack.set(["/assignment", plannedAssignmentId, "parking-bans"]);

        this.createForm();
    }

    //endregion

    //region Getters
    get attachments(): FormArray<FormGroup> {
        return this.parkingBanForm.get("attachmentsForm") as FormArray;
    }

    get exceptions(): FormArray<FormGroup> {
        return this.parkingBanForm.get("exceptionsForm") as FormArray;
    }

    get signScans(): FormArray<FormGroup> {
        return this.parkingBanForm.get("signScansForm") as FormArray;
    }

    get location(): ILocation {
        return this.parkingBanForm.get("generalForm").get("location").value;
    }

    //endregion

    //region Form
    createForm() {
        const generalForm = this.formBuilder.group({
            description: new FormControl(this.parkingBan?.description),
            end: new FormControl(this.parkingBan?.end?.toISOString().slice(0, 10)),
            location: new FormControl(this.parkingBan?.location),
            start: new FormControl(this.parkingBan?.start?.toISOString().slice(0, 10)),
        });

        const attachmentsForm = this.formBuilder.array([]);
        const exceptionsForm = this.formBuilder.array([]);
        const signScansForm = this.formBuilder.array([]);

        this.parkingBanForm = this.formBuilder.group({
            exceptionsForm,
            generalForm,
            isCompleted: new FormControl(this.parkingBan.isCompleted),
            attachmentsForm,
            signScansForm,
        });

        if (this.parkingBan?.photos) {
            this.parkingBan.photos.forEach((photo) => {
                this.attachments.push(
                    this.formBuilder.group({
                        attachmentId: new FormControl(photo.id),
                        attachmentImage: new FormControl(photo.url),
                        attachmentDescription: new FormControl(photo.description),
                    }),
                );
            });
        }

        if (this.parkingBan?.exceptions) {
            this.parkingBan?.exceptions.forEach((exception) =>
                exception?.photos.forEach((photo) =>
                    this.exceptions.push(
                        this.formBuilder.group({
                            exceptionId: new FormControl(exception.id),
                            exceptionImage: new FormControl(photo.url),
                            exceptionLicensePlate: new FormControl(exception.licensePlate),
                        }),
                    ),
                ),
            );
        }

        if (this.parkingBan?.signScans) {
            this.parkingBan?.signScans.forEach((signScan) =>
                this.signScans.push(
                    this.formBuilder.group({
                        signScanId: new FormControl(signScan.id),
                        signScanCode: new FormControl(signScan.signCode),
                        signScan: new FormControl(signScan),
                        isCheckedOut: new FormControl(this.signScanService.isCheckedOut(signScan)),
                        isLost: new FormControl(this.signScanService.isLost(signScan)),
                    }),
                ),
            );
        }
    }

    async onLicensePlateChange(id: number, event: any) {
        const exceptionLicensePlate = event.target.value;
        const currentException = this.parkingBan.exceptions.find((exception) => exception.id === id);
        if (currentException.licensePlate !== exceptionLicensePlate) {
            currentException.licensePlate = exceptionLicensePlate;
            await this.parkingBanService.updateParkingBanException(currentException, this.parkingBan);
        }
    }

    //endregion

    //region Map
    openMapsDialog(event: Event) {
        this.mapsDialog.open(event);
    }

    async onSaveModal(location: ILocation) {
        this.parkingBanForm.get("generalForm").get("location").patchValue(location);
        const locationUpdater = new LocationUpdater({
            ...this.parkingBan.location,
            coordinate: location.coordinate,
            address: location.address,
        });
        await firstValueFrom(this.locationApi.update$(locationUpdater));
    }

    //endregion

    //region QR Code
    async onQrCodeScanned(event: string) {
        const organizationId =
            this.assignment.project?.parentProject?.organizationId ?? this.assignment.project?.organizationId;

        await this.parkingBanService.createParkingBanSignScan(
            this.parkingBan,
            organizationId,
            this.location.coordinate,
            event,
        );
        await this.fetchParkingBan(this.assignment.id, this.parkingBan.id);
    }

    async onQrCodeDelete(signScanId: number) {
        await this.parkingBanService.deleteParkingBanSignScan(signScanId);
        await this.fetchParkingBan(this.assignment.id, this.parkingBan.id);
    }

    navigateToSignScan(signScanId: number) {
        const plannedAssignmentId = this.route.snapshot.params["plannedAssignmentId"];
        const parkingBanId = this.route.snapshot.params["parkingBanId"];

        this.router.navigate([
            "/assignment",
            plannedAssignmentId,
            "parking-ban",
            parkingBanId,
            "sign-scan",
            signScanId,
        ]);
    }

    async markAsCheckedOut(signScan: ISignScan) {
        const plannedAssignmentId = this.route.snapshot.params["plannedAssignmentId"];
        const parkingBanId = this.route.snapshot.params["parkingBanId"];

        await this.signScanService.markAsCheckedOut(signScan, parkingBanId);
        await this.fetchParkingBan(plannedAssignmentId, parkingBanId);

        const index = this.signScans.controls.findIndex((control) => control.get("signScanId").value === signScan.id);
        this.signScans.controls[index].get("isLost").enable();
        this.signScans.controls[index].get("isCheckedOut").disable();
    }

    async markAsLost(signScan: ISignScan) {
        const plannedAssignmentId = this.route.snapshot.params["plannedAssignmentId"];
        const parkingBanId = this.route.snapshot.params["parkingBanId"];

        await this.signScanService.markAsLost(signScan, parkingBanId);
        await this.fetchParkingBan(plannedAssignmentId, parkingBanId);

        const index = this.signScans.controls.findIndex((control) => control.get("signScanId").value === signScan.id);
        this.signScans.controls[index].get("isLost").disable();
        this.signScans.controls[index].get("isCheckedOut").enable();
    }

    //endregion

    //region Exception

    async onExceptionDelete(exceptionId: number) {
        await this.parkingBanService.deleteParkingBanException(exceptionId);
        await this.fetchParkingBan(this.assignment.id, this.parkingBan.id);

        this.exceptions.removeAt(
            this.exceptions.controls.findIndex((control) => control.get("exceptionId").value === exceptionId),
        );
    }

    //endregion

    //region Attachment

    async onParkingBanAttachmentDelete(attachmentId: number) {
        await this.parkingBanService.deleteParkingBanAttachment(attachmentId);
        await this.fetchParkingBan(this.assignment.id, this.parkingBan.id);

        this.attachments.removeAt(
            this.attachments.controls.findIndex((control) => control.get("attachmentId").value === attachmentId),
        );
    }

    //endregion

    //region Api calls
    async fetchPlannedAssignment(plannedAssignmentId: number) {
        if (!plannedAssignmentId) {
            this.router.navigate(["/assignments"]);
        }

        this.plannedAssignment = await this.assignmentService.fetchPlannedAssignment(plannedAssignmentId);
        this.assignment = this.plannedAssignment.assignment;
    }

    async fetchParkingBan(assignmentId: number, parkingBanId: number) {
        if (!parkingBanId) {
            this.router.navigate(["/assignment", assignmentId, "parking-bans"]);
        }

        this.parkingBan = await this.parkingBanService.getParkingBan(parkingBanId);

        if (this.parkingBanForm) {
            if (this.parkingBan?.signScans) {
                this.signScans.clear();
                this.parkingBan.signScans.forEach((signScan) => {
                    const signScanForm = this.formBuilder.group({
                        signScanId: new FormControl(signScan.id),
                        signScanCode: new FormControl(signScan.signCode),
                        signScan: new FormControl(signScan),
                        isCheckedOut: new FormControl(this.signScanService.isCheckedOut(signScan)),
                        isLost: new FormControl(this.signScanService.isLost(signScan)),
                    });

                    if (signScanForm.get("isCheckedOut")) signScanForm.get("isCheckedOut").disable();
                    else signScanForm.get("isCheckedOut").enable();

                    if (signScanForm.get("isLost")) signScanForm.get("isLost").disable();
                    else signScanForm.get("isLost").enable();

                    if (signScanForm.get("isLost").disabled && signScanForm.get("isCheckedOut").disabled) {
                        signScanForm.get("isLost").enable();
                        signScanForm.get("isCheckedOut").enable();
                    }

                    this.signScans.push(signScanForm);
                });
            }
        }
    }

    //endregion

    getBorderColor(signScan: ISignScan): string {
        if (this.signScanService.isCheckedOut(signScan)) return "var(--color-finished)";
        if (this.signScanService.isLost(signScan)) return "var(--color-cancelled)";
        return "var(--color-in-progress)";
    }

    async move(dateString: string, modal: ModalComponent) {
        if (!moment(dateString, "yyyy-MM-dd").isValid()) this.toastService.showError("Invalid date");

        const parkingBanId = this.route.snapshot.params["parkingBanId"];
        await this.parkingBanService.moveParkingBan(parkingBanId, moment(dateString, "yyyy-MM-dd").toDate());
        modal.closeModal();
        await this.router.navigate(["/assignment", this.assignment.id, "parking-bans"]);
    }

    async onGeneralFormChange() {
        const updatedParkingBan = {
            assignment: this.parkingBan.assignment,
            description: this.parkingBan.description,
            end: new Date(this.parkingBan.end),
            id: this.parkingBan.id,
            isCompleted: this.parkingBan.isCompleted,
            location: this.parkingBan.location,
            name: this.parkingBan.name,
            start: new Date(this.parkingBan.start),
        } as IParkingBan;

        updatedParkingBan.description = this.parkingBanForm.get("generalForm").get("description").value;
        updatedParkingBan.end = moment(this.parkingBanForm.get("generalForm").get("end").value).toDate();
        updatedParkingBan.isCompleted = this.parkingBanForm.get("isCompleted").value;
        updatedParkingBan.start = moment(this.parkingBanForm.get("generalForm").get("start").value).toDate();

        await this.parkingBanService.updateParkingBan(updatedParkingBan);
    }

    async onAttachmentDescriptionChange(attachmentId: number, event: Event) {
        const description = (event.target as HTMLInputElement).value;
        const currentAttachment = this.parkingBan.photos.find((photo) => photo.id === attachmentId);
        if (currentAttachment.description !== description) {
            currentAttachment.description = description;
            await this.parkingBanService.updateParkingBanAttachment(currentAttachment, this.parkingBan.id);
        }
    }

    async onSignScanCodeChange(signScanId: number, event: Event) {
        const signScanCode = (event.target as HTMLInputElement).value;

        const currentSignScan = this.parkingBan.signScans.find((signScan) => signScan.id === signScanId);
        if (currentSignScan.signCode !== signScanCode) {
            currentSignScan.signCode = signScanCode;
            await this.parkingBanService.updateParkingBanSignScan(currentSignScan, this.parkingBan.id);
        }
    }

    openAttachmentsGalleria(index: number) {
        const images = this.parkingBan.photos.map(
            (photo) =>
                ({
                    alt: photo.description,
                    previewImageSrc: photo.url,
                    source: photo.url,
                    title: photo.name,
                }) as IGalleriaImage,
        );

        this.galleriaService.open(images, index);
    }

    openExceptionsGalleria(index: number) {
        const images = this.parkingBan.exceptions
            .map((exception) => exception.photos)
            .flat()
            .map(
                (photo) =>
                    ({
                        alt: photo.description,
                        previewImageSrc: photo.url,
                        source: photo.url,
                        title: photo.name,
                    }) as IGalleriaImage,
            );

        this.galleriaService.open(images, index);
    }

    async onFileLoadEnd(event: PhotoInputFileLoadEnd) {
        let coordinate: ICoordinate;
        if (event.metadata && event.metadata?.latitude && event.metadata?.longitude) {
            coordinate = {
                latitude: event.metadata?.latitude,
                longitude: event.metadata?.longitude,
            };
        }

        if (event.eventName === "photo") {
            const attachment = await this.parkingBanService.createParkingBanAttachment(
                this.parkingBan,
                event.base64image,
                "",
            );
            await this.fetchParkingBan(this.assignment.id, this.parkingBan.id);

            const formGroup = this.formBuilder.group({
                attachmentId: new FormControl(attachment.id),
                attachmentImage: new FormControl(attachment.url),
                attachmentDescription: new FormControl(""),
            });

            this.attachments.push(formGroup);
        }
        if (event.eventName === "exception") {
            const organizationId =
                this.assignment.project?.parentProject?.organizationId ?? this.assignment.project?.organizationId;

            let location: ILocation;
            if (coordinate) {
                location = {
                    coordinate,
                } as ILocation;
            } else {
                location = {
                    coordinate: await this.locationService.getCurrentCoordinates().catch(() => {
                        this.toastService.showWarning("location.usedDefault");
                        return this.parkingBan.location.coordinate;
                    }),
                } as ILocation;
            }

            const parkingBanException = await this.parkingBanService.createParkingBanExceptionWithAttachment(
                this.parkingBan,
                organizationId,
                event.base64image as string,
                location,
            );

            await this.fetchParkingBan(this.assignment.id, this.parkingBan.id);

            const exceptionsForm = this.formBuilder.group({
                exceptionId: new FormControl(parkingBanException.id),
                exceptionImage: new FormControl(parkingBanException.photos[0].url),
                exceptionLicensePlate: new FormControl(parkingBanException.licensePlate),
            });

            this.exceptions.push(exceptionsForm);
        }
    }
}
