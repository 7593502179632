<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<content-default>
    <ng-container body>
        @if (parkingBanForm) {
            <form
                class="flex flex-column gap-16"
                [formGroup]="parkingBanForm"
            >
                <!--region Header-->
                <div class="flex align-items-center justify-content-between gap-16">
                    <h3>{{ parkingBan.name }}</h3>

                    <div class="flex align-items-center gap-16">
                        <div class="flex align-items-center">
                            <button
                                class="btn--yellow"
                                [disabled]="!parkingBanForm.valid || parkingBan.isCompleted"
                                (click)="modal.openModal()"
                                type="submit"
                            >
                                {{ "parkingBanPage.move" | translate }}
                            </button>
                        </div>
                        <div class="flex align-items-center gap-8">
                            <label for="isCompleted">{{ "parkingBanPage.isCompleted" | translate }}</label>
                            <label
                                class="input--toggle"
                                for="isCompleted"
                            >
                                <input
                                    id="isCompleted"
                                    formControlName="isCompleted"
                                    type="checkbox"
                                />
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>
                <!--endregion-->

                <!--region General Form-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "parkingBanPage.general" | translate }}</h3>
                    </div>

                    <div class="card__body">
                        <div
                            class="form__fields"
                            formGroupName="generalForm"
                        >
                            <!--region Address-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="description"
                                >
                                    {{ "parkingBanPage.address" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        (click)="openMapsDialog($event)"
                                        readonly
                                        value="{{ location.address.line1 }}, {{ location.address.zipCode }} {{
                                            location.address.city
                                        }}"
                                    />
                                </div>
                            </div>
                            <!--endregion-->

                            <!--region Description-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="description"
                                >
                                    {{ "parkingBanPage.description" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        id="description"
                                        (delayedInput)="onGeneralFormChange()"
                                        appDelayedInput
                                        formControlName="description"
                                        placeholder=""
                                        type="text"
                                    />
                                </div>
                            </div>
                            <!--endregion-->

                            <!--region Start-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="start"
                                >
                                    {{ "parkingBanPage.start" | translate }}
                                </label>

                                <div
                                    class="grid__item-8"
                                    (click)="startDateInput.showPicker()"
                                >
                                    <input
                                        class="input--text"
                                        id="start"
                                        #startDateInput
                                        (change)="onGeneralFormChange()"
                                        formControlName="start"
                                        max="{{ parkingBanForm.get('generalForm.end').value | date: 'yyyy-MM-dd' }}"
                                        min="{{ moment().toDate() | date: 'yyyy-MM-dd' }}"
                                        placeholder=""
                                        type="date"
                                    />
                                </div>
                            </div>
                            <!--endregion-->

                            <!--region End-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="end"
                                >
                                    {{ "parkingBanPage.end" | translate }}
                                </label>

                                <div
                                    class="grid__item-8"
                                    (click)="endDateInput.showPicker()"
                                >
                                    <input
                                        class="input--text"
                                        id="end"
                                        #endDateInput
                                        (change)="onGeneralFormChange()"
                                        formControlName="end"
                                        min="{{ parkingBanForm.get('generalForm.start').value | date: 'yyyy-MM-dd' }}"
                                        placeholder=""
                                        type="date"
                                    />
                                </div>
                            </div>
                            <!--endregion-->
                        </div>
                    </div>
                </div>
                <!--endregion-->

                <!--region Attachment Form-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "parkingBanPage.images" | translate }}</h3>

                        <button
                            class="btn--icon"
                            (click)="mPhotoInput.open('photo')"
                        >
                            <m-svg iconName="plus" />
                        </button>
                    </div>

                    <div class="card__body">
                        <div
                            class="form__fields attachments"
                            formArrayName="attachmentsForm"
                        >
                            @for (photoForm of attachments.controls; track $index) {
                                <ng-container [formGroup]="photoForm">
                                    <div class="attachment">
                                        <button
                                            class="attachment__delete btn--icon"
                                            (click)="onParkingBanAttachmentDelete(photoForm.get('attachmentId').value)"
                                        >
                                            <m-svg iconName="delete" />
                                        </button>

                                        <img
                                            class="attachment__image"
                                            [alt]="photoForm.get('attachmentDescription').value"
                                            [src]="photoForm.get('attachmentImage').value"
                                            (click)="openAttachmentsGalleria($index)"
                                        />

                                        <div class="attachment__body">
                                            <div class="form__field">
                                                <label for="attachmentDescription">{{
                                                    "parkingBanPage.description" | translate
                                                }}</label>
                                                <input
                                                    class="input--text"
                                                    id="attachmentDescription"
                                                    (delayedInput)="
                                                        onAttachmentDescriptionChange(
                                                            photoForm.get('attachmentId').value,
                                                            $event
                                                        )
                                                    "
                                                    appDelayedInput
                                                    formControlName="attachmentDescription"
                                                    placeholder=""
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            }
                        </div>
                    </div>
                </div>
                <!--endregion-->

                <!--region Exceptions Form-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "parkingBanPage.exceptions" | translate }}</h3>

                        <button
                            class="btn--icon"
                            (click)="mPhotoInput.open('exception')"
                        >
                            <m-svg iconName="plus" />
                        </button>
                    </div>

                    <div class="card__body">
                        <div
                            class="form__fields attachments"
                            formArrayName="exceptionsForm"
                        >
                            @for (exceptionForm of exceptions.controls; track $index) {
                                <ng-container [formGroup]="exceptionForm">
                                    <div class="attachment">
                                        <button
                                            class="attachment__delete btn--icon"
                                            (click)="onExceptionDelete(exceptionForm.get('exceptionId').value)"
                                        >
                                            <m-svg iconName="delete" />
                                        </button>

                                        <img
                                            class="attachment__image"
                                            [alt]="exceptionForm.get('exceptionLicensePlate').value"
                                            [src]="exceptionForm.get('exceptionImage').value"
                                            (click)="openExceptionsGalleria($index)"
                                        />

                                        <div class="attachment__body">
                                            <div class="form__field">
                                                <label for="exceptionLicensePlate">{{
                                                    "parkingBanPage.licensePlate" | translate
                                                }}</label>
                                                <input
                                                    class="input--text"
                                                    id="exceptionLicensePlate"
                                                    (delayedInput)="
                                                        onLicensePlateChange(
                                                            exceptionForm.get('exceptionId').value,
                                                            $event
                                                        )
                                                    "
                                                    appDelayedInput
                                                    formControlName="exceptionLicensePlate"
                                                    placeholder=""
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            }
                        </div>
                    </div>
                </div>
                <!--endregion-->

                <!--region Sign scan Form-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "parkingBanPage.scannedSigns" | translate }}</h3>

                        <button
                            class="btn--icon"
                            (click)="signScansModal.open($event)"
                        >
                            <m-svg iconName="plus" />
                        </button>
                    </div>

                    <div class="card__body">
                        <div
                            class="form__fields attachments sign-scans"
                            formArrayName="signScansForm"
                        >
                            @for (signScanForm of signScans.controls; track $index) {
                                <ng-container [formGroup]="signScanForm">
                                    <div
                                        class="attachment sign-scan"
                                        [style]="{
                                            '--signScanBorderColor': getBorderColor(signScanForm.get('signScan').value),
                                        }"
                                        (click)="navigateToSignScan(signScanForm.get('signScanId').value)"
                                    >
                                        <button
                                            class="attachment__delete btn--icon"
                                            (click)="
                                                $event.stopPropagation();
                                                onQrCodeDelete(signScanForm.get('signScanId').value)
                                            "
                                        >
                                            <m-svg iconName="delete" />
                                        </button>
                                        <img
                                            class="attachment__image"
                                            [alt]="signScanForm.get('signScanCode').value"
                                            src="/assets/parking-ban.png"
                                        />

                                        <div class="attachment__body">
                                            <div class="flex gap-16">
                                                <div class="form__field">
                                                    <label [for]="'signScanCode-' + $index">{{
                                                        "parkingBanPage.code" | translate
                                                    }}</label>
                                                    <input
                                                        class="input--text"
                                                        [id]="'signScanCode-' + $index"
                                                        (click)="$event.stopPropagation()"
                                                        (delayedInput)="
                                                            onSignScanCodeChange(
                                                                signScanForm.get('signScanId').value,
                                                                $event
                                                            )
                                                        "
                                                        appDelayedInput
                                                        formControlName="signScanCode"
                                                        placeholder=""
                                                        type="text"
                                                    />
                                                </div>
                                                <div class="form__field">
                                                    <!--region Is Checked Out-->
                                                    <label [for]="'checkboxIsCheckedOut-' + $index">{{
                                                        "signScanDetailPage.isCheckedOut" | translate
                                                    }}</label>

                                                    <label
                                                        class="input--toggle"
                                                        [for]="'checkboxIsCheckedOut-' + $index"
                                                        (click)="$event.stopPropagation()"
                                                    >
                                                        <input
                                                            [id]="'checkboxIsCheckedOut-' + $index"
                                                            (change)="
                                                                markAsCheckedOut(signScanForm.get('signScan').value)
                                                            "
                                                            formControlName="isCheckedOut"
                                                            type="checkbox"
                                                        />
                                                        <span></span>
                                                    </label>
                                                    <!--endregion-->
                                                </div>

                                                <div class="form__field">
                                                    <!--region Is Lost-->
                                                    <label [for]="'checkboxIsLost-' + $index">{{
                                                        "signScanDetailPage.isLost" | translate
                                                    }}</label>

                                                    <label
                                                        class="input--toggle"
                                                        [for]="'checkboxIsLost-' + $index"
                                                        (click)="$event.stopPropagation()"
                                                    >
                                                        <input
                                                            [id]="'checkboxIsLost-' + $index"
                                                            (change)="markAsLost(signScanForm.get('signScan').value)"
                                                            formControlName="isLost"
                                                            type="checkbox"
                                                        />
                                                        <span></span>
                                                    </label>
                                                    <!--endregion-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            }
                        </div>
                    </div>
                </div>
                <!--endregion-->
            </form>
        }
    </ng-container>
</content-default>

<!--region Photo modals-->

<m-photo-input
    #mPhotoInput
    (fileLoadEnd)="onFileLoadEnd($event)"
/>

<app-qr-scanner
    #signScansModal
    (qrCodeScanned)="onQrCodeScanned($event)"
/>
<!--endregion-->

<!--region Maps dialog-->
@if (this.parkingBanForm) {
    <app-maps-dialog
        [coordinates]="location?.coordinate"
        (saveModal)="onSaveModal($event)"
    />
}
<!--endregion-->

<m-modal
    #modal
    [title]="'parkingBanPage.moveTitle' | translate"
>
    <ng-container body>
        <!--region Start-->
        <div class="form__field grid">
            <label
                class="grid__item-2"
                for="start"
            >
                {{ "parkingBanPage.start" | translate }}
            </label>

            <div
                class="grid__item-10"
                (click)="moveStartDateInput.showPicker()"
            >
                <input
                    class="input--text"
                    id="moveStart"
                    #moveStartDateInput
                    [(ngModel)]="moveDate"
                    min="{{ moment().toDate() | date: 'yyyy-MM-dd' }}"
                    placeholder=""
                    type="date"
                />
            </div>
        </div>
        <!--endregion-->
    </ng-container>
    <ng-container
        #footer
        footer
    >
        <div class="flex justify-content-end gap-16">
            <button
                class="btn"
                (click)="modal.closeModal(); moveStartDateInput.value = null"
            >
                {{ "general.cancel" | translate }}
            </button>

            <button
                class="btn--yellow"
                [disabled]="!moment(moveDate, 'yyyy-MM-dd').isValid()"
                (click)="move(moveStartDateInput.value, modal)"
            >
                {{ "parkingBanPage.move" | translate }}
            </button>
        </div>
    </ng-container>
</m-modal>
