import { Injectable } from "@angular/core";
import { GalleriaComponent } from "./galleria.component";
import { IGalleriaImage } from "./galleria.modal";

@Injectable({
    providedIn: "root",
})
export class GalleriaService {
    private galleria: GalleriaComponent;

    open(images: IGalleriaImage[], indexStart = 0) {
        if (!this.galleria) {
            console.error("Attemped to show galleria before component was registered");
            return;
        }

        this.galleria.show(images, indexStart);
    }

    register(dialog: GalleriaComponent) {
        this.galleria = dialog;
    }
}
