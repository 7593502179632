<content-default>
    <ng-container body>
        @if (signScanForm) {
            <form
                class="flex flex-column gap-16"
                [formGroup]="signScanForm"
            >
                <div class="flex align-items-center justify-content-between gap-16">
                    <h3>{{ signScan.signCode }}</h3>
                    <div class="flex align-items-center gap-16">
                        <div class="flex align-items-center gap-8">
                            <!--region Is Checked Out-->
                            <label for="checkboxIsCheckedOut">{{
                                "signScanDetailPage.isCheckedOut" | translate
                            }}</label>

                            <label
                                class="input--toggle"
                                for="checkboxIsCheckedOut"
                            >
                                <input
                                    id="checkboxIsCheckedOut"
                                    (change)="markAsCheckedOut()"
                                    formControlName="isCheckedOut"
                                    type="checkbox"
                                />
                                <span></span>
                            </label>
                            <!--endregion-->
                        </div>
                        <div class="flex align-items-center gap-8">
                            <!--region Is Lost-->
                            <label for="checkboxIsLost">{{ "signScanDetailPage.isLost" | translate }}</label>

                            <label
                                class="input--toggle"
                                for="checkboxIsLost"
                            >
                                <input
                                    id="checkboxIsLost"
                                    (change)="markAsLost()"
                                    formControlName="isLost"
                                    type="checkbox"
                                />
                                <span></span>
                            </label>
                            <!--endregion-->
                        </div>
                    </div>
                </div>

                <!--region Location-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "signScanDetailPage.location" | translate }}</h3>
                    </div>
                    <div class="card__body">
                        <!--region Address-->
                        <div class="form__field grid">
                            <label
                                class="grid__item-4"
                                for="address"
                            >
                                {{ "signScanDetailPage.address" | translate }}
                            </label>

                            <div class="grid__item-8">
                                <input
                                    class="input--text"
                                    id="address"
                                    [disabled]="signScan.location?.address === undefined"
                                    [value]="address"
                                    (click)="openMapsDialog($event)"
                                    readonly
                                />
                            </div>
                        </div>
                        <!--endregion-->
                    </div>
                </div>
                <!--endregion-->

                <!--region General-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "signScanDetailPage.general" | translate }}</h3>
                    </div>
                    <div class="card__body">
                        <div
                            class="form__fields"
                            formGroupName="generalForm"
                        >
                            <!--region Sign code-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="inputSignCode"
                                >
                                    {{ "signScanDetailPage.signCode" | translate }}
                                </label>
                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        id="inputSignCode"
                                        formControlName="signCode"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <!--endregion-->

                            <!--region Check in-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="inputCheckIn"
                                >
                                    {{ "signScanDetailPage.checkIn" | translate }}
                                </label>
                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        id="inputCheckIn"
                                        dateFormat
                                        formControlName="checkIn"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <!--endregion-->

                            <!--region Check out-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="inputCheckOut"
                                >
                                    {{ "signScanDetailPage.checkOut" | translate }}
                                </label>
                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        id="inputCheckOut"
                                        dateFormat
                                        formControlName="checkOut"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <!--endregion-->
                        </div>
                    </div>
                </div>
                <!--endregion-->

                <!--region Attachment Form-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "parkingBanPage.images" | translate }}</h3>

                        <button
                            class="btn--icon"
                            (click)="mPhotoInput.open()"
                        >
                            <m-svg iconName="plus" />
                        </button>
                    </div>

                    <div class="card__body">
                        <div
                            class="form__fields attachments"
                            formArrayName="attachmentsForm"
                        >
                            @for (photoForm of attachments.controls; track $index) {
                                <ng-container [formGroup]="photoForm">
                                    <div class="attachment">
                                        <button
                                            class="attachment__delete btn--icon"
                                            (click)="onAttachmentDelete(photoForm.get('id').value)"
                                        >
                                            <m-svg iconName="delete" />
                                        </button>

                                        <img
                                            class="attachment__image"
                                            [alt]="photoForm.get('name').value"
                                            [src]="photoForm.get('url').value"
                                            (click)="openGalleria($index)"
                                        />

                                        <div class="attachment__body">
                                            <div class="form__field">
                                                <label for="attachmentDescription">{{
                                                    "parkingBanPage.description" | translate
                                                }}</label>
                                                <input
                                                    class="input--text"
                                                    id="attachmentDescription"
                                                    formControlName="description"
                                                    placeholder=""
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            }
                        </div>
                    </div>
                </div>
                <!--endregion-->
            </form>
        }
    </ng-container>
</content-default>

@if (signScanForm) {
    <app-maps-dialog
        [coordinates]="signScan.location?.coordinate"
        (saveModal)="onSaveModal($event)"
    />
}

<m-photo-input
    #mPhotoInput
    (fileLoadEnd)="onFileLoadEnd($event)"
/>
