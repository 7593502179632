<nav class="nav">
    <a
        class="nav__link"
        [routerLink]="['/assignment', plannedAssignmentId(), 'details']"
        routerLinkActive="is-active"
    >
        <m-svg iconName="file-lines" />
        <span>{{ "navigation.assignment" | translate }}</span>
    </a>

    <a
        class="nav__link"
        [routerLink]="['/assignment', plannedAssignmentId(), 'tasks']"
        routerLinkActive="is-active"
    >
        <m-svg iconName="task" />
        <span>{{ "navigation.tasks" | translate }}</span>
    </a>

    <a
        class="nav__link"
        [routerLink]="['/assignment', plannedAssignmentId(), 'parking-bans']"
        routerLinkActive="is-active"
    >
        <m-svg iconName="parking" />
        <span>{{ "navigation.parking" | translate }}</span>
    </a>
</nav>
