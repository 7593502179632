import { Injectable } from "@angular/core";
import { AttachmentCreator } from "@ramudden/models/attachment";
import {
    IParkingBanException,
    ParkingBanExceptionCreator,
    ParkingBanExceptionUpdater,
} from "@ramudden/models/parking-ban-exception";
import { Observable } from "rxjs";
import { ApiService } from "./api";

@Injectable({
    providedIn: "root",
})
export class ParkingBanExceptionApi extends ApiService<
    IParkingBanException,
    ParkingBanExceptionCreator,
    ParkingBanExceptionUpdater
> {
    override getRoute(): string {
        return "ParkingBanExceptions";
    }

    createWithAttachment$(
        attachmentCreator: AttachmentCreator,
        parkingBanExceptionCreator: ParkingBanExceptionCreator,
        file: File,
    ): Observable<IParkingBanException> {
        const url = `${super.getUrl()}/createWithAttachment`;
        const formData = new FormData();
        formData.append("attachmentConfig", JSON.stringify(attachmentCreator));
        formData.append("parkingBanExceptionConfig", JSON.stringify(parkingBanExceptionCreator));
        formData.append("data", file);

        return this.http.post<IParkingBanException>(url, formData);
    }
}
