<m-page-default>
    <ng-container header>
        <m-page-header />
    </ng-container>

    <ng-container body>
        <m-loader />
        @if (!isIframe) {
            <router-outlet></router-outlet>
        }
    </ng-container>
</m-page-default>

<m-toast />
<ng-template #modalContainer></ng-template>
<m-galleria></m-galleria>
