import { Injectable } from "@angular/core";
import {
    PinnedDataWebApi,
    PinnedHistoryDataSearchParameters,
    PinnedLiveDataSearchParameters,
} from "@ramudden/data-access/resource/web";
import {
    DataSetSubType,
    IHistoricalData,
    ILiveData,
    IPinnedDataConfigurationPerAnalysisType,
} from "@ramudden/models/pinned-data";
import { VehicleCategory } from "@ramudden/models/vehicle";
import { DownloadedFile } from "@ramudden/services";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class PinnedDataService {
    constructor(private readonly pinnedDataApi: PinnedDataWebApi) {}

    isHistoricalDataAvailable(pinnedDataConfiguration: IPinnedDataConfigurationPerAnalysisType): boolean {
        return pinnedDataConfiguration?.supportedDataSets && pinnedDataConfiguration?.supportedDataSets.length > 0;
    }

    getVehicleCategoryBasedOnDataSetType(dataSetSubType: DataSetSubType): VehicleCategory {
        switch (dataSetSubType) {
            case DataSetSubType.IntensityBikes:
                return VehicleCategory.Bike;
            case DataSetSubType.IntensityMopeds:
                return VehicleCategory.Moped;
            case DataSetSubType.IntensityHeavy:
                return VehicleCategory.Heavy;
            case DataSetSubType.IntensityMedium:
                return VehicleCategory.Medium;
            case DataSetSubType.IntensityLight:
                return VehicleCategory.Light;
            case DataSetSubType.IntensityPedestrians:
                return VehicleCategory.Pedestrian;
            default:
                return VehicleCategory.Unknown;
        }
    }

    loadLiveData$(
        measuringPointId: number,
        pinnedLiveDataSearchParameters: PinnedLiveDataSearchParameters,
        useCache: boolean,
    ): Observable<ILiveData> {
        return this.pinnedDataApi.getLiveData$(measuringPointId, pinnedLiveDataSearchParameters, useCache);
    }

    loadHistoricalData$(
        measuringPointId: number,
        pinnedHistoricalDataSearchParameters: PinnedHistoryDataSearchParameters,
    ): Observable<IHistoricalData> {
        return this.pinnedDataApi.getHistoricalData$(measuringPointId, pinnedHistoricalDataSearchParameters);
    }

    public getPinnedHistoricalDataExportToExcel$(
        id: number,
        searchParameters: PinnedHistoryDataSearchParameters,
    ): Promise<DownloadedFile> {
        return this.pinnedDataApi.getPinnedHistoricalDataExportToExcel$(id, searchParameters);
    }
}
