<content-default>
    <ng-container body>
        @if (this.taskForm) {
            <h2 class="mb-24">{{ title }}</h2>

            <form
                class="flex flex-column gap-16"
                [formGroup]="taskForm"
                autocomplete="off"
            >
                <!--region Location-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "taskPage.location" | translate }}</h3>
                    </div>
                    <div class="card__body">
                        <!--region Address-->
                        <div class="form__field grid">
                            <label
                                class="grid__item-4"
                                for="address"
                            >
                                {{ "taskPage.address" | translate }}
                            </label>

                            <div class="grid__item-8 flex gap-8">
                                <input
                                    class="input--text"
                                    id="address"
                                    [disabled]="location?.address === undefined || !editableAssignment"
                                    [value]="getAddress(location?.address)"
                                    (click)="openMapsDialog($event)"
                                    readonly
                                />
                                @if (locationService.isRetrievingLocation) {
                                    <div class="grid__item-1">
                                        <m-loader-icon />
                                    </div>
                                }
                            </div>
                        </div>
                        <!--endregion-->
                    </div>
                </div>
                <!--endregion-->

                <!--region General-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "taskPage.general" | translate }}</h3>
                    </div>
                    <div class="card__body">
                        <div class="form__fields">
                            <!--region Current Status-->
                            <ng-container
                                *ngIf="taskStatusForm"
                                formGroupName="currentStatus"
                            >
                                <!--region Is New-->
                                <div
                                    class="form__field grid"
                                    *ngIf="selectCanBeRefreshed"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="isNew"
                                    >
                                        {{ "taskPage.isNew" | translate }}
                                    </label>

                                    <div class="grid__item-8">
                                        <label
                                            class="input--toggle"
                                            for="isNew"
                                        >
                                            <input
                                                id="isNew"
                                                formControlName="isNew"
                                                type="checkbox"
                                            />
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Task Type-->
                                <div
                                    class="form__field grid"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('taskTypeId').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="taskTypeId"
                                    >
                                        {{ "taskPage.taskType" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="taskTypeId"
                                            #taskTypeIdInput
                                            [options]="taskTypes"
                                            (selectionChanged)="handleTaskTypeChange($event)"
                                            formControlName="taskTypeId"
                                            placeHolder="{{ 'taskPage.selectTaskType' | translate }}"
                                        />

                                        <app-form-validation
                                            [control]="taskStatusForm.get('taskTypeId')"
                                            [input]="taskTypeIdInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Sign Material-->
                                <div
                                    class="form__field grid"
                                    *ngIf="selectMaterial"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('signMaterialId').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="signMaterialId"
                                    >
                                        {{ "taskPage.signMaterial" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="signMaterialId"
                                            #signMaterialIdInput
                                            [options]="signMaterials"
                                            (selectionChanged)="handleMaterialChange($event)"
                                            formControlName="signMaterialId"
                                            placeHolder="{{ 'taskPage.selectSignMaterial' | translate }}"
                                        >
                                        </m-dropdown>

                                        <app-form-validation
                                            [control]="taskStatusForm.get('signMaterialId')"
                                            [input]="signMaterialIdInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Is Machine Work-->
                                <div
                                    class="form__field grid"
                                    *ngIf="selectIsMachineWork"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="isMachineWork"
                                    >
                                        {{ "taskPage.isMachineWork" | translate }}
                                    </label>

                                    <div class="grid__item-8">
                                        <input
                                            class="input--option"
                                            id="isMachineWork"
                                            (change)="handleIsMachineWorkChange()"
                                            formControlName="isMachineWork"
                                            type="checkbox"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Primer-->
                                <div
                                    class="form__field grid"
                                    *ngIf="selectCanHavePrimer"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="primer"
                                    >
                                        {{ "taskPage.primer" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--option"
                                            id="primer"
                                            formControlName="primer"
                                            type="checkbox"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Substrate-->
                                <div
                                    class="form__field grid"
                                    *ngIf="selectShowSubstrate"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('substrateTypeId').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="substrateTypeId"
                                    >
                                        {{ "taskPage.substrateType" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="substrateTypeId"
                                            #substrateTypeIdInput
                                            [options]="allSubstrateTypes"
                                            (selectionChanged)="handleSubstrateTypeChange($event)"
                                            formControlName="substrateTypeId"
                                            placeHolder="{{ 'taskPage.selectSubstrateType' | translate }}"
                                        >
                                        </m-dropdown>

                                        <app-form-validation
                                            [control]="taskStatusForm.get('substrateTypeId')"
                                            [input]="substrateTypeIdInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Substrate Override-->
                                <div
                                    class="form__field grid"
                                    *ngIf="selectShowSubstrate && taskStatusForm.value?.substrateTypeId === SubstrateType.Others"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('substrateTypeOverride').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="substrateTypeOverride"
                                    >
                                        {{ "taskPage.substrateTypeOverride" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="substrateTypeOverride"
                                            #substrateTypeOverrideInput
                                            formControlName="substrateTypeOverride"
                                            type="text"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('substrateTypeOverride')"
                                            [input]="substrateTypeOverrideInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Sign Category-->
                                <div
                                    class="form__field grid"
                                    *ngIf="selectShowCategories"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('signCategoryId').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="signCategoryId"
                                    >
                                        {{ "taskPage.signCategory" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="signCategoryId"
                                            #signCategoryIdInput
                                            [options]="signCategoryValues"
                                            (selectionChanged)="handleCategoryChange($event)"
                                            formControlName="signCategoryId"
                                            placeHolder="{{ 'taskPage.selectSignCategory' | translate }}"
                                        >
                                        </m-dropdown>

                                        <app-form-validation
                                            [control]="taskStatusForm.get('signCategoryId')"
                                            [input]="signCategoryIdInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Sign Category Level 2-->
                                <div
                                    class="form__field grid"
                                    *ngIf="selectLevel2Category"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('signCategoryLevel2Id').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="signCategoryLevel2Id"
                                    >
                                        {{ "taskPage.signCategoryLevel2" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="signCategoryLevel2Id"
                                            #signCategoryLevel2IdInput
                                            [options]="signCategoryLevel2Values"
                                            (selectionChanged)="handleCategoryLevel2Change($event)"
                                            formControlName="signCategoryLevel2Id"
                                            placeHolder="{{ 'taskPage.selectSignCategory' | translate }}"
                                        >
                                        </m-dropdown>

                                        <app-form-validation
                                            [control]="taskStatusForm.get('signCategoryLevel2Id')"
                                            [input]="signCategoryLevel2IdInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Sign Category Level 3-->
                                <div
                                    class="form__field grid"
                                    *ngIf="selectLevel3Category"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('signCategoryLevel3Id').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="signCategoryLevel3Id"
                                    >
                                        {{ "taskPage.signCategoryLevel3" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="signCategoryLevel3Id"
                                            #signCategoryLevel3IdInput
                                            [options]="signCategoryLevel3Values"
                                            (selectionChanged)="handleCategoryLevel3Change($event)"
                                            formControlName="signCategoryLevel3Id"
                                            placeHolder="{{ 'taskPage.selectSignCategory' | translate }}"
                                        >
                                        </m-dropdown>

                                        <app-form-validation
                                            [control]="taskStatusForm.get('signCategoryLevel3Id')"
                                            [input]="signCategoryLevel3IdInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Sign Category Level 4-->
                                <div
                                    class="form__field grid"
                                    *ngIf="selectLevel4Category"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('signCategoryLevel4Id').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="signCategoryLevel4Id"
                                    >
                                        {{ "taskPage.signCategoryLevel4" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="signCategoryLevel4Id"
                                            #signCategoryLevel4IdInput
                                            [options]="signCategoryLevel4Values"
                                            (selectionChanged)="handleCategoryLevel4Change($event)"
                                            formControlName="signCategoryLevel4Id"
                                            placeHolder="{{ 'taskPage.selectSignCategory' | translate }}"
                                        >
                                        </m-dropdown>

                                        <app-form-validation
                                            [control]="taskStatusForm.get('signCategoryLevel4Id')"
                                            [input]="signCategoryLevel4IdInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Image that is explaining the options-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showImage"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="image"
                                    >
                                    </label>
                                    <div class="grid__item-8">
                                        <img
                                            class="grid__item-4"
                                            id="image"
                                            [alt]="propertyConfiguration.imageName"
                                            [src]="'/assets/sign-categories/' + propertyConfiguration.imageName"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Type-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showType"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('type').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="type"
                                    >
                                        {{ "taskPage.type" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="type"
                                            #typeInput
                                            [options]="typeOptions"
                                            formControlName="type"
                                            placeHolder="{{ 'taskPage.selectType' | translate }}"
                                        >
                                        </m-dropdown>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('type')"
                                            [input]="typeInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Custom Sign Color-->
                                <div
                                    class="form__field grid"
                                    *ngIf="selectColor && displayCustomSignColor"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customSignColor').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customSignColor"
                                    >
                                        {{ "taskPage.signColor" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="customSignColor"
                                            #signColorInput
                                            [options]="signColors"
                                            formControlName="customSignColor"
                                            placeHolder="{{ 'taskPage.selectColor' | translate }}"
                                        >
                                            <ng-template let-signColor>
                                                <div
                                                    [style.background-color]="signColor.value"
                                                    style="width: 2rem; height: 2rem; border-radius: 50%"
                                                ></div>
                                                <span>
                                                    {{ signColor.label | translate }}
                                                </span>
                                            </ng-template>
                                        </m-dropdown>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customSignColor')"
                                            [input]="signColorInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Sign Color-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        selectColor && propertyConfiguration && propertyConfiguration.showSignColorList
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('signColorId').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="signColor"
                                    >
                                        {{ "taskPage.signColor" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="signColor"
                                            #signColorInput
                                            [options]="signColorOptions"
                                            formControlName="signColorId"
                                            placeHolder="{{ 'taskPage.selectSignColor' | translate }}"
                                        >
                                            <ng-template let-signColor>
                                                <span [style.background-color]="signColor.value">
                                                    {{ signColor.label | translate }}
                                                </span>
                                            </ng-template>
                                        </m-dropdown>

                                        <app-form-validation
                                            [control]="taskStatusForm.get('signColorId')"
                                            [input]="signColorInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Length-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        currentSignCategory &&
                                        propertyConfiguration &&
                                        propertyConfiguration.showLength &&
                                        !hideLengthList
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('length').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="length"
                                    >
                                        {{
                                            currentSignCategory.lengthLabel
                                                | translateModel: translateService.currentLang
                                                | safeHtml
                                        }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="length"
                                            #lengthInput
                                            [options]="lengthOptions"
                                            (selectionChanged)="handleLengthChange($event)"
                                            formControlName="length"
                                            placeHolder="{{ 'taskPage.selectLength' | translate }}"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('length')"
                                            [input]="lengthInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Custom Length-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        currentSignCategory &&
                                        propertyConfiguration &&
                                        propertyConfiguration.showLength &&
                                        displayCustomLength
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customLength').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customLength"
                                    >
                                        {{
                                            currentSignCategory.lengthLabel
                                                | translateModel: translateService.currentLang
                                                | safeHtml
                                        }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="customLength"
                                                #customLengthInput
                                                formControlName="customLength"
                                                min="0"
                                                placeholder="{{
                                                    currentSignCategory.lengthLabel
                                                        | translateModel: translateService.currentLang
                                                        | safeHtml
                                                }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.lengthUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customLength')"
                                            [input]="customLengthInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Width-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        currentSignCategory &&
                                        propertyConfiguration &&
                                        propertyConfiguration.showWidth &&
                                        !hideWidthList
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('width').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="width"
                                    >
                                        {{
                                            currentSignCategory.widthLabel
                                                | translateModel: translateService.currentLang
                                                | safeHtml
                                        }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="width"
                                            #widthInput
                                            [options]="widthOptions"
                                            (selectionChanged)="handleWidthChange($event)"
                                            formControlName="width"
                                            placeHolder="{{ 'taskPage.selectWidth' | translate }}"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('width')"
                                            [input]="widthInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Custom Width-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        currentSignCategory &&
                                        propertyConfiguration &&
                                        propertyConfiguration.showWidth &&
                                        displayCustomWidth
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customWidth').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customWidth"
                                    >
                                        {{
                                            currentSignCategory.widthLabel
                                                | translateModel: translateService.currentLang
                                                | safeHtml
                                        }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="customWidth"
                                                #customWidthInput
                                                formControlName="customWidth"
                                                min="0"
                                                placeholder="{{
                                                    currentSignCategory.widthLabel
                                                        | translateModel: translateService.currentLang
                                                        | safeHtml
                                                }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.widthUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customWidth')"
                                            [input]="customWidthInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Short Piece Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showShortPieceCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('shortPieceCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="shortPieceCount"
                                    >
                                        {{ "taskPage.shortPieceCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="shortPieceCount"
                                            #shortPieceCountInput
                                            formControlName="shortPieceCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.shortPieceCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('shortPieceCount')"
                                            [input]="shortPieceCountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--Rainbow-->
                                <!--region White Substrate length-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showWhiteSubstrateLength"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('whiteSubstrateLength').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="whiteSubstrateLength"
                                    >
                                        {{ "taskPage.whiteSubstrateLength" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="whiteSubstrateLength"
                                                #whiteSubstrateLengthInput
                                                formControlName="whiteSubstrateLength"
                                                min="0"
                                                placeholder="{{ 'taskPage.whiteSubstrateLength' | translate }}"
                                                type="number"
                                            />
                                            <div
                                                [innerHtml]="getUnit(propertyConfiguration.whiteSubstrateLengthUnit)"
                                            ></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('whiteSubstrateLength')"
                                            [input]="whiteSubstrateLengthInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region White Substrate Width-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showWhiteSubstrateWidth"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('whiteSubstrateWidth').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="whiteSubstrateWidth"
                                    >
                                        {{ "taskPage.whiteSubstrateWidth" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="whiteSubstrateWidth"
                                                #whiteSubstrateWidthInput
                                                formControlName="whiteSubstrateWidth"
                                                min="0"
                                                placeholder="{{ 'taskPage.whiteSubstrateWidth' | translate }}"
                                                type="number"
                                            />
                                            <div
                                                [innerHtml]="getUnit(propertyConfiguration.whiteSubstrateWidthUnit)"
                                            ></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('whiteSubstrateWidth')"
                                            [input]="whiteSubstrateWidthInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region White Band Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showWhiteBandCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('whiteBandCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="whiteBandCount"
                                    >
                                        {{ "taskPage.whiteBandCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="whiteBandCount"
                                            #whiteBandCountInput
                                            formControlName="whiteBandCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.whiteBandCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('whiteBandCount')"
                                            [input]="whiteBandCountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Red Band Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showRedBandCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('redBandCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="redBandCount"
                                    >
                                        {{ "taskPage.redBandCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="redBandCount"
                                            #redBandCountInput
                                            formControlName="redBandCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.redBandCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('redBandCount')"
                                            [input]="redBandCountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Yellow Band Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showYellowBandCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('yellowBandCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="yellowBandCount"
                                    >
                                        {{ "taskPage.yellowBandCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="yellowBandCount"
                                            #yellowBandCountInput
                                            formControlName="yellowBandCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.yellowBandCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('yellowBandCount')"
                                            [input]="yellowBandCountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Orange Band Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showOrangeBandCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('orangeBandCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="orangeBandCount"
                                    >
                                        {{ "taskPage.orangeBandCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="orangeBandCount"
                                            #orangeBandCountInput
                                            formControlName="orangeBandCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.orangeBandCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('orangeBandCount')"
                                            [input]="orangeBandCountInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Green Band Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showGreenBandCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('greenBandCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="greenBandCount"
                                    >
                                        {{ "taskPage.greenBandCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="greenBandCount"
                                            #greenBandCountInput
                                            formControlName="greenBandCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.greenBandCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('greenBandCount')"
                                            [input]="greenBandCountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Blue Band Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showBlueBandCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('blueBandCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="blueBandCount"
                                    >
                                        {{ "taskPage.blueBandCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="blueBandCount"
                                            #blueBandCountInput
                                            formControlName="blueBandCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.blueBandCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('blueBandCount')"
                                            [input]="blueBandCountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Purple Band Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showPurpleBandCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('purpleBandCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="purpleBandCount"
                                    >
                                        {{ "taskPage.purpleBandCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="purpleBandCount"
                                            #purpleBandCountInput
                                            formControlName="purpleBandCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.purpleBandCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('purpleBandCount')"
                                            [input]="purpleBandCountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Height-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showHeight && !hideHeightList"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('height').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="height"
                                    >
                                        {{ "taskPage.height" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="height"
                                            #heightInput
                                            [options]="heightOptions"
                                            (selectionChanged)="handleHeightChange($event)"
                                            formControlName="height"
                                            placeHolder="{{ 'taskPage.selectHeight' | translate }}"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('height')"
                                            [input]="heightInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Custom Height-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration && propertyConfiguration.showHeight && displayCustomHeight
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customHeight').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customHeight"
                                    >
                                        {{ "taskPage.customHeight" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="customHeight"
                                                #customHeightInput
                                                formControlName="customHeight"
                                                min="0"
                                                placeholder="{{ 'taskPage.customHeight' | translate }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.heightUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customHeight')"
                                            [input]="customHeightInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Colored Length-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        currentSignCategory &&
                                        propertyConfiguration &&
                                        propertyConfiguration.showColoredLength &&
                                        !hideColoredLengthList
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('coloredLength').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="coloredLength"
                                    >
                                        {{
                                            currentSignCategory.coloredLengthLabel
                                                | translateModel: translateService.currentLang
                                                | safeHtml
                                        }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="coloredLength"
                                            #coloredLengthInput
                                            [options]="coloredLengthOptions"
                                            (selectionChanged)="handleColoredLengthChange($event)"
                                            formControlName="coloredLength"
                                            placeHolder="{{ 'taskPage.selectLength' | translate }}"
                                        >
                                        </m-dropdown>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('coloredLength')"
                                            [input]="coloredLengthInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Custom Colored Length-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        currentSignCategory &&
                                        propertyConfiguration &&
                                        propertyConfiguration.showColoredLength &&
                                        displayCustomColoredLength
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customColoredLength').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customColoredLength"
                                    >
                                        {{
                                            currentSignCategory.coloredLengthLabel
                                                | translateModel: translateService.currentLang
                                                | safeHtml
                                        }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="customColoredLength"
                                                #customColoredLengthInput
                                                formControlName="customColoredLength"
                                                min="0"
                                                placeholder="{{
                                                    currentSignCategory.coloredLengthLabel
                                                        | translateModel: translateService.currentLang
                                                        | safeHtml
                                                }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.coloredLengthUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customColoredLength')"
                                            [input]="customColoredLengthInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Colored Width-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        currentSignCategory &&
                                        propertyConfiguration &&
                                        propertyConfiguration.showColoredWidth &&
                                        !hideColoredWidthList
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('coloredWidth').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="coloredWidth"
                                    >
                                        {{
                                            currentSignCategory.coloredWidthLabel
                                                | translateModel: translateService.currentLang
                                                | safeHtml
                                        }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="coloredWidth"
                                            #coloredWidthInput
                                            [options]="coloredWidthOptions"
                                            (selectionChanged)="handleColoredWidthChange($event)"
                                            formControlName="coloredWidth"
                                            placeHolder="{{ 'taskPage.selectWidth' | translate }}"
                                        >
                                        </m-dropdown>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('coloredWidth')"
                                            [input]="coloredWidthInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Custom Colored Width-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        currentSignCategory &&
                                        propertyConfiguration &&
                                        propertyConfiguration.showColoredWidth &&
                                        displayCustomColoredWidth
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customColoredWidth').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customColoredWidth"
                                    >
                                        {{
                                            currentSignCategory.coloredWidthLabel
                                                | translateModel: translateService.currentLang
                                                | safeHtml
                                        }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="customColoredWidth"
                                                #customColoredWidthInput
                                                formControlName="customColoredWidth"
                                                min="0"
                                                placeholder="{{
                                                    currentSignCategory.coloredWidthLabel
                                                        | translateModel: translateService.currentLang
                                                        | safeHtml
                                                }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.coloredWidthUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customColoredWidth')"
                                            [input]="customColoredWidthInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Text-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showText && !hideTextList"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('text').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="text"
                                    >
                                        {{ "taskPage.text" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="text"
                                            #textInput
                                            [options]="textOptions"
                                            (selectionChanged)="handleTextChange($event)"
                                            formControlName="text"
                                            placeHolder="{{ 'taskPage.selectText' | translate }}"
                                        >
                                        </m-dropdown>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('text')"
                                            [input]="textInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Custom Text-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showText && displayCustomText"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customText').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customText"
                                    >
                                        {{ "taskPage.customText" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <textarea
                                            class="input--textarea"
                                            id="customText"
                                            #customTextInput
                                            formControlName="customText"
                                            placeholder="{{ 'taskPage.customText' | translate }}"
                                        >
                                        </textarea>

                                        <app-form-validation
                                            [control]="taskStatusForm.get('customText')"
                                            [input]="customTextInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Diameter-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration && propertyConfiguration.showDiameter && !hideDiameterList
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('diameter').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="diameter"
                                    >
                                        {{ "taskPage.diameter" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="diameter"
                                            #diameterInput
                                            [options]="diameterOptions"
                                            (selectionChanged)="handleDiameterChange($event)"
                                            formControlName="diameter"
                                            placeHolder="{{ 'taskPage.selectDiameter' | translate }}"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('diameter')"
                                            [input]="diameterInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Custom Diameter-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration &&
                                        propertyConfiguration.showDiameter &&
                                        displayCustomDiameter
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customDiameter').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customDiameter"
                                    >
                                        {{ "taskPage.customDiameter" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="customDiameter"
                                                #customDiameterInput
                                                formControlName="customDiameter"
                                                min="0"
                                                placeholder="{{ 'taskPage.customDiameter' | translate }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.dimensionUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customDiameter')"
                                            [input]="customDiameterInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Place Number-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showPlacenumber"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('placenumber').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="placenumber"
                                    >
                                        {{ "taskPage.placenumber" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="placenumber"
                                            #placenumberInput
                                            formControlName="placenumber"
                                            min="0"
                                            placeholder="{{ 'taskPage.placenumber' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('placenumber')"
                                            [input]="placenumberInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Speed-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showSpeed && !hideSpeedList"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('speed').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="text"
                                    >
                                        {{ "taskPage.speed" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="speed"
                                            #speedInput
                                            [options]="speedOptions"
                                            (selectionChanged)="handleSpeedChange($event)"
                                            formControlName="speed"
                                            placeHolder="{{ 'taskPage.selectSpeed' | translate }}"
                                        >
                                        </m-dropdown>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('speed')"
                                            [input]="speedInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Custom Speed-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration && propertyConfiguration.showSpeed && displayCustomSpeed
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customSpeed').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customSpeed"
                                    >
                                        {{ "taskPage.customSpeed" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="customSpeed"
                                            #customSpeedInput
                                            formControlName="customSpeed"
                                            min="0"
                                            placeholder="{{ 'taskPage.customSpeed' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customSpeed')"
                                            [input]="customSpeedInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Block1 Amount-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showBlock1Amount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('block1Amount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="block1Amount"
                                    >
                                        {{ "taskPage.block1Amount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="block1Amount"
                                            #block1AmountInput
                                            formControlName="block1Amount"
                                            min="0"
                                            placeholder="{{ 'taskPage.block1Amount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('block1Amount')"
                                            [input]="block1AmountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Block1 Width -->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration &&
                                        propertyConfiguration.showBlock1Width &&
                                        !hideBlock1WidthList
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('block1Width').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="block1Width"
                                    >
                                        {{ "taskPage.block1Width" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="block1Width"
                                            #block1WidthInput
                                            [options]="block1WidthOptions"
                                            (selectionChanged)="handleBlock1WidthChange($event)"
                                            formControlName="block1Width"
                                            placeHolder="{{ 'taskPage.block1Width' | translate }}"
                                        >
                                        </m-dropdown>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('block1Width')"
                                            [input]="block1WidthInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Customer Block1 Width-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration &&
                                        propertyConfiguration.showBlock1Width &&
                                        displayCustomBlock1Width
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customBlock1Width').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customBlock1Width"
                                    >
                                        {{ "taskPage.block1Width" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="customBlock1Width"
                                                #customBlock1WidthInput
                                                formControlName="customBlock1Width"
                                                min="0"
                                                placeholder="{{ 'taskPage.block1Width' | translate }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.blocksWidthUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customBlock1Width')"
                                            [input]="customBlock1WidthInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Block2 Amount-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showBlock2Amount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('block2Amount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="block2Amount"
                                    >
                                        {{ "taskPage.block2Amount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="block2Amount"
                                            #block2AmountInput
                                            formControlName="block2Amount"
                                            min="0"
                                            placeholder="{{ 'taskPage.block2Amount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('block2Amount')"
                                            [input]="block2AmountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Block2 Width-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration &&
                                        propertyConfiguration.showBlock2Width &&
                                        !hideBlock2WidthList
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('block2Width').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="block2Width"
                                    >
                                        {{ "taskPage.block2Width" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="block2Width"
                                            #block2WidthInput
                                            [options]="block2WidthOptions"
                                            (selectionChanged)="handleBlock2WidthChange($event)"
                                            formControlName="block2Width"
                                            placeHolder="{{ 'taskPage.block2Width' | translate }}"
                                        >
                                        </m-dropdown>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('block2Width')"
                                            [input]="block2WidthInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Custom Block2 Width-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration &&
                                        propertyConfiguration.showBlock2Width &&
                                        displayCustomBlock2Width
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customBlock2Width').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customBlock2Width"
                                    >
                                        {{ "taskPage.block2Width" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="customBlock2Width"
                                                #customBlock2WidthInput
                                                formControlName="customBlock2Width"
                                                min="0"
                                                placeholder="{{ 'taskPage.block2Width' | translate }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.blocksWidthUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customBlock2Width')"
                                            [input]="customBlock2WidthInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Length A-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showLengthA"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('lengthA').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="lengthA"
                                    >
                                        {{ "taskPage.lengthA" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="lengthA"
                                                #lengthAInput
                                                formControlName="lengthA"
                                                min="0"
                                                placeholder="{{ 'taskPage.lengthA' | translate }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.lengthABCDUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('lengthA')"
                                            [input]="lengthAInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Length B-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showLengthB"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('lengthB').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="lengthB"
                                    >
                                        {{ "taskPage.lengthB" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="lengthB"
                                                #lengthBInput
                                                formControlName="lengthB"
                                                min="0"
                                                placeholder="{{ 'taskPage.lengthB' | translate }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.lengthABCDUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('lengthB')"
                                            [input]="lengthBInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Length C-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showLengthC"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('lengthC').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="lengthC"
                                    >
                                        {{ "taskPage.lengthC" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="lengthC"
                                                #lengthCInput
                                                formControlName="lengthC"
                                                min="0"
                                                placeholder="{{ 'taskPage.lengthC' | translate }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.lengthABCDUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('lengthC')"
                                            [input]="lengthCInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Length D-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showLengthD"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('lengthD').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="lengthD"
                                    >
                                        {{ "taskPage.lengthD" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="lengthD"
                                                #lengthDInput
                                                formControlName="lengthD"
                                                min="0"
                                                placeholder="{{ 'taskPage.lengthD' | translate }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.lengthABCDUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('lengthD')"
                                            [input]="lengthDInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Dimension-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration &&
                                        propertyConfiguration.showDimension &&
                                        !hideDimensionList
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('dropdownDimension').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="text"
                                    >
                                        {{ "taskPage.dimension" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="dropdownDimension"
                                            #dropdownDimensionInput
                                            [options]="dimensionOptions"
                                            (selectionChanged)="handleDimensionChange($event)"
                                            formControlName="dropdownDimension"
                                            placeHolder="{{ 'taskPage.selectDimension' | translate }}"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('dropdownDimension')"
                                            [input]="dropdownDimensionInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Dimension Length-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration &&
                                        propertyConfiguration.showDimension &&
                                        displayCustomDimension
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customDimensionLength').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customDimensionLength"
                                    >
                                        {{ "taskPage.dimensionLength" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="customDimensionLength"
                                                #customDimensionLengthInput
                                                formControlName="customDimensionLength"
                                                min="0"
                                                placeholder="{{ 'taskPage.dimensionLength' | translate }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.dimensionUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customDimensionLength')"
                                            [input]="customDimensionLengthInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Dimension Width-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration &&
                                        propertyConfiguration.showDimension &&
                                        displayCustomDimension
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customDimensionWidth').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customDimensionWidth"
                                    >
                                        {{ "taskPage.dimensionWidth" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="customDimensionWidth"
                                                #customDimensionWidthInput
                                                formControlName="customDimensionWidth"
                                                min="0"
                                                placeholder="{{ 'taskPage.dimensionWidth' | translate }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.dimensionUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customDimensionWidth')"
                                            [input]="customDimensionWidthInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Dimension Description-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration &&
                                        propertyConfiguration.showDimension &&
                                        displayCustomDimension
                                    "
                                    [ngClass]="{
                                        'has-error': taskStatusForm.get('customDimensionDescription').showErrors,
                                    }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customDimensionDescription"
                                    >
                                        {{ "taskPage.dimensionDescription" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="customDimensionDescription"
                                            #customDimensionDescriptionInput
                                            formControlName="customDimensionDescription"
                                            min="0"
                                            placeholder="{{ 'taskPage.dimensionDescription' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customDimensionDescription')"
                                            [input]="customDimensionDescriptionInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region lElement dimension-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration &&
                                        propertyConfiguration.showLElementDimension &&
                                        !hideLElementDimensionList
                                    "
                                    [ngClass]="{
                                        'has-error': taskStatusForm.get('dropdownLElementDimension').showErrors,
                                    }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="text"
                                    >
                                        {{ "taskPage.lElementDimension" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="dropdownLElementDimension"
                                            #dropdownLElementDimensionInput
                                            [options]="lElementDimensionOptions"
                                            (selectionChanged)="handleLElementDimensionChange($event)"
                                            formControlName="dropdownLElementDimension"
                                            placeHolder="{{ 'taskPage.selectLElementDimension' | translate }}"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('dropdownLElementDimension')"
                                            [input]="dropdownLElementDimensionInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Custom lElement Length-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration &&
                                        propertyConfiguration.showLElementDimension &&
                                        displayCustomLDimension
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customLElementLength').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customLElementLength"
                                    >
                                        {{ "taskPage.lElementLength" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="customLElementLength"
                                                #customLElementLengthInput
                                                formControlName="customLElementLength"
                                                min="0"
                                                placeholder="{{ 'taskPage.lElementLength' | translate }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.lElementUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customLElementLength')"
                                            [input]="customLElementLengthInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Custom lElement Width-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration &&
                                        propertyConfiguration.showLElementDimension &&
                                        displayCustomLDimension
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customLElementWidth').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customLElementWidth"
                                    >
                                        {{ "taskPage.lElementWidth" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="customLElementWidth"
                                                #customLElementWidthInput
                                                formControlName="customLElementWidth"
                                                min="0"
                                                placeholder="{{ 'taskPage.lElementWidth' | translate }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.lElementUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customLElementWidth')"
                                            [input]="customLElementWidthInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region lElement Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showLElementCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('lElementCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="lElementCount"
                                    >
                                        {{ "taskPage.lElementCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="lElementCount"
                                            #tasksLElementCountInput
                                            formControlName="lElementCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.lElementCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('lElementCount')"
                                            [input]="tasksLElementCountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region tElement Dimension-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration &&
                                        propertyConfiguration.showTElementDimension &&
                                        !hideTElementDimensionList
                                    "
                                    [ngClass]="{
                                        'has-error': taskStatusForm.get('dropdownTElementDimension').showErrors,
                                    }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="text"
                                    >
                                        {{ "taskPage.tElementDimension" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <m-dropdown
                                            id="dropdownTElementDimension"
                                            #dropdownTElementDimensionInput
                                            [options]="tElementDimensionOptions"
                                            (selectionChanged)="handleTElementDimensionChange($event)"
                                            formControlName="dropdownTElementDimension"
                                            placeHolder="{{ 'taskPage.selectTElementDimension' | translate }}"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('dropdownTElementDimension')"
                                            [input]="dropdownTElementDimensionInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Custom tElement Length-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration &&
                                        propertyConfiguration.showTElementDimension &&
                                        displayCustomTDimension
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customTElementLength').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customTElementLength"
                                    >
                                        {{ "taskPage.tElementLength" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="customTElementLength"
                                                #customTElementLengthInput
                                                formControlName="customTElementLength"
                                                min="0"
                                                placeholder="{{ 'taskPage.tElementLength' | translate }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.tElementUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customTElementLength')"
                                            [input]="customTElementLengthInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Custom tElement Width-->
                                <div
                                    class="form__field grid"
                                    *ngIf="
                                        propertyConfiguration &&
                                        propertyConfiguration.showTElementDimension &&
                                        displayCustomTDimension
                                    "
                                    [ngClass]="{ 'has-error': taskStatusForm.get('customTElementWidth').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="customTElementWidth"
                                    >
                                        {{ "taskPage.tElementWidth" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <div class="flex align-items-center gap-8">
                                            <input
                                                class="input--text"
                                                id="customTElementWidth"
                                                #customTElementWidthInput
                                                formControlName="customTElementWidth"
                                                min="0"
                                                placeholder="{{ 'taskPage.tElementWidth' | translate }}"
                                                type="number"
                                            />
                                            <div [innerHtml]="getUnit(propertyConfiguration.tElementUnit)"></div>
                                        </div>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('customTElementWidth')"
                                            [input]="customTElementWidthInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region tElement Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showTElementCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('tElementCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="tElementCount"
                                    >
                                        {{ "taskPage.tElementCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="tElementCount"
                                            #tasksTElementCountInput
                                            formControlName="tElementCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.tElementCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('tElementCount')"
                                            [input]="tasksTElementCountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Tram Element Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showTRAMElementCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('tramElementCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="tramElementCount"
                                    >
                                        {{ "taskPage.tramElementCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="tramElementCount"
                                            #tramElementCountInput
                                            formControlName="tramElementCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.tramElementCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('tramElementCount')"
                                            [input]="tramElementCountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Logo Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showLogoCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('logoCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="logoCount"
                                    >
                                        {{ "taskPage.logoCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="logoCount"
                                            #tasksLogoCountInput
                                            formControlName="logoCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.logoCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('logoCount')"
                                            [input]="tasksLogoCountInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Banner Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showBannerCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('bannerCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="bannerCount"
                                    >
                                        {{ "taskPage.bannerCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="bannerCount"
                                            #tasksBannerCountInput
                                            formControlName="bannerCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.bannerCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('bannerCount')"
                                            [input]="tasksBannerCountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showMarkingsCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('markingsCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="markingsCount"
                                    >
                                        {{ "taskPage.markingsCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="markingsCount"
                                            #markingsCountInput
                                            formControlName="markingsCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.count' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('markingsCount')"
                                            [input]="markingsCountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Hours Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showHoursCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('hoursCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="hoursCount"
                                    >
                                        {{ "taskPage.hoursCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="hoursCount"
                                            #hoursCountInput
                                            formControlName="hoursCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.hoursCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('hoursCount')"
                                            [input]="hoursCountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Minutes Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showMinutesCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('minutesCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="minutesCount"
                                    >
                                        {{ "taskPage.minutesCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="minutesCount"
                                            #minutesCountInput
                                            formControlName="minutesCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.minutesCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('minutesCount')"
                                            [input]="minutesCountInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--Dropzone-->
                                <!--region Bend Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showBendCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('bendCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="bendCount"
                                    >
                                        {{ "taskPage.bendCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="bendCount"
                                            #bendCountInput
                                            formControlName="bendCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.bendCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('bendCount')"
                                            [input]="bendCountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Arrowhead Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showArrowHeadCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('arrowHeadCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="arrowHeadCount"
                                    >
                                        {{ "taskPage.arrowHeadCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="arrowHeadCount"
                                            #arrowHeadCountInput
                                            formControlName="arrowHeadCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.arrowHeadCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('arrowHeadCount')"
                                            [input]="arrowHeadCountInput"
                                        >
                                        </app-form-validation>
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Rounded end Count-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showRoundedEndCount"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('roundedEndCount').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="roundedEndCount"
                                    >
                                        {{ "taskPage.roundedEndCount" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="roundedEndCount"
                                            #roundedEndCountInput
                                            formControlName="roundedEndCount"
                                            min="0"
                                            placeholder="{{ 'taskPage.roundedEndCount' | translate }}"
                                            type="number"
                                        />
                                        <app-form-validation
                                            [control]="taskStatusForm.get('roundedEndCount')"
                                            [input]="roundedEndCountInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->

                                <!--region Note-->
                                <div
                                    class="form__field grid"
                                    *ngIf="propertyConfiguration && propertyConfiguration.showNote"
                                    [ngClass]="{ 'has-error': taskStatusForm.get('note').showErrors }"
                                >
                                    <label
                                        class="grid__item-4"
                                        for="note"
                                    >
                                        {{ "taskPage.note" | translate }}
                                    </label>
                                    <div class="grid__item-8">
                                        <textarea
                                            class="input--textarea"
                                            id="note"
                                            #noteInput
                                            formControlName="note"
                                            placeholder="{{ 'taskPage.note' | translate }}"
                                        >
                                        </textarea>
                                        <app-form-validation
                                            [control]="taskStatusForm.get('note')"
                                            [input]="noteInput"
                                        />
                                    </div>
                                </div>
                                <!--endregion-->
                            </ng-container>

                            <!--endregion-->

                            <!--region Is finished-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="isNew"
                                >
                                    {{ "taskPage.isFinished" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <label
                                        class="input--toggle"
                                        for="isFinished"
                                    >
                                        <input
                                            id="isFinished"
                                            formControlName="isFinished"
                                            type="checkbox"
                                        />
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                            <!--endregion-->
                        </div>
                    </div>

                    @if (editableAssignment) {
                        <div class="card__actions">
                            <div class="flex justify-content-end align-items-center gap-8">
                                <button
                                    class="btn--lightgrey"
                                    [disabled]="!taskForm.valid"
                                    (click)="saveAndCopy()"
                                >
                                    {{ "taskPage.saveAndCopy" | translate }}
                                </button>
                                <button
                                    class="btn--yellow"
                                    [disabled]="!taskForm.valid"
                                    (click)="save()"
                                >
                                    {{ "taskPage.save" | translate }}
                                </button>
                            </div>
                        </div>
                    }
                </div>
                <!--endregion-->

                <!--region Attachment Form-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "taskPage.images" | translate }}</h3>

                        @if (editableAssignment) {
                            <button
                                class="btn--icon"
                                [disabled]="!taskForm.valid"
                                (click)="addPhoto(mPhotoInput)"
                            >
                                <m-svg iconName="plus" />
                            </button>
                        }
                    </div>

                    <div class="card__body">
                        <div
                            class="form__fields attachments"
                            formArrayName="attachmentsForm"
                        >
                            @for (photoForm of attachments.controls; track $index) {
                                <ng-container [formGroup]="photoForm">
                                    <div class="attachment">
                                        @if (editableAssignment) {
                                            <button
                                                class="attachment__delete btn--icon"
                                                (click)="onAttachmentDelete(photoForm.get('attachmentId').value)"
                                            >
                                                <m-svg iconName="delete" />
                                            </button>
                                        }
                                        <img
                                            class="attachment__image"
                                            [alt]="photoForm.get('attachmentDescription').value"
                                            [src]="photoForm.get('attachmentImage').value"
                                        />

                                        <div class="attachment__body">
                                            <div class="form__field">
                                                <label for="attachmentDescription">{{
                                                    "taskPage.description" | translate
                                                }}</label>
                                                <input
                                                    class="input--text"
                                                    id="attachmentDescription"
                                                    (change)="onAttachmentDescriptionChange($event, photoForm)"
                                                    formControlName="attachmentDescription"
                                                    placeholder=""
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            }
                        </div>
                    </div>
                </div>
                <!--endregion-->
            </form>
        }
    </ng-container>
</content-default>

@if (this.taskForm) {
    <app-maps-dialog
        [coordinates]="location?.coordinate"
        (saveModal)="onSaveModal($event)"
    />
}

<m-photo-input
    #mPhotoInput
    (fileLoadEnd)="onFileLoadEnd($event)"
/>
