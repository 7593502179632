import { IAssignment } from "./assignment";
import { DailyReportsStatus } from "./daily-expense-worklistitem";
import { IDevice } from "./device";
import { IServiceModelBase } from "./servicemodelbase";
import { IUser } from "./user";
import { IWorkerBase } from "./worker";

export interface IPlannedEvent extends IServiceModelBase {
    eventDate: Date;
    order: number;
    isNightShift: boolean;
    isWeekendShift: boolean;
    hasWorkdayEnded: boolean;
    plannedWorkers?: IPlannedWorker[];
    plannedAssignments?: IPlannedAssignment[];
    plannedVehicles?: IPlannedVehicle[];

    lastUpdateDate: Date;
    planner: IUser;
}

export class PlannedEventCreator {
    id: number;
    eventDate: Date;
    order: number;
    isNightShift: boolean;
    isWeekendShift: boolean;

    plannedWorkers?: PlannedWorkerUpdater[];
    plannedAssignments?: PlannedAssignmentUpdater[];
    plannedVehicles?: PlannedVehicleCreator[];

    public constructor(plannedEvent: IPlannedEvent) {
        this.eventDate = plannedEvent.eventDate;
        this.order = plannedEvent.order;
        this.plannedAssignments = [];
        this.plannedWorkers = [];
        this.plannedVehicles = [];
        this.isNightShift = plannedEvent.isNightShift;
        this.isWeekendShift = plannedEvent.isWeekendShift;

        if (plannedEvent.plannedWorkers) {
            for (const plannedWorker of plannedEvent.plannedWorkers) {
                this.plannedWorkers.push(new PlannedWorkerUpdater(plannedWorker));
            }
        }

        if (plannedEvent.plannedAssignments) {
            for (const plannedAssignment of plannedEvent.plannedAssignments) {
                this.plannedAssignments.push(new PlannedAssignmentUpdater(plannedAssignment));
            }
        }

        if (plannedEvent.plannedVehicles) {
            for (const plannedVehicle of plannedEvent.plannedVehicles) {
                this.plannedVehicles.push(new PlannedVehicleUpdater(plannedVehicle));
            }
        }
    }
}

export class PlannedEventUpdater extends PlannedEventCreator {
    constructor(plannedEvent: IPlannedEvent) {
        super(plannedEvent);
        this.id = plannedEvent.id;
    }
}

export interface IPlannedVehicle {
    id: number;

    plannedEventId: number;
    plannedEvent: IPlannedEvent;

    deviceId: number;
    device: IDevice;
}

export class PlannedVehicleCreator {
    deviceId: number;
}

export class PlannedVehicleUpdater {
    constructor(plannedVehicle: IPlannedVehicle) {
        this.deviceId = plannedVehicle.deviceId;
        this.plannedEventId = plannedVehicle.plannedEventId;
        this.id = plannedVehicle?.id;
    }

    id: number;
    deviceId: number;
    plannedEventId: number;
}

export interface IPlannedWorker extends IServiceModelBase {
    guid?: string;
    isCreatedByMobileApp: boolean;
    displayOrder: number;
    isTeamLeader?: boolean;

    //#region "Relations'

    plannedEventId: number;
    plannedEvent?: IPlannedEvent;

    workerId: number;
    worker?: IWorkerBase;

    //endregion "Relations"
}

export class PlannedWorkerCreator {
    isCreatedByMobileApp: boolean;
    displayOrder: number;

    plannedEventId?: number; // can be null if we are creating completely new PlannedEvent with new PlannedWorkers
    workerId: number;

    date: Date; // Used for validation, date of associated planend event so BE can check if that worker is available certain day

    constructor(plannedWorker: IPlannedWorker) {
        this.isCreatedByMobileApp = plannedWorker.isCreatedByMobileApp;
        this.displayOrder = plannedWorker.displayOrder;

        this.plannedEventId = plannedWorker.plannedEventId;
        this.workerId = plannedWorker.workerId;
    }
}

export class PlannedWorkerUpdater extends PlannedWorkerCreator {
    constructor(plannedWorker: IPlannedWorker) {
        super(plannedWorker);
        this.id = plannedWorker.id;
    }

    id: number;
}

export class AssignmentSchedule {
    todayAssignments: IPlannedAssignment[];
    tomorrowAssignments: IPlannedAssignment[];

    constructor(todayAssignments: IPlannedAssignment[] = [], tomorrowAssignments: IPlannedAssignment[] = []) {
        this.todayAssignments = todayAssignments;
        this.tomorrowAssignments = tomorrowAssignments;
    }
}

export interface IPlannedAssignment extends IServiceModelBase {
    guid?: string;
    note: string;
    safetyQuestionsChecked: boolean;
    displayOrder: number;
    dailyExpenseReportUrl?: string;
    dailyParkingBansReportUrl?: string;
    dailyReportsStatusId?: DailyReportsStatus;

    checkInAtWorkLastUpdate?: Date;
    checkInAtWorkChecked?: boolean;

    isPlannedToday?: boolean;

    //#region "Relations'

    plannedEventId: number;
    plannedEvent?: IPlannedEvent;

    assignmentId: number;
    assignment?: IAssignment;

    customTeam?: IPlannedAssignmentCustomTeamMember[];
    customVehicles?: IPlannedAssignmentCustomVehicle[];
    currentTeamStatus?: IPlannedAssignmentTeamHistory;

    //endregion "Relations"
}

export interface IPlannedAssignmentTeamHistory {
    teamArrival: Date;
    teamDeparture: Date;
}

export interface IPlannedAssignmentCustomTeamMember {
    workerId: number;
    worker: IWorkerBase;

    plannedAssignmentId: number;
    plannedAssignment: IPlannedAssignment;
}

export interface IPlannedAssignmentCustomVehicle {
    deviceId: number;
    device: IDevice;

    plannedAssignmentId: number;
    plannedAssignment: IPlannedAssignment;
}

export class PlannedAssignmentCreator {
    safetyQuestionsChecked: boolean;
    displayOrder: number;

    plannedEventId?: number; // can be null if we are creating completely new PlannedEvent with new PlannedWorkers
    assignmentId: number;

    checkInAtWorkLastUpdate?: Date;
    checkInAtWorkChecked?: boolean;

    note: string;
    constructor(plannedAssignment: IPlannedAssignment) {
        this.safetyQuestionsChecked = plannedAssignment.safetyQuestionsChecked;
        this.displayOrder = plannedAssignment.displayOrder;
        this.plannedEventId = plannedAssignment.plannedEventId;
        this.assignmentId = plannedAssignment.assignmentId;
        this.note = plannedAssignment.note;

        this.checkInAtWorkChecked = plannedAssignment.checkInAtWorkChecked;
        this.checkInAtWorkLastUpdate = plannedAssignment.checkInAtWorkLastUpdate;
    }
}

export class PlannedAssignmentUpdater extends PlannedAssignmentCreator {
    constructor(plannedAssignment: IPlannedAssignment) {
        super(plannedAssignment);
        this.id = plannedAssignment.id;
    }
    id: number;
}

export interface IPlannedAssignmentCustomTeamMember {
    workerId: number;
    displayOrder: number;
}

export interface IPlannedAssignmentCustomTeam {
    teamMembers: IPlannedAssignmentCustomTeamMember[];
}

export interface IPlannedAssignmentCustomVehicles {
    vehicles: number[];
}
