<div
    class="card--task"
    [style.--status-color]="getStatusColor()"
>
    <div class="card__header flex justify-content-between align-items-center">
        <div class="flex align-items-center gap-16">
            <h2>{{ task().name }}</h2>

            @if (task()?.currentStatus?.isMachineWork) {
                <m-svg
                    iconName="bulldozer"
                    style="--icon-width: 2.4rem; --icon-height: 2.4rem; --icon-fill: var(--color-neutral500)"
                />
            }
        </div>

        <div class="flex align-items-center gap-8">
            <button
                class="btn--icon"
                (click)="navigateToTaskEdit()"
            >
                @if (siteActive && editableAssignment) {
                    <m-svg iconName="edit" />
                } @else {
                    <m-svg iconName="eye-solid" />
                }
            </button>
            @if (siteActive && editableAssignment) {
                <button
                    class="btn--icon"
                    (click)="copyTask()"
                >
                    <m-svg iconName="copy" />
                </button>
                <button
                    class="btn--icon"
                    (click)="deleteTask()"
                >
                    <m-svg iconName="delete" />
                </button>
            }
        </div>
    </div>

    <div class="card__body">
        <div
            class="data-list"
            style="--data-list-columns: 2"
        >
            <dl>
                <dt>{{ "taskPage.type" | translate }}</dt>
                <dd>{{ task().currentStatus.taskTypeId | translateEnum: "taskType" }}</dd>
            </dl>
            @if (task().currentStatus.signMaterialId) {
                <dl>
                    <dt>{{ "taskPage.material" | translate }}</dt>
                    <dd>{{ task().currentStatus.signMaterialId | translateEnum: "signMaterial" }}</dd>
                </dl>
            }

            @if (task().currentStatus.signCategoryId && task().currentStatus.signMaterialId) {
                <dl>
                    <dt>{{ "taskPage.mainCategory" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.signCategoryId | translateEnum: "signCategoryValue" }}
                        @if (task().currentStatus.signCategoryLevel2Id) {
                            - {{ task().currentStatus.signCategoryLevel2Id | translateEnum: "signCategoryValue" }}
                        }
                        @if (task().currentStatus.signCategoryLevel3Id) {
                            - {{ task().currentStatus.signCategoryLevel3Id | translateEnum: "signCategoryValue" }}
                        }
                        @if (task().currentStatus.signCategoryLevel4Id) {
                            - {{ task().currentStatus.signCategoryLevel4Id | translateEnum: "signCategoryValue" }}
                        }
                    </dd>
                </dl>
            }
            @if (showCustomSignColor) {
                <dl>
                    <dt>{{ "taskPage.color" | translate }}</dt>
                    <dd>
                        <div
                            class="color-box"
                            [style.backgroundColor]="task().currentStatus.customSignColor"
                        >
                            &nbsp;
                        </div>
                    </dd>
                </dl>
            }

            @if (
                this.task().currentStatus.propertyConfiguration.showSignColorList && task().currentStatus.signColorId
            ) {
                <dl>
                    <dt>{{ "taskPage.signColor" | translate }}</dt>
                    <dd>{{ task().currentStatus.signColorId | translateEnum: "signColorType" }}</dd>
                </dl>
            }
            <dl>
                <dt>{{ "taskPage.isNew" | translate }}</dt>
                <dd>{{ (task().currentStatus.isNew ? "taskPage.yes" : "taskPage.no") | translate }}</dd>
            </dl>
            <dl>
                <dt>{{ "taskPage.address" | translate }}</dt>
                <dd>
                    {{ getAddress() }}
                </dd>
            </dl>

            <dl>
                <dt>{{ "taskPage.start" | translate }}</dt>
                <dd>{{ task().start | momentFormat: "D MMM y" }}</dd>
            </dl>

            @if (task().end) {
                <dl>
                    <dt>{{ "taskPage.end" | translate }}</dt>
                    <dd>{{ task().end | momentFormat: "D MMM y" }}</dd>
                </dl>
            }

            @if (task().currentStatus.signCategoryId) {
                <dl>
                    <dt>{{ "taskPage.signCategory" | translate }}</dt>
                    <dd>{{ task().currentStatus.signCategoryId | translateEnum: "signCategoryValue" }}</dd>
                </dl>
            }

            @if (task().currentStatus.signCategoryLevel2Id) {
                <dl>
                    <dt>{{ "taskPage.signCategoryLevel2" | translate }}</dt>
                    <dd>{{ task().currentStatus.signCategoryLevel2Id | translateEnum: "signCategoryValue" }}</dd>
                </dl>
            }

            @if (task().currentStatus.signCategoryLevel3Id) {
                <dl>
                    <dt>{{ "taskPage.signCategoryLevel3" | translate }}</dt>
                    <dd>{{ task().currentStatus.signCategoryLevel3Id | translateEnum: "signCategoryValue" }}</dd>
                </dl>
            }

            @if (task().currentStatus.signCategoryLevel4Id) {
                <dl>
                    <dt>{{ "taskPage.signCategoryLevel4" | translate }}</dt>
                    <dd>{{ task().currentStatus.signCategoryLevel4Id | translateEnum: "signCategoryValue" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showMarkingsCount) {
                <dl>
                    <dt>{{ "taskPage.count" | translate }}</dt>
                    <dd>{{ task().currentStatus.markingsCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showLength) {
                <dl>
                    <dt>{{ "taskPage.length" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.length }}
                        {{ task().currentStatus.lengthUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showWidth) {
                <dl>
                    <dt>{{ "taskPage.width" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.width }} {{ task().currentStatus.widthUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showHeight) {
                <dl>
                    <dt>{{ "taskPage.height" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.height }}
                        {{ task().currentStatus.heightUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showColoredLength) {
                <dl>
                    <dt>{{ "taskPage.coloredLength" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.coloredLength }}
                        {{ task().currentStatus.coloredLengthUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showColoredWidth) {
                <dl>
                    <dt>{{ "taskPage.coloredWidth" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.coloredWidth }}
                        {{ task().currentStatus.coloredWidthUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showType) {
                <dl>
                    <dt>{{ "taskPage.type" | translate }}</dt>
                    <dd>{{ task().currentStatus.type | translateEnum: "markingPropertyType" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showDimension) {
                <dl>
                    <dt>{{ "taskPage.dimension" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.dimensionLength }} x {{ task().currentStatus.dimensionWidth }}
                        {{ task().currentStatus.dimensionUnitId | translateEnum: "unit" }}
                        {{ task().currentStatus.dimensionDescription }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showDiameter) {
                <dl>
                    <dt>{{ "taskPage.diameter" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.diameter }}
                        {{ task().currentStatus.diameterUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showText) {
                <dl>
                    <dt>{{ "taskPage.text" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.text ?? "/" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showSpeed) {
                <dl>
                    <dt>{{ "taskPage.speed" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.speed ?? "/" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showPlacenumber) {
                <dl>
                    <dt>{{ "taskPage.placeNumber" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.placenumber ?? "/" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showBlock1Amount) {
                <dl>
                    <dt>{{ "taskPage.block1Amount" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.block1Amount ?? "/" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showBlock2Amount) {
                <dl>
                    <dt>{{ "taskPage.block2Amount" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.block2Amount ?? "/" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showHoursCount) {
                <dl>
                    <dt>{{ "taskPage.hoursCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.hoursCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showBlock1Width) {
                <dl>
                    <dt>{{ "taskPage.block1Width" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.block1Width }}
                        {{ task().currentStatus.blocksWidthUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showBlock2Width) {
                <dl>
                    <dt>{{ "taskPage.block2Width" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.block2Width }}
                        {{ task().currentStatus.blocksWidthUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showLogoCount) {
                <dl>
                    <dt>{{ "taskPage.logoCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.logoCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showBannerCount) {
                <dl>
                    <dt>{{ "taskPage.bannerCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.bannerCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showTRAMElementCount) {
                <dl>
                    <dt>{{ "taskPage.tramElementCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.tramElementCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showTElementCount) {
                <dl>
                    <dt>{{ "taskPage.tElementCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.tElementCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showTElementDimension) {
                <dl>
                    <dt>{{ "taskPage.tElementDimension" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.tElementLength }} x {{ task().currentStatus.tElementWidth }}
                        {{ task().currentStatus.tElementUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showTElementCount) {
                <dl>
                    <dt>{{ "taskPage.lElementCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.lElementCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showTElementDimension) {
                <dl>
                    <dt>{{ "taskPage.lElementDimension" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.lElementLength }} x {{ task().currentStatus.lElementWidth }}
                        {{ task().currentStatus.lElementUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showLengthA) {
                <dl>
                    <dt>{{ "taskPage.lengthA" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.lengthA }}
                        {{ task().currentStatus.lengthABCDUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showLengthB) {
                <dl>
                    <dt>{{ "taskPage.lengthB" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.lengthB }}
                        {{ task().currentStatus.lengthABCDUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showLengthC) {
                <dl>
                    <dt>{{ "taskPage.lengthC" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.lengthC }}
                        {{ task().currentStatus.lengthABCDUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showLengthD) {
                <dl>
                    <dt>{{ "taskPage.lengthD" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.lengthD }}
                        {{ task().currentStatus.lengthABCDUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showBendCount) {
                <dl>
                    <dt>{{ "taskPage.bendCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.bendCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showArrowHeadCount) {
                <dl>
                    <dt>{{ "taskPage.arrowHeadCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.arrowHeadCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showRoundedEndCount) {
                <dl>
                    <dt>{{ "taskPage.roundedEndCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.roundedEndCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showShortPieceCount) {
                <dl>
                    <dt>{{ "taskPage.shortPieceCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.shortPieceCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showWhiteSubstrateLength) {
                <dl>
                    <dt>{{ "taskPage.whiteSubstrateLength" | translate }}</dt>
                    <dd>
                        {{ task().currentStatus.whiteSubstrateLength }}
                        {{ task().currentStatus.whiteSubstrateLengthUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showWhiteSubstrateWidth) {
                <dl>
                    <dt>{{ "taskPage.whiteSubstrateWidth" }}</dt>
                    <dd>
                        {{ task().currentStatus.whiteSubstrateWidth }}
                        {{ task().currentStatus.whiteSubstrateWidthUnitId | translateEnum: "unit" }}
                    </dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showWhiteBandCount) {
                <dl>
                    <dt>{{ "taskPage.whiteBandCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.whiteBandCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showRedBandCount) {
                <dl>
                    <dt>{{ "taskPage.redBandCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.redBandCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showYellowBandCount) {
                <dl>
                    <dt>{{ "taskPage.yellowBandCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.yellowBandCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showOrangeBandCount) {
                <dl>
                    <dt>{{ "taskPage.orangeBandCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.orangeBandCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showGreenBandCount) {
                <dl>
                    <dt>{{ "taskPage.greenBandCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.greenBandCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showBlueBandCount) {
                <dl>
                    <dt>{{ "taskPage.blueBandCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.blueBandCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showPurpleBandCount) {
                <dl>
                    <dt>{{ "taskPage.purpleBandCount" | translate }}</dt>
                    <dd>{{ task().currentStatus.purpleBandCount ?? "/" }}</dd>
                </dl>
            }

            @if (this.task().currentStatus.propertyConfiguration.showNote) {
                <dl>
                    <dt>{{ "taskPage.note" | translate }}</dt>
                    <dd>{{ task().currentStatus.note ?? "/" }}</dd>
                </dl>
            }
        </div>
    </div>
</div>
