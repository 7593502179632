import { inject, Injectable } from "@angular/core";
import { AttachmentApi } from "@ramudden/data-access/resource/attachment.api";
import { ParkingBanExceptionApi } from "@ramudden/data-access/resource/parking-ban-exception.api";
import { ParkingBanApi } from "@ramudden/data-access/resource/parking-ban.api";
import { SignScanApi } from "@ramudden/data-access/resource/sign-scan.api";
import { AttachmentCreator, AttachmentUpdater, IAttachment } from "@ramudden/models/attachment";
import { ICoordinate, ILocation, LocationCreator } from "@ramudden/models/location";
import { IParkingBan, ParkingBanCreator, ParkingBanUpdater } from "@ramudden/models/parking-ban";
import {
    IParkingBanException,
    ParkingBanExceptionCreator,
    ParkingBanExceptionUpdater,
} from "@ramudden/models/parking-ban-exception";
import { FilterDescriptor, FilterOperator, SearchParameters, ServiceRequestOptions } from "@ramudden/models/search";
import { ISignScan, SignScanCreator, SignScanUpdater } from "@ramudden/models/sign-scan";
import { ImageConverterService } from "@ramudden/services";
import { firstValueFrom } from "rxjs";
import { LoaderService } from "../services/loader.service";

@Injectable({
    providedIn: "root",
})
export class ParkingBanService {
    private readonly attachmentApi = inject(AttachmentApi);
    private readonly imageConverter = inject(ImageConverterService);
    private readonly loaderService = inject(LoaderService);
    private readonly parkingBanApi = inject(ParkingBanApi);
    private readonly parkingBanExceptionApi = inject(ParkingBanExceptionApi);
    private readonly signScanApi = inject(SignScanApi);

    //region Parking bans
    public async createParkingBan(parkingBan: IParkingBan): Promise<IParkingBan> {
        const creator = Object.assign(new ParkingBanCreator(), parkingBan);
        return await firstValueFrom(this.parkingBanApi.create$(creator));
    }

    public async updateParkingBan(parkingBan: IParkingBan): Promise<IParkingBan> {
        const updater = new ParkingBanUpdater().init(parkingBan);
        return await firstValueFrom(this.parkingBanApi.update$(updater));
    }

    public async deleteParkingBan(parkingBanId: number): Promise<void> {
        await firstValueFrom(this.parkingBanApi.delete$(parkingBanId));
    }

    public async deleteParkingBanAttachment(attachmentId: number) {
        if (attachmentId <= 0) return;

        await firstValueFrom(this.attachmentApi.delete$(attachmentId));
    }

    public async createParkingBanAttachment(
        parkingBan: IParkingBan,
        dataUrl: string,
        description: string,
    ): Promise<IAttachment> {
        const webpDataUrl = await this.imageConverter.convertToWebP(dataUrl);
        const imageName = `${parkingBan.id}_${new Date().getTime()}.webp`.replace(/ /g, "_");
        const image = this.dataURLtoFile(webpDataUrl, imageName);

        const creator = new AttachmentCreator();
        creator.description = description;
        creator.parkingBanId = parkingBan.id;
        creator.name = imageName;
        creator.typeId = 3;

        return await firstValueFrom(this.attachmentApi.upload$(creator, image));
    }

    public async updateParkingBanAttachment(attachment: IAttachment, parkingBanId: number): Promise<IAttachment> {
        const updater = new AttachmentUpdater(attachment);
        updater.parkingBanId = parkingBanId;
        return await firstValueFrom(this.attachmentApi.update$(updater));
    }

    public async getAllParkingBans(assignmentId: number): Promise<IParkingBan[]> {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("ParkingBan", "Location");
        serviceRequestOptions.includes.add("ParkingBan", "Exceptions");

        const searchParameters = new SearchParameters();
        searchParameters.filter = [new FilterDescriptor("Assignment", assignmentId, FilterOperator.equals)];

        return await firstValueFrom(this.parkingBanApi.getAll$(searchParameters, null, serviceRequestOptions));
    }

    public async getParkingBan(parkingBanId: number): Promise<IParkingBan> {
        const serviceRequestOptions = new ServiceRequestOptions();
        serviceRequestOptions.includes.add("Attachment", "Type");
        serviceRequestOptions.includes.add("ParkingBan", "Assignment");
        serviceRequestOptions.includes.add("ParkingBan", "Exceptions");
        serviceRequestOptions.includes.add("ParkingBan", "Location");
        serviceRequestOptions.includes.add("ParkingBan", "Photos");
        serviceRequestOptions.includes.add("ParkingBan", "SignScans");
        serviceRequestOptions.includes.add("ParkingBanException", "Location");
        serviceRequestOptions.includes.add("ParkingBanException", "Photos");
        serviceRequestOptions.includes.add("SignScan", "Location");
        serviceRequestOptions.includes.add("SignScan", "Photos");

        this.loaderService.show();
        return await firstValueFrom(this.parkingBanApi.get$(parkingBanId, null, serviceRequestOptions)).finally(() =>
            this.loaderService.hide(),
        );
    }
    //endregion

    //region Sign scans
    public async createParkingBanSignScan(
        parkingBan: IParkingBan,
        organizationId: number,
        coordinate: ICoordinate,
        qrCode: string,
    ): Promise<ISignScan> {
        const locationCreator = new LocationCreator();
        locationCreator.coordinate = coordinate;
        locationCreator.ownerId = organizationId;

        const signScanCreator = new SignScanCreator({
            name: qrCode,
            signCode: qrCode,
            checkIn: new Date(),
            parkingBan: parkingBan,
            location: locationCreator,
        } as ISignScan);

        return await firstValueFrom(this.signScanApi.create$(signScanCreator));
    }

    public async deleteParkingBanSignScan(signScanId: number): Promise<void> {
        if (signScanId <= 0) return;

        await firstValueFrom(this.signScanApi.delete$(signScanId));
    }

    public async updateParkingBanSignScan(signScan: ISignScan, parkingBanId: number): Promise<ISignScan> {
        const signScanUpdater = new SignScanUpdater(signScan);
        signScanUpdater.parkingBanId = parkingBanId;
        return await firstValueFrom(this.signScanApi.update$(signScanUpdater));
    }
    //endregion

    //region Exceptions

    public async createParkingBanExceptionWithAttachment(
        parkingBan: IParkingBan,
        organizationId: number,
        dataUrl: string,
        location?: ILocation,
        coordinate?: ICoordinate,
    ): Promise<IParkingBanException> {
        const locationCreator = new LocationCreator();
        locationCreator.coordinate = coordinate ?? location?.coordinate ?? parkingBan.location.coordinate;
        locationCreator.ownerId = organizationId;
        locationCreator.code = organizationId + "_PBE";

        const pbeCreator = new ParkingBanExceptionCreator();
        pbeCreator.parkingBanId = parkingBan.id;
        pbeCreator.timestamp = new Date();
        pbeCreator.location = locationCreator;

        const webpDataUrl = await this.imageConverter.convertToWebP(dataUrl);
        const imageName = `PBE_${new Date().getTime()}.webp`.replace(/ /g, "_");
        const file = this.dataURLtoFile(webpDataUrl, imageName);

        const creator = new AttachmentCreator();
        creator.name = imageName;
        creator.typeId = 3;

        return await firstValueFrom(this.parkingBanExceptionApi.createWithAttachment$(creator, pbeCreator, file));
    }

    public async deleteParkingBanException(parkingBanExceptionId: number): Promise<void> {
        if (parkingBanExceptionId <= 0) return;

        await firstValueFrom(this.parkingBanExceptionApi.delete$(parkingBanExceptionId));
    }

    public async updateParkingBanException(
        exception: IParkingBanException,
        parkingBan: IParkingBan,
    ): Promise<IParkingBanException> {
        const exceptionUpdater = new ParkingBanExceptionUpdater(exception);
        exceptionUpdater.parkingBanId = parkingBan.id;
        exceptionUpdater.locationId = parkingBan.location.id;
        return await firstValueFrom(this.parkingBanExceptionApi.update$(exceptionUpdater));
    }

    public async moveParkingBan(parkingBanId: number, start: Date): Promise<IParkingBan> {
        return await firstValueFrom(this.parkingBanApi.move$(parkingBanId, start));
    }
    //endregion

    private dataURLtoFile(dataUrl: string, fileName: string) {
        const arr = dataUrl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, { type: mime });
    }
}
