export interface ModalConfig {
    canClose?: boolean;
    title: string;
    body: string;
    type: "info" | "error" | "delete" | "alert";
    outsideClickAction?: ModalConfigAction;
    actions: ModalConfigAction[];
    doNotShowAgain?: boolean;
}

export interface ModalConfigAction {
    label: string;
    handler?: (() => void) | (() => Promise<void>);
    class?: string[];
}
