import { Injectable, isDevMode } from "@angular/core";
import { Subject } from "rxjs";

abstract class StorageService {
    storageSubject$ = new Subject<{ key: string; value: string }>();

    getItem(key: string): string {
        return this.get().getItem(key);
    }

    setItem(key: string, value: string) {
        try {
            this.storageSubject$.next({ key, value });
            this.get().setItem(key, value);
        } catch (ex) {
            if (isDevMode()) {
                console.error(`Couldn't set storage for key ${key}`);
            }
        }
    }

    removeItem(key: string) {
        this.storageSubject$.next({ key, value: null });
        this.get().removeItem(key);
    }

    clear() {
        this.get().clear();
    }

    getAll(): { key: string; value: string }[] {
        const storage = this.get();

        const items = new Array<{ key: string; value: string }>();

        for (const x in storage) {
            if (storage.hasOwnProperty(x)) {
                items.push({ key: x, value: storage[x] });
            }
        }

        return items;
    }

    private get(): Storage {
        if (typeof window !== "undefined") {
            return this.getStorage();
        }

        return new Storage();
    }

    protected abstract getStorage(): Storage;
}

@Injectable({
    providedIn: "root",
})
export class SessionStorageService extends StorageService {
    protected getStorage(): Storage {
        return sessionStorage;
    }
}

@Injectable({
    providedIn: "root",
})
export class LocalStorageService extends StorageService {
    protected getStorage(): Storage {
        return localStorage;
    }
}
