import { inject, Injectable } from "@angular/core";
import { LocationApi } from "@ramudden/data-access/resource/location.api";
import { IAddress, IAddressWithTimeZone, ICoordinate } from "@ramudden/models/location";
import { firstValueFrom } from "rxjs";

@Injectable({ providedIn: "root" })
export class LocationService {
    private readonly locationApi = inject(LocationApi);
    isRetrievingLocation = false;

    async getCurrentCoordinates(): Promise<ICoordinate> {
        if (!navigator.geolocation) {
            throw new Error("Geolocation is not supported by this browser.");
        }
        this.isRetrievingLocation = true;
        return new Promise<ICoordinate>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const coordinates: ICoordinate = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    };
                    resolve(coordinates);
                },
                (error) => {
                    reject(error);
                },
                { enableHighAccuracy: false, timeout: 3000, maximumAge: 0 },
            );
        }).finally(() => {
            this.isRetrievingLocation = false;
        });
    }

    convertToAddressString(address: IAddress, useCountry = false): string {
        return `${address.line1}, ${address.zipCode} ${address.city}${useCountry ? `, ${address.country}` : ""}`;
    }

    async getAddressFromCoordinates(coordinates: ICoordinate): Promise<IAddressWithTimeZone> {
        return await firstValueFrom(this.locationApi.getAddressFromCoordinates$(coordinates));
    }

    async getCoordinatesFromAddress(address: IAddress): Promise<ICoordinate> {
        return await firstValueFrom(this.locationApi.getCoordinatesFromAddress$(address));
    }
}
