<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<content-default>
    <ng-container body>
        <h2 class="mb-24">{{ "assignmentPage.title" | translate }}</h2>

        @if (plannedEventForm) {
            <form
                class="flex flex-column gap-16"
                [formGroup]="plannedEventForm"
            >
                <!--region Header-->
                <div class="flex align-items-center justify-content-between gap-16">
                    <div class="flex align-items-center gap-8">
                        <button
                            class="btn--yellow__reverse"
                            [disabled]="siteActive || !editableAssignment"
                            (click)="startSite()"
                        >
                            {{ "assignmentPage.startSite" | translate }}
                        </button>
                        <button
                            class="btn--yellow__reverse"
                            [disabled]="siteInactive || !siteActive || !editableAssignment"
                            (click)="endSite()"
                        >
                            {{ "assignmentPage.stopSite" | translate }}
                        </button>
                        @if (siteActive) {
                            <m-svg
                                class="ml-8"
                                iconName="hourglass"
                                style="animation: inProgress 1s infinite"
                            />
                        }
                    </div>

                    <div class="flex align-items-center gap-8">
                        <label for="isFinished"> {{ "assignmentPage.isFinished" | translate }} </label>

                        <label
                            class="input--toggle"
                            for="isFinished"
                        >
                            <input
                                id="isFinished"
                                (change)="toggleFinished($event)"
                                formControlName="isFinished"
                                type="checkbox"
                            />
                            <span></span>
                        </label>
                    </div>
                </div>
                <!--endregion-->

                <!--region General Form-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "assignmentPage.assignment" | translate }}</h3>
                    </div>

                    <div class="card__body">
                        <div
                            class="form__fields"
                            formGroupName="generalForm"
                        >
                            <!--region Description-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="description"
                                >
                                    {{ "assignmentPage.description" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        id="description"
                                        formControlName="description"
                                        placeholder=""
                                        type="text"
                                    />
                                </div>
                            </div>
                            <!--endregion-->

                            <!--region Remarks-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="remarks"
                                >
                                    {{ "assignmentPage.remarks" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        id="remarks"
                                        (change)="updateRemarks()"
                                        formControlName="remarks"
                                        placeholder=""
                                        type="text"
                                    />
                                </div>
                            </div>
                            <!--endregion-->

                            <!--region Task type-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="taskType"
                                >
                                    {{ "assignmentPage.taskType" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        id="taskType"
                                        formControlName="taskType"
                                        placeholder=""
                                        type="text"
                                    />
                                </div>
                            </div>
                            <!--endregion-->

                            <!--region Material-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="material"
                                >
                                    {{ "assignmentPage.material" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        id="material"
                                        formControlName="material"
                                        placeholder=""
                                        type="text"
                                    />
                                </div>
                            </div>
                            <!--endregion-->

                            <!--region Is machine work-->
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="isMachineWork"
                                >
                                    {{ "assignmentPage.isMachineWork" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <label
                                        class="input--toggle"
                                        for="isMachineWork"
                                    >
                                        <input
                                            id="isMachineWork"
                                            formControlName="isMachineWork"
                                            type="checkbox"
                                        />
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                            <!--endregion-->
                        </div>
                    </div>
                </div>
                <!--endregion-->

                <!--region Configuration Form-->
                @if (editableAssignment) {
                    <div class="card">
                        <div class="card__header">
                            <h3>{{ "assignmentPage.team" | translate }}</h3>
                        </div>

                        <div class="card__body">
                            <div class="form__fields">
                                <div class="form__field grid">
                                    <label
                                        class="grid__item-4"
                                        for="team"
                                    >
                                        {{ "assignmentPage.team" | translate }}
                                    </label>

                                    <div
                                        class="grid__item-8"
                                        (click)="teamDialog.openModal(plannedAssignment?.customTeam)"
                                    >
                                        <input
                                            class="input--text"
                                            id="team"
                                            [value]="teamMembers"
                                            readonly
                                            type="text"
                                        />
                                    </div>
                                </div>

                                <div class="form__field grid">
                                    <label
                                        class="grid__item-4"
                                        for="vehicle"
                                    >
                                        {{ "assignmentPage.vehicles" | translate }}
                                    </label>

                                    <div
                                        class="grid__item-8"
                                        (click)="
                                            editableAssignment &&
                                                vehicleDialog.openModal(plannedAssignment?.customVehicles)
                                        "
                                    >
                                        <input
                                            class="input--text"
                                            id="vehicle"
                                            [value]="vehicles"
                                            readonly
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <!--endregion-->

                <!--region Administrative responsible Form-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "assignmentPage.administrativeResponsible" | translate }}</h3>
                    </div>

                    <div class="card__body">
                        <div
                            class="form__fields"
                            formGroupName="administrativeResponsibleForm"
                        >
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="administrativeResponsibleFormName"
                                >
                                    {{ "assignmentPage.name" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        id="administrativeResponsibleFormName"
                                        formControlName="name"
                                        placeholder=""
                                        type="text"
                                    />
                                </div>
                            </div>

                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="administrativeResponsibleFormEmail"
                                >
                                    {{ "assignmentPage.email" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        id="administrativeResponsibleFormEmail"
                                        formControlName="email"
                                        placeholder=""
                                        type="text"
                                    />
                                </div>
                            </div>

                            @if (plannedEventForm.get("administrativeResponsibleForm").get("phoneNumber").value) {
                                <div class="form__field grid">
                                    <label
                                        class="grid__item-4"
                                        for="administrativeResponsibleFormPhoneNumber"
                                    >
                                        {{ "assignmentPage.phoneNumber" | translate }}
                                    </label>

                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="administrativeResponsibleFormPhoneNumber"
                                            formControlName="phoneNumber"
                                            placeholder=""
                                            type="text"
                                        />
                                    </div>
                                </div>
                            }

                            @if (plannedEventForm.get("administrativeResponsibleForm").get("mobilePhone").value) {
                                <div class="form__field grid">
                                    <label
                                        class="grid__item-4"
                                        for="administrativeResponsibleFormMobilePhone"
                                    >
                                        {{ "assignmentPage.mobilePhone" | translate }}
                                    </label>

                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="administrativeResponsibleFormMobilePhone"
                                            formControlName="mobilePhone"
                                            placeholder=""
                                            type="text"
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <!--endregion-->

                <!--region Responsible client Form-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "assignmentPage.responsibleClient" | translate }}</h3>
                    </div>

                    <div class="card__body">
                        <div
                            class="form__fields"
                            formGroupName="responsibleClientForm"
                        >
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="responsibleClientFormName"
                                >
                                    {{ "assignmentPage.name" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        id="responsibleClientFormName"
                                        formControlName="name"
                                        placeholder=""
                                        type="text"
                                    />
                                </div>
                            </div>

                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="responsibleClientFormEmail"
                                >
                                    {{ "assignmentPage.email" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        id="responsibleClientFormEmail"
                                        formControlName="email"
                                        placeholder=""
                                        type="text"
                                    />
                                </div>
                            </div>

                            @if (plannedEventForm.get("responsibleClientForm").get("phoneNumber").value) {
                                <div class="form__field grid">
                                    <label
                                        class="grid__item-4"
                                        for="responsibleClientFormPhoneNumber"
                                    >
                                        {{ "assignmentPage.phoneNumber" | translate }}
                                    </label>

                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="responsibleClientFormPhoneNumber"
                                            formControlName="phoneNumber"
                                            placeholder=""
                                            type="text"
                                        />
                                    </div>
                                </div>
                            }

                            @if (plannedEventForm.get("responsibleClientForm").get("mobilePhone").value) {
                                <div class="form__field grid">
                                    <label
                                        class="grid__item-4"
                                        for="responsibleClientFormMobilePhone"
                                    >
                                        {{ "assignmentPage.mobilePhone" | translate }}
                                    </label>

                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="responsibleClientFormMobilePhone"
                                            formControlName="mobilePhone"
                                            placeholder=""
                                            type="text"
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <!--endregion-->

                <!--region Executive client Form-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "assignmentPage.executiveResponsible" | translate }}</h3>
                    </div>

                    <div class="card__body">
                        <div
                            class="form__fields"
                            formGroupName="executiveResponsibleForm"
                        >
                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="executiveResponsibleFormName"
                                >
                                    {{ "assignmentPage.name" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        id="executiveResponsibleFormName"
                                        formControlName="name"
                                        placeholder=""
                                        type="text"
                                    />
                                </div>
                            </div>

                            <div class="form__field grid">
                                <label
                                    class="grid__item-4"
                                    for="executiveResponsibleFormEmail"
                                >
                                    {{ "assignmentPage.email" | translate }}
                                </label>

                                <div class="grid__item-8">
                                    <input
                                        class="input--text"
                                        id="executiveResponsibleFormEmail"
                                        formControlName="email"
                                        placeholder=""
                                        type="text"
                                    />
                                </div>
                            </div>

                            @if (plannedEventForm.get("executiveResponsibleForm").get("phoneNumber").value) {
                                <div class="form__field grid">
                                    <label
                                        class="grid__item-4"
                                        for="executiveResponsibleFormPhoneNumber"
                                    >
                                        {{ "assignmentPage.phoneNumber" | translate }}
                                    </label>

                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="executiveResponsibleFormPhoneNumber"
                                            formControlName="phoneNumber"
                                            placeholder=""
                                            type="text"
                                        />
                                    </div>
                                </div>
                            }

                            @if (plannedEventForm.get("executiveResponsibleForm").get("mobilePhone").value) {
                                <div class="form__field grid">
                                    <label
                                        class="grid__item-4"
                                        for="executiveResponsibleFormMobilePhone"
                                    >
                                        {{ "assignmentPage.mobilePhone" | translate }}
                                    </label>

                                    <div class="grid__item-8">
                                        <input
                                            class="input--text"
                                            id="executiveResponsibleFormMobilePhone"
                                            formControlName="mobilePhone"
                                            placeholder=""
                                            type="text"
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <!--endregion-->

                <!--region Attachments-->
                <div class="card">
                    <div class="card__header">
                        <h3>{{ "assignmentPage.attachments" | translate }}</h3>
                    </div>

                    <div class="card__body">
                        <div class="attachments">
                            @for (attachment of attachments; track $index) {
                                @if (fileIsPdf(attachment.url)) {
                                    <div
                                        class="attachment"
                                        (click)="pdfViewer.openModal(attachment.name, attachment.url, $event)"
                                    >
                                        <a
                                            class="attachment__download btn--icon"
                                            [href]="attachment.url"
                                            (click)="$event.stopPropagation()"
                                        >
                                            <m-svg iconName="download" />
                                        </a>
                                        <div class="attachment__icon">
                                            <m-svg iconName="file-pdf-light" />
                                        </div>

                                        <div class="attachment__body">
                                            <div
                                                class="clamp"
                                                style="--clamp-line-clamp: 2"
                                            >
                                                <small class="flex mb-8">{{ attachment.type.code }}</small>
                                                {{ attachment.name }}
                                            </div>
                                        </div>
                                    </div>
                                } @else if (fileIsImage(attachment.url)) {
                                    <div
                                        class="attachment"
                                        (click)="openGalleria(attachment)"
                                    >
                                        <a
                                            class="attachment__download btn--icon"
                                            [href]="attachment.url"
                                            (click)="$event.stopPropagation()"
                                        >
                                            <m-svg iconName="download" />
                                        </a>
                                        <img
                                            class="attachment__image"
                                            [alt]="attachment.name"
                                            [src]="attachment.url"
                                        />

                                        <div class="attachment__body">
                                            <div
                                                class="clamp"
                                                style="--clamp-line-clamp: 2"
                                            >
                                                <small class="flex mb-8">{{ attachment.type.code }}</small>
                                                {{ attachment.name }}
                                            </div>
                                        </div>
                                    </div>
                                } @else {
                                    <div class="attachment">
                                        <a
                                            class="attachment__download btn--icon"
                                            [href]="attachment.url"
                                            (click)="$event.stopPropagation()"
                                        >
                                            <m-svg iconName="download" />
                                        </a>
                                        <div class="attachment__icon">
                                            <m-svg iconName="file" />
                                        </div>

                                        <div class="attachment__body">
                                            <a
                                                [href]="attachment.url"
                                                (click)="$event.stopPropagation()"
                                            >
                                                <div
                                                    class="clamp"
                                                    style="--clamp-line-clamp: 2"
                                                >
                                                    <small class="flex mb-8">{{ attachment.type.code }}</small>
                                                    {{ attachment.name }}
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                }
                            }
                        </div>
                    </div>
                </div>
                <!--endregion-->
            </form>
        }
    </ng-container>
</content-default>

<app-pdf-viewer-dialog #pdfViewer />
<app-vehicle-dialog
    #vehicleDialog
    [allowClose]="true"
    [usedInSetup]="false"
    (nextClicked)="onVehicleDialogClosed($event)"
/>
<app-team-dialog
    #teamDialog
    [allowClose]="true"
    [showSplitTeam]="false"
    [usedInSetup]="false"
    (nextClicked)="onTeamDialogClosed($event)"
/>
