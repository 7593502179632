import { Component, inject, Input, OnInit, output } from "@angular/core";
import { ICoordinate } from "@ramudden/models/location";
import { ToastService } from "@ramudden/ui";
import { AssignmentService } from "../../services/assignment.service";

@Component({
    selector: "app-map",
    templateUrl: "./map.component.html",
    styleUrl: "./map.component.scss",
    standalone: true,
})
export class MapComponent implements OnInit {
    private readonly assignmentsService = inject(AssignmentService);
    private readonly toastService = inject(ToastService);

    @Input() coordinates: ICoordinate = undefined;
    coordinatesChanged = output<ICoordinate>();
    mapLoaded = output<void>();
    locationLoading = false;

    map: google.maps.Map;

    ngOnInit(): void {
        if (!this.coordinates) {
            this.getCurrentCoordinates();
        } else {
            this.initMap();
        }
    }

    async initMap(): Promise<void> {
        const { Map } = (await google.maps.importLibrary("maps")) as google.maps.MapsLibrary;

        if (!this.coordinates?.latitude || !this.coordinates?.longitude) {
            return;
        }

        this.map = new Map(document.getElementById("map") as HTMLElement, {
            clickableIcons: false,
            center: {
                lat: this.coordinates.latitude,
                lng: this.coordinates.longitude,
            },
            zoom: 15,
            mapId: "e1ad0500d0a22f4d",
        });

        this.mapLoaded.emit();
    }

    public async createMarker(
        coordinates: ICoordinate,
        title: string,
        draggable = false,
    ): Promise<google.maps.marker.AdvancedMarkerElement> {
        const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as google.maps.MarkerLibrary;

        return new AdvancedMarkerElement({
            position: {
                lat: coordinates.latitude,
                lng: coordinates.longitude,
            },
            map: this.map,
            title: title,
            gmpDraggable: draggable,
        });
    }

    public getCurrentCoordinates() {
        if ("geolocation" in navigator) {
            let callBackSuccess: PositionCallback;
            if (!this.coordinates) {
                callBackSuccess = (position: GeolocationPosition) => {
                    const coordinate = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    };

                    try {
                        this.coordinatesChanged.emit(coordinate);
                        this.coordinates = coordinate;
                        this.initMap();
                    } catch {
                        /* empty */
                    }
                    this.locationLoading = false;
                };
            } else {
                callBackSuccess = (position: GeolocationPosition) => {
                    const coordinate = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    };
                    try {
                        this.coordinatesChanged.emit(coordinate);
                        this.coordinates = coordinate;
                        this.map.setCenter({ lat: coordinate.latitude, lng: coordinate.longitude });
                    } catch {
                        /* empty */
                    }
                    this.locationLoading = false;
                };
            }

            const callBackError = (error: GeolocationPositionError) => {
                if (error.code === error.TIMEOUT) this.toastService.showWarning("location.usedDefault");
                if (error.code !== error.TIMEOUT) this.toastService.showWarning(error.message);

                const tempCoordinates = { ...this.coordinates };

                const coordinate = this.assignmentsService.selectedPlannedAssignment.assignment.location.coordinate;
                try {
                    this.coordinatesChanged.emit(coordinate);
                    this.coordinates = coordinate;

                    if (!tempCoordinates.latitude || !tempCoordinates.longitude) {
                        this.initMap();
                    }
                } catch {
                    /* empty */
                }
                this.locationLoading = false;
            };
            this.locationLoading = true;
            navigator.geolocation.getCurrentPosition(callBackSuccess, callBackError, {
                enableHighAccuracy: true,
                timeout: 3000,
                maximumAge: 0,
            });
        } else {
            const tempCoordinates = { ...this.coordinates };

            const coordinate = this.assignmentsService.selectedPlannedAssignment.assignment.location.coordinate;
            this.coordinatesChanged.emit(coordinate);
            this.coordinates = coordinate;

            if (!tempCoordinates.latitude || !tempCoordinates.longitude) {
                this.initMap();
            }
        }
    }
}
