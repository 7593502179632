import { NgClass } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SvgComponent } from "../svg/svg.component";
import { IconName } from "../svg/svg.model";
import { ToastMessage, ToastService } from "./toast.service";

@Component({
    selector: "m-toast",
    templateUrl: "./toast.component.html",
    styleUrls: ["./toast.component.scss"],
    standalone: true,
    imports: [NgClass, SvgComponent, TranslateModule],
})
export class ToastComponent implements OnInit {
    messages: ToastMessage[] = [];

    private readonly toastService = inject(ToastService);

    ngOnInit() {
        this.toastService.toastState$.subscribe((message: ToastMessage) => {
            this.messages.push(message);
            setTimeout(() => this.removeMessage(message), 3000);
        });
    }

    removeMessage(message: ToastMessage) {
        this.messages = this.messages.filter((msg) => msg !== message);
    }

    removeMessages() {
        this.messages = [];
    }

    getIconName(message: ToastMessage): IconName {
        if (message.type == "success") return "circle-success";
        if (message.type == "error") return "circle-error";
        if (message.type == "warning") return "circle-warning";
        return "circle-info";
    }
}
