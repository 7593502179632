import { Component, inject, Input, output, ViewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { LocationApi } from "@ramudden/data-access/resource/location.api";
import { ICoordinate, ILocation } from "@ramudden/models/location";
import { ModalComponent, ModalService, SvgComponent } from "@ramudden/ui";
import { firstValueFrom } from "rxjs";
import { MapComponent } from "../../../../../../components/map/map.component";
import { AssignmentService } from "../../../../../../services/assignment.service";
import { LoaderService } from "../../../../../../services/loader.service";
import { LocationService } from "../../../../../../services/location.service";

@Component({
    selector: "app-maps-dialog",
    standalone: true,
    templateUrl: "./maps-dialog.component.html",
    styleUrl: "./maps-dialog.component.scss",
    imports: [MapComponent, ModalComponent, SvgComponent, TranslateModule],
})
export class MapsDialogComponent {
    private readonly assignmentService = inject(AssignmentService);
    private readonly loaderService = inject(LoaderService);
    private readonly locationApi = inject(LocationApi);
    private readonly locationService = inject(LocationService);
    private readonly modalService = inject(ModalService);

    @Input({ required: true }) coordinates: ICoordinate;

    saveModal = output<ILocation>();

    @ViewChild(MapComponent) googleMap!: MapComponent;
    @ViewChild(ModalComponent) modal!: ModalComponent;

    marker: google.maps.marker.AdvancedMarkerElement;
    address: string;
    location: ILocation;

    open(event?: Event) {
        this.modal.openModal(event);
    }

    async onMapLoaded() {
        if (!this.googleMap.map || this.marker) return;

        this.marker = await this.googleMap.createMarker(this.googleMap.coordinates, "", true);
        this.marker.addListener("dragend", () => {
            this.onCoordinatesChanged({
                latitude: this.marker.position.lat,
                longitude: this.marker.position.lng,
            } as ICoordinate);
        });
        this.googleMap.map.addListener("click", (event: google.maps.MapMouseEvent) => {
            const callbackSucces = () => {
                const coordinates = {
                    latitude: event.latLng.lat(),
                    longitude: event.latLng.lng(),
                };
                this.marker.position = event.latLng;
                this.onCoordinatesChanged(coordinates);
            };

            const callbackCancel = () => {
                event.stop();
            };

            this.modalService.confirm(
                "Are you sure you want to change the coordinates?",
                callbackSucces,
                callbackCancel,
                "info",
                false,
            );
        });
    }

    async onCoordinatesChanged(coordinate: ICoordinate) {
        if (this.modal.modalOpen) {
            this.loaderService.show();
        }
        const addressWithTimeZone = await firstValueFrom(this.locationApi.getAddressFromCoordinates$(coordinate))
            .catch(() => {
                return { address: this.assignmentService.selectedPlannedAssignment.assignment.location.address };
            })
            .finally(() => {
                this.loaderService.hide();
            });
        this.location = {
            address: addressWithTimeZone.address,
            coordinate: coordinate,
        } as ILocation;

        this.address = this.locationService.convertToAddressString(this.location.address);
        this.coordinates = coordinate;
        if (google.maps.LatLng && this.marker) {
            this.marker.position = new google.maps.LatLng(coordinate.latitude, coordinate.longitude);
            this.googleMap.map.setCenter(this.marker.position);
        }
    }

    onSave() {
        this.saveModal.emit(this.location);
    }
}
