import { Component, ElementRef, HostListener, inject, output, ViewChild } from "@angular/core";
import { ToastService } from "../toast/toast.service";
import { PhotoInputFileLoadEnd } from "./photo-input.model";

@Component({
    selector: "m-photo-input",
    templateUrl: "./photo-input.component.html",
    styleUrls: ["./photo-input.component.scss"],
    standalone: true,
})
export class PhotoInputComponent {
    @ViewChild("mPhotoInput") fileInput: ElementRef<HTMLInputElement>;

    private readonly toastService = inject(ToastService);

    fileLoadEnd = output<PhotoInputFileLoadEnd>();

    eventName?: string;

    mPhotoInputChanged(event: any) {
        if (event.target.files && event.target.files.length === 1) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onloadend = async (fileLoadend) => {
                const exifr = await import("exifr");
                const metadata = await exifr.parse(event.target.files[0]);

                this.fileLoadEnd.emit({
                    eventName: this.eventName,
                    base64image: fileLoadend.target.result as string,
                    metadata: metadata,
                });
            };
        }
    }

    @HostListener("click")
    onClickEvent() {
        if (!this.fileInput) this.toastService.showError("camera.error");
        this.fileInput.nativeElement.click();
    }

    open(eventName?: string) {
        if (!this.fileInput) this.toastService.showError("camera.error");
        this.eventName = eventName;
        this.fileInput.nativeElement.click();
    }
}
