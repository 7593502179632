<header class="header">
    @if (titleService.goBack() !== undefined) {
        <nav class="header__nav--left nav">
            <a
                class="btn--icon"
                [routerLink]="titleService.goBack()"
                (click)="titleService.goBack.set(undefined)"
                title="Terug"
            >
                <m-svg iconName="back" />
            </a>
        </nav>
    }

    <h1 class="header__title">
        {{ titleService.title }}
    </h1>

    @if (isConfigured) {
        <nav class="header__nav--right nav">
            <small class="px-8">v{{ guiVersion }}</small>
            <button
                class="btn--white"
                (click)="endWorkday()"
                title="Beeindig werkdag"
            >
                <span>{{ "header.endWorkday" | translate }}</span>
                <m-svg iconName="stopwatch" />
            </button>

            <button
                class="btn--icon"
                (click)="userIconClick()"
                title="{{ appService.selectedWorker.firstName + ' ' + appService.selectedWorker.lastName }}"
            >
                <m-svg iconName="user" />
            </button>

            <div
                class="notification"
                [style]="{ '--color-notification': hasLatestVersion ? 'transparent' : 'red' }"
            >
                <button
                    class="btn--icon"
                    #reloadButton
                    (click)="refreshClicked(reloadButton)"
                    title="Reload"
                >
                    @if (hasLatestVersion) {
                        <m-svg iconName="reload" />
                    } @else {
                        <m-svg iconName="reload-exclamation" />
                    }
                </button>
            </div>

            <app-refresh-popup #popupDialog />
        </nav>
    }
</header>
