import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment/moment";

@Pipe({ name: "momentDate", standalone: true })
export class MomentDatePipe implements PipeTransform {
    transform(date: Date, day = true, _lang = "forceRefreshPipeTrick") {
        if (!date) return "";

        return moment(date).format(day ? "ddd L" : "L");
    }
}

@Pipe({ name: "momentFormat", standalone: true })
export class MomentFormatPipe implements PipeTransform {
    transform(date: Date, format = "ddd L LT") {
        if (!date) return "";
        return moment(date).format(format);
    }
}

@Pipe({ name: "shortDateTime", standalone: true })
export class ShortDateTimePipe implements PipeTransform {
    transform(date: Date, includeSeconds = false) {
        const now = new Date();
        const isCurrentDay = date.getDate() === now.getDate();
        const isCurrentYear = date.getFullYear() === now.getFullYear();

        let formatString = isCurrentDay ? "HH:mm" : isCurrentYear ? "DD/MM HH:mm" : "DD/MM/yyyy HH:mm";
        if (includeSeconds) formatString = formatString + ":ss";

        return moment(date).format(formatString);
    }
}
